import React, { useState } from 'react';
import sendSms from 'api/sendMessage';
import SpinLoader from 'components/ui/spinLoader';
import { pause } from 'lib/pause';

interface SendSmsFormProps {
    contactId: string;
}

const SendSmsForm: React.FC<SendSmsFormProps> = ({ contactId }) => {
    const [message, setMessage] = useState('');
    const [isSendSms, setIsSendSms] = useState(false);

    const sendMessage = async (contactId: string, message: string) => {
        if (message === '') return;
        setIsSendSms(true);
        await pause(2000);
        await sendSms({ contactId: parseInt(contactId), message });
        setIsSendSms(false);
        setMessage('');
    };

    return (
        <div className="flex flex-col border border-gray-200 bg-white shadow-sm">
            <textarea
                className="w-full flex-grow border-b border-gray-200 p-3 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500"
                rows={4}
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Type your message here..."
            />
            <div className="p-2 text-right">
                <button
                    className="inline-flex h-8 items-center justify-center border border-blue-600 bg-blue-500 px-3 text-sm text-white hover:bg-blue-600 disabled:border-gray-300 disabled:bg-gray-300"
                    type="button"
                    onClick={() => sendMessage(contactId, message)}
                    disabled={isSendSms}
                >
                    <div className="flex items-center gap-2">
                        {isSendSms && <SpinLoader className="h-3 w-3 animate-spin" />}
                        <span>Send SMS</span>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default SendSmsForm;
