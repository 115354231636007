import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import MustBeLoggedIn from 'components/MustBeLoggedIn';
import Sidebar from 'components/Sidebar';
import MainPage from 'pages/MainPage';
import Contact from 'pages/Contact';
import Transfers from 'pages/Transfers';
import { errorHandler } from 'api/handlers';
import usePopupMessageStore from 'stores/popup-message';
import PopupMessage from 'components/PopupMessage';
import Settings from 'pages/Settings';
import Analytics from 'pages/Analytics';
import AgentConfig from 'pages/AgentConfig';
import Documents from 'pages/Documents';
import DocumentDetailPage from 'pages/Document/DocumentDetailPage';
import SequencePageLayout from 'components/SequencePageLayout';
import Sequences from 'pages/Sequences';
import SequenceDetail from 'pages/SequenceDetail';
import Demo from 'pages/Demo';
import Variables from 'pages/Variables';

function App() {
    const popupMessageStore = usePopupMessageStore();
    errorHandler(popupMessageStore);

    return (
        <BrowserRouter basename="/">
            <PopupMessage />
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <SequencePageLayout pageId="contacts">
                                    <MainPage />
                                </SequencePageLayout>
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/contacts"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <SequencePageLayout pageId="contacts">
                                    <MainPage />
                                </SequencePageLayout>
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/contacts/:contactId"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Contact />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/transfers"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Transfers />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/agent-config"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <AgentConfig />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/sequences"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Sequences />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/variables"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Variables />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/sequences/:sequenceId"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <SequenceDetail />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/analytics"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <SequencePageLayout pageId="analytics">
                                    <Analytics />
                                </SequencePageLayout>
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/documents"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Documents />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/documents/:documentId"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <DocumentDetailPage />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <MustBeLoggedIn>
                            <Sidebar>
                                <Settings />
                            </Sidebar>
                        </MustBeLoggedIn>
                    }
                />
                <Route path="/demo" element={<Demo />} />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
