import httpClient from '../http-client';

export interface DocumentNormal {
    id: string;
    name: string;
    description: string | null;
}

export interface Response {
    documents: DocumentNormal[];
}

const getDocuments = async (): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/get-documents', {});
    return data;
};

export default getDocuments;
