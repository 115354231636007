export function timeCondition(fact: string, operator: string, value: string) {
    return {
        fact,
        operator,
        value: value
    };
}

export type RuleCondition = {
    fact: string;
    operator: string;
    value: boolean | number | string | null;
    not?: {
        all: RuleCondition[];
    };
};

export type RuleLogic = {
    all?: RuleCondition[];
    any?: RuleCondition[];
};

export type StepRule = {
    conditions: RuleLogic;
    event: {
        type: 'sms' | 'call' | 'schedule call' | 'schedule sms' | 'mark unresponsive';
        params: {
            description: string;
            mode?: 'random';
            endHour?: number;
        };
    };
};

export const nextStepRules: StepRule[] = [
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'equal', value: 0 },
                { fact: 'numberOfCalls', operator: 'equal', value: 1 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 0 },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'userEverAnswerCall', operator: 'equal', value: false },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'firstInteractionType', operator: 'equal', value: 'call' }
            ]
        },
        event: {
            type: 'sms',
            params: { description: '1st day, user did not answer first call, send an SMS immediately' }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '10m'),
                { fact: 'daysSinceFirstInteraction', operator: 'equal', value: 0 },
                { fact: 'numberOfCalls', operator: 'equal', value: 1 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'lastInteractionType', operator: 'equal', value: 'sms' },
                { fact: 'firstInteractionType', operator: 'equal', value: 'call' }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    '1st day, user did not answer first call and did not reply to first SMS, make a call 10 minutes after last SMS'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '2h'),
                { fact: 'daysSinceFirstInteraction', operator: 'equal', value: 0 },
                { fact: 'numberOfCalls', operator: 'equal', value: 2 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'firstInteractionType', operator: 'equal', value: 'call' }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    '1st day, user did not answer first call, did not reply to first SMS, and did not answer second call, make a call 2 hours after last call'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '4h'),
                { fact: 'daysSinceFirstInteraction', operator: 'equal', value: 0 },
                { fact: 'numberOfCalls', operator: 'equal', value: 3 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'firstInteractionType', operator: 'equal', value: 'call' }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    '1st day, user did not answer first call, did not reply to first SMS, and did not answer second and third calls, make a call 4 hours after last call'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastAssistantSms', 'greaterThanInclusive', '3h'),
                timeCondition('timeSinceFirstInteraction', 'lessThanInclusive', '24h'),
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'currentHourLocal', operator: 'equal', value: 18 },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'lessThan', value: 3 }
            ]
        },
        event: {
            type: 'sms',
            params: {
                description:
                    '1st day, no user interaction, 6pm local time, last SMS 3 hours ago, send an SMS immediately'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 1 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 3 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'schedule call',
            params: {
                description:
                    '2nd to 4th day, user has no interaction today, call randomly before 1pm local time. (If user registered outside of contact-friendly time in evening, treat the second day as first day)',
                mode: 'random',
                endHour: 13
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 1 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 3 },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '7h'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 1 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    '2nd to 4th day, user has no interaction today, already made 1 call, make a second call 7 hours after last call. (If user registered outside of contact-friendly time in evening, treat the second day as first day)'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 1 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 3 },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '20m'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 2 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'lessThan', value: 3 }
            ]
        },
        event: {
            type: 'sms',
            params: {
                description:
                    '2nd to 4th day, user has no interaction today, already made 2 calls, send an SMS 20 minutes after last call'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 4 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 9 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'schedule call',
            params: {
                description: '5th to 10th day, user has no interaction today, make a call randomly',
                mode: 'random'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 4 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 9 },
                timeCondition('timeSinceLastAssistantSms', 'greaterThanInclusive', '48h'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 1 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'lessThan', value: 3 }
            ]
        },
        event: {
            type: 'sms',
            params: {
                description:
                    '5th to 10th day, user has no interaction today, already made 1 call, send SMS 48 hours after last SMS'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 10 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 29 },
                { fact: 'daysSinceLastCall', operator: 'greaterThanInclusive', value: 3 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'schedule call',
            params: {
                description:
                    '11th to 30th day, user has no interaction today, make a call randomly every 3 days, no more than 1 interaction per day',
                mode: 'random'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'daysSinceFirstInteraction', operator: 'greaterThanInclusive', value: 10 },
                { fact: 'daysSinceFirstInteraction', operator: 'lessThanInclusive', value: 29 },
                { fact: 'daysSinceLastAssistantSms', operator: 'greaterThanInclusive', value: 5 },
                { fact: 'userEverInteractionToday', operator: 'equal', value: false },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfSmsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'lessThan', value: 3 }
            ]
        },
        event: {
            type: 'schedule sms',
            params: {
                description:
                    '11th to 30th day, user has no interaction today, send an SMS randomly every 5 days, no more than 1 interaction per day',
                mode: 'random'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastUserInteraction', 'greaterThanInclusive', '15m'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: true },
                { fact: 'numberOfUserSmsToday', operator: 'equal', value: 1 },
                { fact: 'numberOfAnsweredCallsToday', operator: 'equal', value: 0 },
                { fact: 'lastInteractionType', operator: 'equal', value: 'sms' }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    'user engaged today, replied to 1 SMS, no calls answered, make a call 15 minutes after last SMS'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastUserInteraction', 'greaterThanInclusive', '10m'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: true },
                { fact: 'numberOfUserSmsToday', operator: 'equal', value: 0 },
                { fact: 'numberOfAnsweredCallsToday', operator: 'equal', value: 1 },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'isLastCallAnswered', operator: 'equal', value: true },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'lessThan', value: 3 }
            ]
        },
        event: {
            type: 'sms',
            params: {
                description: 'user engaged today, answered 1 call, send an SMS 10 minutes after last call'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '4h'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: true },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'isLastCallAnswered', operator: 'equal', value: false }
            ]
        },
        event: {
            type: 'call',
            params: {
                description: 'user engaged today, make a call 4 hours after last unanswered call'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '4h'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: true },
                { fact: 'lastInteractionType', operator: 'equal', value: 'sms' }
            ]
        },
        event: {
            type: 'call',
            params: {
                description: 'user engaged today, make a call 4 hours after last unanswered SMS'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceFirstInteraction', 'greaterThanInclusive', '30d'),
                { fact: 'userEverInteractionToday', operator: 'equal', value: false }
            ]
        },
        event: {
            type: 'mark unresponsive',
            params: {
                description: 'if days since first interaction is equal to or greater than 30, classify as unresponsive'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'numberOfCalls', operator: 'equal', value: 0 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'firstInteractionType', operator: 'equal', value: 'sms' },
                { fact: 'userEverInteraction', operator: 'equal', value: false }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    'user registered outside of friendly hours, so we sent SMS as first interaction; if no reply, call at the start of friendly hours'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '2h'),
                { fact: 'numberOfCalls', operator: 'equal', value: 1 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'isLastCallAnswered', operator: 'equal', value: false },
                { fact: 'firstInteractionType', operator: 'equal', value: 'sms' },
                { fact: 'userEverInteraction', operator: 'equal', value: false }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    'user registered outside of friendly hours, so we sent SMS as first interaction; if no reply, call at the start of friendly hours; if no answer, call 2 hours later'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceLastInteraction', 'greaterThanInclusive', '4h'),
                { fact: 'numberOfCalls', operator: 'equal', value: 2 },
                { fact: 'numberOfAssistantSms', operator: 'equal', value: 1 },
                { fact: 'lastInteractionType', operator: 'equal', value: 'call' },
                { fact: 'isLastCallAnswered', operator: 'equal', value: false },
                { fact: 'firstInteractionType', operator: 'equal', value: 'sms' },
                { fact: 'userEverInteraction', operator: 'equal', value: false }
            ]
        },
        event: {
            type: 'call',
            params: {
                description:
                    'user registered outside of friendly hours, so we sent SMS as first interaction; if no reply, call at the start of friendly hours; if no answer, call 2 hours later; if still no answer, call 4 hours later'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                timeCondition('timeSinceFirstInteraction', 'lessThanInclusive', '29d'),
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'greaterThanInclusive', value: 3 },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'schedule call',
            params: {
                description: 'too many undelivered SMS messages',
                mode: 'random'
            }
        }
    },
    {
        conditions: {
            all: [
                { fact: 'isWithinContactFriendlyTime', operator: 'equal', value: true },
                { fact: 'firstInteractionTime', operator: 'equal', value: null },
                { fact: 'numberOfConsecutiveUndeliveredSms', operator: 'greaterThanInclusive', value: 1 },
                { fact: 'numberOfCallsToday', operator: 'equal', value: 0 }
            ]
        },
        event: {
            type: 'schedule call',
            params: {
                description: 'undelivered SMS, no first interaction',
                mode: 'random'
            }
        }
    }
];
