import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    Typography,
    IconButton,
    InputAdornment,
    CircularProgress
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { UserSubmission } from '../../types/demo';
import { PhoneIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { submitDemoRequest } from 'api/submit-demo-request';
import usePopupMessageStore from 'stores/popup-message';

interface ContactModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: UserSubmission) => void;
    selectedCase: string | null;
    caseCategory: string;
}

export default function ContactModal({ open, onClose, onSubmit, selectedCase, caseCategory }: ContactModalProps) {
    const { success } = usePopupMessageStore();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<UserSubmission>();

    const onSubmitForm = async (data: Omit<UserSubmission, 'selectedCase' | 'caseCategory'>) => {
        try {
            setIsSubmitting(true);

            const submission: UserSubmission = {
                ...data,
                lastName: data.lastName?.trim() || null,
                email: data.email?.trim() || null,
                selectedCase: selectedCase || '',
                caseCategory
            };

            const response = await submitDemoRequest(submission);

            if (response.success) {
                reset();
                success('Request Submitted Successfully!');
                setTimeout(() => {
                    onClose();
                }, 2000);
            } else {
                throw new Error(response.message || 'Submission failed');
            }
        } catch (err) {
            console.error(err instanceof Error ? err.message : 'An unexpected error occurred');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    borderRadius: '8px'
                }
            }}
        >
            {/* Demo Badge */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    backgroundColor: 'rgba(45, 212, 191, 0.1)',
                    border: '1px solid rgba(45, 212, 191, 0.3)',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    zIndex: 1
                }}
            >
                <Typography
                    sx={{
                        color: '#2DD4BF',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em'
                    }}
                >
                    Demo Mode
                </Typography>
            </Box>

            {/* Close button */}
            <IconButton
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'rgba(255,255,255,0.5)',
                    '&:hover': { color: 'white' }
                }}
            >
                <XMarkIcon className="h-5 w-5" />
            </IconButton>

            {/* Header Section */}
            <DialogTitle
                sx={{
                    color: 'white',
                    borderBottom: '1px solid rgba(255,255,255,0.1)',
                    pb: 3,
                    pt: 3
                }}
            >
                <div className="mb-2 flex items-center justify-center">
                    <PhoneIcon className="mr-2 h-8 w-8 text-teal-400" />
                </div>
                <Typography
                    variant="h5"
                    className="bg-gradient-to-r from-teal-400 to-indigo-400 bg-clip-text text-center font-semibold text-transparent"
                >
                    Get Legal Help Now
                </Typography>
            </DialogTitle>

            <form onSubmit={handleSubmit(onSubmitForm)}>
                <DialogContent>
                    {/* Informative text */}
                    <Box className="mb-6 text-center">
                        <Typography sx={{ color: 'rgba(255,255,255,0.95)', mb: 2 }}>
                            Enter your contact information below and our legal team will call you shortly to discuss
                            your case.
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgba(255,255,255,0.8)',
                                fontSize: '0.9rem',
                                padding: '8px 16px',
                                background: 'rgba(45, 212, 191, 0.1)',
                                borderRadius: '8px',
                                border: '1px solid rgba(45, 212, 191, 0.2)',
                                mb: 2
                            }}
                        >
                            ⚡ Average response time: Under 2 minutes
                        </Typography>

                        {/* Demo Notice */}
                        <Typography
                            sx={{
                                color: 'rgba(255,255,255,0.8)',
                                fontSize: '0.85rem',
                                padding: '8px 16px',
                                background: 'rgba(129, 140, 248, 0.1)',
                                borderRadius: '8px',
                                border: '1px solid rgba(129, 140, 248, 0.2)'
                            }}
                        >
                            🔔 This is a demo version. Contact us to set up a complete agentic sequence.
                        </Typography>
                    </Box>

                    <Box className="space-y-6">
                        <TextField
                            fullWidth
                            label="First Name"
                            {...register('firstName', {
                                required: 'First name is required',
                                maxLength: {
                                    value: 40,
                                    message: 'First name cannot exceed 40 characters'
                                },
                                pattern: {
                                    value: /^[a-zA-Z\s-']+$/,
                                    message: 'Please enter a valid first name'
                                }
                            })}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255,255,255,0.04)',
                                    '& fieldset': {
                                        borderColor: 'rgba(255,255,255,0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(138, 180, 248, 0.5)'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#8AB4F8'
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255,255,255,0.8)'
                                },
                                '& input': {
                                    color: 'rgba(255,255,255,0.95)'
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            {...register('lastName', {
                                maxLength: {
                                    value: 40,
                                    message: 'Last name cannot exceed 40 characters'
                                },
                                pattern: {
                                    value: /^[a-zA-Z\s-']*$/,
                                    message: 'Please enter a valid last name'
                                }
                            })}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255,255,255,0.04)',
                                    '& fieldset': {
                                        borderColor: 'rgba(255,255,255,0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(138, 180, 248, 0.5)'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#8AB4F8'
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255,255,255,0.8)'
                                },
                                '& input': {
                                    color: 'rgba(255,255,255,0.95)'
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            type="email"
                            {...register('email', {
                                pattern: {
                                    value: /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Please enter a valid email address'
                                }
                            })}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255,255,255,0.04)',
                                    '& fieldset': {
                                        borderColor: 'rgba(255,255,255,0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(138, 180, 248, 0.5)'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#8AB4F8'
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255,255,255,0.8)'
                                },
                                '& input': {
                                    color: 'rgba(255,255,255,0.95)'
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Phone Number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography sx={{ color: 'rgba(255,255,255,0.7)' }}>+1</Typography>
                                    </InputAdornment>
                                )
                            }}
                            {...register('phoneNumber', {
                                required: 'Phone number is required',
                                pattern: {
                                    value: /^[2-9]\d{9}$/,
                                    message: 'Please enter a valid 10-digit US phone number'
                                },
                                validate: {
                                    validArea: value => {
                                        const areaCode = value.substring(0, 3);
                                        return parseInt(areaCode) >= 200 || 'Invalid area code';
                                    }
                                }
                            })}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message || 'Format: 10 digits (e.g., 123-456-7890)'}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'rgba(255,255,255,0.04)',
                                    '& fieldset': {
                                        borderColor: 'rgba(255,255,255,0.2)'
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(138, 180, 248, 0.5)'
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#8AB4F8'
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255,255,255,0.8)'
                                },
                                '& input': {
                                    color: 'rgba(255,255,255,0.95)'
                                }
                            }}
                        />
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        padding: 3,
                        borderTop: '1px solid rgba(255,255,255,0.1)',
                        flexDirection: 'column',
                        gap: 1
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={isSubmitting}
                        sx={{
                            backgroundColor: '#8AB4F8',
                            color: '#202124',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            textTransform: 'none',
                            padding: '10px 24px',
                            '&:hover': {
                                backgroundColor: '#93bbf9'
                            }
                        }}
                    >
                        {isSubmitting ? <CircularProgress size={20} /> : 'Submit Demo Request'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
