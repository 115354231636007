import React, { useState } from 'react';
import { IconButton, Collapse } from '@mui/material';
import { ChevronDownIcon, ChevronUpIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

interface FaqItemProps {
    query: string[];
    content: string;
    onEdit?: () => void;
    onDelete?: () => void;
}

function FaqItem({ query, content, onEdit, onDelete }: FaqItemProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const renderMainQuestion = (text: string) => (
        <div className="flex items-center gap-2">
            <span className="line-clamp-1">{text}</span>
            <span className="shrink-0 rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-600">FAQ</span>
        </div>
    );

    return (
        <div className={`group transition-colors ${isExpanded ? 'bg-blue-50' : 'hover:bg-gray-50'}`}>
            <div
                className="flex cursor-pointer items-center justify-between px-4 py-3 sm:px-5"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="flex-1">
                    <h3
                        className={`font-inter text-sm font-medium leading-tight ${
                            isExpanded ? 'text-blue-700' : 'text-gray-900'
                        }`}
                    >
                        {renderMainQuestion(query[0])}
                    </h3>
                    {query.length > 1 && (
                        <div className="mt-1 flex flex-wrap gap-2">
                            <span className="text-xs font-medium text-gray-500">Alternative questions:</span>
                            {query.slice(1).map((q, index) => (
                                <span key={index} className="text-xs italic text-gray-400">
                                    {q}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                <div className="flex items-center gap-1 opacity-0 transition-opacity group-hover:opacity-100">
                    <IconButton onClick={onEdit} size="small" className="text-gray-400 hover:text-gray-600">
                        <PencilIcon className="h-3.5 w-3.5" />
                    </IconButton>
                    <IconButton onClick={onDelete} size="small" className="text-gray-400 hover:text-red-500">
                        <TrashIcon className="h-3.5 w-3.5" />
                    </IconButton>
                    <IconButton
                        onClick={() => setIsExpanded(!isExpanded)}
                        size="small"
                        className="text-gray-400 hover:text-gray-600"
                    >
                        {isExpanded ? (
                            <ChevronUpIcon className="h-3.5 w-3.5" />
                        ) : (
                            <ChevronDownIcon className="h-3.5 w-3.5" />
                        )}
                    </IconButton>
                </div>
            </div>
            <Collapse in={isExpanded}>
                <div className="border-t border-blue-100 bg-white px-6 py-4 shadow-inner">
                    <div className="prose prose-sm max-w-none text-gray-700 [&>*:first-child]:mt-0 [&>*:last-child]:mb-0">
                        {content}
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default FaqItem;
