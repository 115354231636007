import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    FormHelperText
} from '@mui/material';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface FAQ {
    id?: string;
    query: string[];
    content: string;
}

interface FaqFormDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (faq: Omit<FAQ, 'id'>) => Promise<void>;
    initialFaq?: FAQ;
    title?: string;
    onSuccess?: () => void;
}

function FaqFormDialog({ isOpen, onClose, onSave, initialFaq, title = 'Add FAQ', onSuccess }: FaqFormDialogProps) {
    const [faq, setFaq] = useState<Omit<FAQ, 'id'>>({
        query: [''],
        content: ''
    });
    const [errors, setErrors] = useState<{
        query?: string[];
        content?: string;
    }>({});
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (initialFaq) {
            setFaq({
                query: initialFaq.query,
                content: initialFaq.content
            });
        } else {
            setFaq({
                query: [''],
                content: ''
            });
        }
        // Clear errors when dialog opens/closes
        setErrors({});
    }, [initialFaq, isOpen]);

    const validateForm = (): boolean => {
        const newErrors: { query?: string[]; content?: string } = {};

        // Validate questions
        const queryErrors = faq.query.map(q => (q.length < 25 ? 'Question must be at least 25 characters long' : ''));
        if (queryErrors.some(error => error)) {
            newErrors.query = queryErrors;
        }

        // Validate answer
        if (!faq.content.trim()) {
            newErrors.content = 'Answer is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        setIsSaving(true);
        try {
            await onSave(faq);
            onSuccess?.();
            onClose();
        } catch (error) {
            console.error('Error saving FAQ:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleAddQuery = () => {
        setFaq(prev => ({
            ...prev,
            query: [...prev.query, '']
        }));
        // Add empty error slot for new query
        setErrors(prev => ({
            ...prev,
            query: prev.query ? [...prev.query, ''] : undefined
        }));
    };

    const handleRemoveQuery = (index: number) => {
        setFaq(prev => ({
            ...prev,
            query: prev.query.filter((_, i) => i !== index)
        }));
        // Remove error for removed query
        setErrors(prev => ({
            ...prev,
            query: prev.query?.filter((_, i) => i !== index)
        }));
    };

    const handleQueryChange = (index: number, value: string) => {
        setFaq(prev => ({
            ...prev,
            query: prev.query.map((q, i) => (i === index ? value : q))
        }));
        // Clear error for this query if it exists
        if (errors.query) {
            setErrors(prev => ({
                ...prev,
                query: prev.query?.map((error, i) => (i === index ? '' : error))
            }));
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    maxWidth: '800px'
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <DialogTitle
                    sx={{
                        padding: '20px 32px 16px',
                        fontSize: '1.5rem',
                        fontWeight: 600,
                        color: 'rgb(17, 24, 39)',
                        borderBottom: '1px solid rgb(229, 231, 235)'
                    }}
                >
                    {title}
                </DialogTitle>
                <DialogContent sx={{ padding: '16px 32px 24px' }}>
                    <div className="space-y-6">
                        {/* Questions Section */}
                        <div className="space-y-4">
                            <div className="space-y-1">
                                <h3 className="text-lg font-semibold text-gray-900">Question and variations</h3>
                                <p className="text-sm leading-relaxed text-gray-600">
                                    Add different ways to ask the same question. This helps the agent find the answer
                                    regardless of how users phrase their question.
                                </p>
                            </div>

                            <div className="space-y-3">
                                {faq.query.map((query, index) => (
                                    <div key={index} className="space-y-2">
                                        {index === 0 && (
                                            <div className="mb-2 text-sm font-medium text-gray-700">
                                                Main Question *
                                            </div>
                                        )}
                                        <div className="flex-1">
                                            <TextField
                                                fullWidth
                                                required={index === 0}
                                                placeholder={
                                                    index === 0
                                                        ? 'e.g., "How do I reset my password?"'
                                                        : 'Alternative phrasing (e.g., "What should I do if I forgot my password?")'
                                                }
                                                value={query}
                                                onChange={e => handleQueryChange(index, e.target.value)}
                                                error={!!errors.query?.[index]}
                                                size="small"
                                                InputProps={{
                                                    endAdornment: index > 0 && (
                                                        <IconButton
                                                            onClick={() => handleRemoveQuery(index)}
                                                            size="small"
                                                            color="error"
                                                            className="hover:bg-red-50"
                                                            sx={{ padding: '4px' }}
                                                        >
                                                            <XMarkIcon className="h-4 w-4" />
                                                        </IconButton>
                                                    )
                                                }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        backgroundColor: 'rgb(249, 250, 251)',
                                                        '&:hover': {
                                                            backgroundColor: 'rgb(243, 244, 246)'
                                                        }
                                                    },
                                                    '& .MuiOutlinedInput-input': {
                                                        padding: '12px 14px'
                                                    }
                                                }}
                                            />
                                            <div className="mt-1.5 flex justify-end">
                                                <span
                                                    className={`text-xs ${
                                                        query.length >= 25 ? 'text-green-600' : 'text-red-500'
                                                    }`}
                                                >
                                                    {query.length >= 25
                                                        ? '✓ Minimum length met'
                                                        : `${25 - query.length} more characters needed`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-start pt-2">
                                <Button
                                    type="button"
                                    onClick={handleAddQuery}
                                    startIcon={<PlusIcon className="h-4 w-4" />}
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 500,
                                        borderColor: 'rgb(209, 213, 219)',
                                        color: 'rgb(55, 65, 81)',
                                        '&:hover': {
                                            borderColor: 'rgb(156, 163, 175)',
                                            backgroundColor: 'rgb(249, 250, 251)'
                                        }
                                    }}
                                >
                                    Add Variation
                                </Button>
                            </div>
                        </div>

                        {/* Answer Section */}
                        <div className="space-y-3">
                            <div className="space-y-1">
                                <h3 className="text-lg font-semibold text-gray-900">Answer *</h3>
                                <p className="text-sm leading-relaxed text-gray-600">
                                    Provide a clear and comprehensive answer that addresses all variations of the
                                    question.
                                </p>
                            </div>
                            <TextField
                                required
                                fullWidth
                                multiline
                                rows={4}
                                value={faq.content}
                                onChange={e => {
                                    setFaq(prev => ({ ...prev, content: e.target.value }));
                                    setErrors(prev => ({ ...prev, content: undefined }));
                                }}
                                error={!!errors.content}
                                placeholder="Enter a detailed answer..."
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'rgb(249, 250, 251)',
                                        '&:hover': {
                                            backgroundColor: 'rgb(243, 244, 246)'
                                        }
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '16px'
                                    }
                                }}
                            />
                            {errors.content && <FormHelperText error>{errors.content}</FormHelperText>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: '16px 32px 20px',
                        borderTop: '1px solid rgb(229, 231, 235)'
                    }}
                >
                    <Button
                        onClick={onClose}
                        disabled={isSaving}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 500,
                            color: 'rgb(55, 65, 81)',
                            '&:hover': {
                                backgroundColor: 'rgb(249, 250, 251)'
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isSaving}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 500,
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 'none'
                            }
                        }}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default FaqFormDialog;
