import httpClient from 'api/http-client';

export interface Response {
    result: string;
}

interface UpdateSequencePayload {
    id: string;
    aiEnabled?: boolean;
    aiEnabledAutoTakeContact?: boolean;
    autoTakeContactRatio?: number;
    name?: string;
    documentId?: string | null;
    slackWebhook?: string | null;
}

const updateSequence = async (payload: UpdateSequencePayload): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-db-sequence', payload);
    return data;
};

export default updateSequence;
