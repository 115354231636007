export type AgendaStep = {
    id: string;
    description?: string;
    type: 'collection' | 'end' | 'call_function';
    points?: string[];
    hidden: boolean;
    nextStep?: {
        conditionalTransitions?: Array<{
            condition: string;
            nextStep: string;
        }>;
        defaultTransition?: string;
    };
    name?: string;
    say?: string;
    unsuccessfulSay?: string;
    arguments?: string;
    transferNumber?: string;
};

export type AgendaSteps = AgendaStep[];

export const agendaSteps: AgendaSteps = [
    {
        id: 'greeting_user_and_case_situation',
        description:
            'If this is an outbound call, check if the customer is the contact by confirming their name. If yes, check if they are available for the call. If they have no time, ask for a better time to call back. If this is an inbound call, we know the user is the right person and has time to chat - no need to ask these questions. If the customer no longer owns the car, you must copy [To Supervisor: SYSTEM-NEXT] in your next reply',
        type: 'collection',
        points: [
            'check_if_call_customer_is_the_right_contact',
            'is_customer_available_for_the_call',
            'repair_times_info',
            'vehicle_model_info',
            'mileage_info',
            'days_out_of_service_info'
        ],
        hidden: false,
        nextStep: {
            conditionalTransitions: [
                {
                    condition: 'user-wants-to-transfer-call',
                    nextStep: 'transfer_call_by_user'
                },
                {
                    condition: 'user-no-longer-own-the-car',
                    nextStep: 'say_goodbye_unqualified_not_own_the_car'
                },
                {
                    condition: 'car-year-earlier-than-2018',
                    nextStep: 'end_car_too_old'
                },
                {
                    condition: 'current-odometer-greater-than-115000',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'manufacturer-not-in-scope',
                    nextStep: 'end_car_out_of_scope'
                },
                {
                    condition: 'future-case',
                    nextStep: 'future_case_talk_step'
                },
                {
                    condition: 'car-year-2019-and-insufficient-repair-conditions',
                    nextStep: 'end_car_too_old'
                },
                {
                    condition: 'odometer-greater-than-100000-and-insufficient-repair-conditions',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'missing-car-purchase-info-odometer-100k-115k-enough-repairs',
                    nextStep: 'car_new_or_certified_pre_owned_talk_points'
                },
                {
                    condition: 'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-new-enough-repairs',
                    nextStep: 'repair_done_under_warranty_mileage_car_new_talk_points'
                },
                {
                    condition:
                        'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-certified-pre-owned-enough-repairs',
                    nextStep: 'repair_done_under_warranty_mileage_car_certified_pre_owned_talk_points'
                },
                {
                    condition: 'disqualify-odometer-100k-115k-car-repair-not-done-under-warranty',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'qualified-in-office-hour',
                    nextStep: 'transfer_call'
                },
                {
                    condition: 'qualified-out-office-hour-morning',
                    nextStep: 'schedule_call_for_qualified_customer_morning'
                },
                {
                    condition: 'qualified-out-office-hour-evening',
                    nextStep: 'schedule_call_for_qualified_customer_evening'
                }
            ],
            defaultTransition: 'say_goodbye'
        }
    },
    {
        id: 'car_new_or_certified_pre_owned_talk_points',
        description: 'Ask user if the car was purchased new or certified pre-owned',
        type: 'collection',
        points: ['car_new_or_certified_pre_owned_talk_points'],
        hidden: false,
        nextStep: {
            conditionalTransitions: [
                {
                    condition: 'user-wants-to-transfer-call',
                    nextStep: 'transfer_call_by_user'
                },
                {
                    condition: 'user-no-longer-own-the-car',
                    nextStep: 'say_goodbye_unqualified_not_own_the_car'
                },
                {
                    condition: 'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-new-enough-repairs',
                    nextStep: 'repair_done_under_warranty_mileage_car_new_talk_points'
                },
                {
                    condition:
                        'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-certified-pre-owned-enough-repairs',
                    nextStep: 'repair_done_under_warranty_mileage_car_certified_pre_owned_talk_points'
                },
                {
                    condition: 'disqualify-odometer-100k-115k-car-repair-not-done-under-warranty',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'qualified-in-office-hour',
                    nextStep: 'transfer_call'
                },
                {
                    condition: 'qualified-out-office-hour-morning',
                    nextStep: 'schedule_call_for_qualified_customer_morning'
                },
                {
                    condition: 'qualified-out-office-hour-evening',
                    nextStep: 'schedule_call_for_qualified_customer_evening'
                }
            ],
            defaultTransition: 'say_goodbye'
        }
    },
    {
        id: 'repair_done_under_warranty_mileage_car_new_talk_points',
        description: 'Tell user what the warranty mileage is and ask if all repairs were done under warranty mileage',
        type: 'collection',
        points: ['repair_done_under_warranty_mileage_car_new_talk_points'],
        hidden: false,
        nextStep: {
            conditionalTransitions: [
                {
                    condition: 'user-wants-to-transfer-call',
                    nextStep: 'transfer_call_by_user'
                },
                {
                    condition: 'user-no-longer-own-the-car',
                    nextStep: 'say_goodbye_unqualified_not_own_the_car'
                },
                {
                    condition: 'disqualify-odometer-100k-115k-car-repair-not-done-under-warranty',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'qualified-in-office-hour',
                    nextStep: 'transfer_call'
                },
                {
                    condition: 'qualified-out-office-hour-morning',
                    nextStep: 'schedule_call_for_qualified_customer_morning'
                },
                {
                    condition: 'qualified-out-office-hour-evening',
                    nextStep: 'schedule_call_for_qualified_customer_evening'
                }
            ],
            defaultTransition: 'say_goodbye'
        }
    },
    {
        id: 'repair_done_under_warranty_mileage_car_certified_pre_owned_talk_points',
        description: 'Tell user what the warranty mileage is and ask if all repairs were done under warranty mileage',
        type: 'collection',
        points: ['repair_done_under_warranty_mileage_car_certified_pre_owned_talk_points'],
        hidden: false,
        nextStep: {
            conditionalTransitions: [
                {
                    condition: 'user-wants-to-transfer-call',
                    nextStep: 'transfer_call_by_user'
                },
                {
                    condition: 'user-no-longer-own-the-car',
                    nextStep: 'say_goodbye_unqualified_not_own_the_car'
                },
                {
                    condition: 'disqualify-odometer-100k-115k-car-repair-not-done-under-warranty',
                    nextStep: 'end_car_too_many_mileage'
                },
                {
                    condition: 'qualified-in-office-hour',
                    nextStep: 'transfer_call'
                },
                {
                    condition: 'qualified-out-office-hour-morning',
                    nextStep: 'schedule_call_for_qualified_customer_morning'
                },
                {
                    condition: 'qualified-out-office-hour-evening',
                    nextStep: 'schedule_call_for_qualified_customer_evening'
                }
            ],
            defaultTransition: 'say_goodbye'
        }
    },
    {
        id: 'say_goodbye',
        description: 'End the call appropriately',
        type: 'end',
        points: ['end-the-call'],
        hidden: false
    },
    {
        id: 'say_goodbye_unqualified_not_own_the_car',
        description: 'End the call appropriately',
        type: 'end',
        points: ['end-the-call-not-qualified-not-own-the-car'],
        hidden: false
    },
    {
        id: 'transfer_call',
        type: 'call_function',
        name: 'transferCall',
        hidden: true,
        say: '',
        unsuccessfulSay: 'We are sorry that all lines are busy right now. We will call you later. Have a great day.',
        arguments: '{}',
        transferNumber: '+14805736464'
    },
    {
        id: 'transfer_call_by_user',
        type: 'call_function',
        name: 'transferCall',
        hidden: true,
        say: '',
        unsuccessfulSay: 'We are sorry that all lines are busy right now. We will call you later. Have a great day.',
        arguments: '{}',
        transferNumber: '+16023130484'
    },
    {
        id: 'schedule_call_for_qualified_customer_morning',
        description: 'Schedule a call for qualified customer and then end the call',
        type: 'end',
        points: ['schedule_call_for_qualified_customer_morning'],
        hidden: false
    },
    {
        id: 'schedule_call_for_qualified_customer_evening',
        description: 'Schedule a call for qualified customer and then end the call',
        type: 'end',
        points: ['schedule_call_for_qualified_customer_evening'],
        hidden: false
    },
    {
        id: 'future_case_talk_step',
        description:
            'Talk points for future case. Tell customer they need more repairs to qualify for the lemon law case. Ask if they have plans to get repairs in the near future. Tell them to inform us when they get more repairs and we will follow up with them.',
        type: 'end',
        points: ['future_case_talk_points'],
        hidden: false
    },
    {
        id: 'end_car_too_old',
        description:
            'Customer has a car that is 2019 or older. This is too old for a lemon law case. End the conversation.',
        type: 'end',
        points: ['end_car_too_old'],
        hidden: false
    },
    {
        id: 'end_car_too_many_mileage',
        description:
            'Customer has a car with more than 100,000 miles. This is too many miles for a lemon law case. End the conversation.',
        type: 'end',
        points: ['end_car_too_many_mileage'],
        hidden: false
    },
    {
        id: 'end_car_out_of_scope',
        description: 'Customer has a car that does not qualify for a lemon law case. End the conversation.',
        type: 'end',
        points: ['end_car_out_of_scope'],
        hidden: false
    }
];
