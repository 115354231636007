import React, { useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ConditionDetailDialog from './ConditionDetailDialog';
import { sharedConditions } from 'data/conditions';

// Add the custom edge component
const CustomEdge: React.FC<EdgeProps> = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    style = {},
    markerEnd,
    label
}) => {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
        curvature: 0.5
    });

    // Calculate the midpoint and offset the label
    const offsetDistance = 60; // Increase this value to move labels further from the edge
    const dx = targetX - sourceX;
    const dy = targetY - sourceY;
    const angle = Math.atan2(dy, dx);

    // Offset the label perpendicular to the edge direction
    const offsetX = offsetDistance * Math.cos(angle - Math.PI / 2);
    const offsetY = offsetDistance * Math.sin(angle - Math.PI / 2);

    // Format label text to show first 10 words
    const formatLabel = (text: string) => {
        if (!text) return '';
        const words = text.split(' ');
        const truncated = words.slice(0, 10).join(' ');
        return words.length > 10 ? `${truncated}...` : truncated;
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const condition = sharedConditions.find(c => c.name === label) || null;

    return (
        <>
            <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={style} />
            {label && (
                <EdgeLabelRenderer>
                    <div
                        onClick={() => condition && setIsDialogOpen(true)}
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${labelX + offsetX}px,${labelY + offsetY}px)`,
                            background: 'rgba(255, 255, 255, 0.95)',
                            border: '1px solid #ccc',
                            padding: '8px 12px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            width: '200px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            pointerEvents: 'all',
                            cursor: condition ? 'pointer' : 'default',
                            transition: 'all 0.2s ease-in-out',
                            userSelect: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px'
                        }}
                        className="nodrag nopan edge-label"
                    >
                        <CallSplitIcon
                            sx={{
                                color: '#666',
                                fontSize: '16px',
                                visibility: condition ? 'visible' : 'hidden'
                            }}
                        />
                        {formatLabel(label as string)}
                    </div>
                </EdgeLabelRenderer>
            )}
            {condition && (
                <ConditionDetailDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    condition={condition}
                />
            )}
        </>
    );
};

export default CustomEdge;
