import React, { useEffect, useState, useMemo } from 'react';
import Loader from 'components/Loader';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import getAnalytics, { AnalyticsResponse } from 'api/getAnalytics';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import { NORMAL_AI_STATUS } from 'api/getContacts';
import useContactTableConfigStore from 'stores/contact-table-config';
import { MenuItem, InputLabel, OutlinedInput, Select, FormControl } from '@mui/material';

function Analytics() {
    const contactTableConfigStore = useContactTableConfigStore();
    const currentSequenceId = contactTableConfigStore.getSequenceSelection('analytics') ?? null;
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<string[]>([]);
    const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);

    const [dateRange, setDateRange] = useState<{ start: Dayjs | null; end: Dayjs | null }>({
        start: contactTableConfigStore.analyticsConfig.startDate
            ? dayjs(contactTableConfigStore.analyticsConfig.startDate).utc()
            : dayjs().utc().subtract(30, 'day').startOf('day'),
        end: contactTableConfigStore.analyticsConfig.endDate
            ? dayjs(contactTableConfigStore.analyticsConfig.endDate).utc()
            : dayjs().utc().endOf('day')
    });
    const [dateRangeError, setDateRangeError] = useState<string | null>(null);
    const [analytics, setAnalytics] = useState<AnalyticsResponse>({
        totalContacts: 0,
        statusBreakdown: [],
        newContactsCount: 0,
        dailyEngagement: [],
        dailyCallEngagement: [],
        dailySmsEngagement: [],
        engagementMetrics: {
            avg: 0,
            std: 0
        },
        callEngagementMetrics: {
            avg: 0,
            std: 0
        },
        smsEngagementMetrics: {
            avg: 0,
            std: 0
        },
        callMetrics: {
            total: 0,
            answered: 0
        },
        smsMetrics: {
            total: 0,
            userSms: 0,
            assistantSms: 0,
            undeliveredAndFailedSms: 0
        },
        carrierDeliverability: [],
        failedCallsByDate: []
    });

    const getDateRangeError = (start: Dayjs | null, end: Dayjs | null): string | null => {
        if (!start || !end) return null;

        if (end.isBefore(start)) {
            return 'End date must be after start date';
        }

        const monthsDiff = end.diff(start, 'month', true);
        if (monthsDiff > 3) {
            return 'Date range cannot exceed 3 months';
        }

        return null;
    };
    useEffect(() => {
        const error = getDateRangeError(dateRange.start, dateRange.end);
        if (error) {
            setDateRangeError(error);
        } else {
            setDateRangeError(null);
            contactTableConfigStore.updateAnalyticsConfig({
                currentSequenceId: currentSequenceId,
                startDate: dateRange.start?.utc().startOf('day').valueOf() ?? null,
                endDate: dateRange.end?.utc().endOf('day').valueOf() ?? null
            });
            const fetchAnalytics = async () => {
                setIsLoading(true);
                if (!currentSequenceId || !dateRange.start || !dateRange.end) {
                    setIsLoading(false);
                    return;
                }
                try {
                    const data = await getAnalytics({
                        sequenceId: currentSequenceId,
                        start: dateRange.start?.utc().startOf('day').valueOf() ?? null,
                        end: dateRange.end?.utc().endOf('day').valueOf() ?? null
                    });
                    setAnalytics(prev => ({
                        ...prev,
                        ...data
                    }));
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching analytics:', error);
                    setIsLoading(false);
                }
            };

            fetchAnalytics();
        }
    }, [currentSequenceId, dateRange.start, dateRange.end]);

    const sortedStatusBreakdown = useMemo(
        () =>
            [...analytics.statusBreakdown]
                .sort((a, b) => b.count - a.count)
                .map(item => ({
                    status: item.status ?? NORMAL_AI_STATUS,
                    count: item.count
                })),
        [analytics.statusBreakdown]
    );

    const dailyEngagementData = useMemo(() => {
        const error = getDateRangeError(dateRange.start, dateRange.end);
        if (error) return [];

        const dates: string[] = [];
        const start = dateRange.start?.startOf('day');
        const end = dateRange.end?.endOf('day');

        if (start && end) {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end, 'day')) {
                dates.push(current.format('YYYY-MM-DD'));
                current = current.add(1, 'day');
            }
        }

        // Create lookup map for engagement data
        const engagementMap = new Map(
            analytics.dailyEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );

        const callEngagementMap = new Map(
            analytics.dailyCallEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );

        const smsEngagementMap = new Map(
            analytics.dailySmsEngagement.map(item => [
                dayjs(item.date).format('YYYY-MM-DD'),
                {
                    engaged: item.engaged,
                    total: item.total,
                    ratio: item.ratio
                }
            ])
        );
        // Map dates to chart data points with all metrics
        return dates.map(date => {
            const overall = engagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };
            const call = callEngagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };
            const sms = smsEngagementMap.get(date) ?? { engaged: 0, total: 0, ratio: 0 };

            return {
                date,
                engaged: overall.engaged,
                total: overall.total,
                ratio: overall.ratio,
                callEngaged: call.engaged,
                callTotal: call.total,
                callRatio: call.ratio,
                smsEngaged: sms.engaged,
                smsTotal: sms.total,
                smsRatio: sms.ratio
            };
        });
    }, [
        dateRange.start,
        dateRange.end,
        analytics.dailyEngagement,
        analytics.dailyCallEngagement,
        analytics.dailySmsEngagement
    ]);

    const carrierDeliverabilityData = useMemo(() => {
        const error = getDateRangeError(dateRange.start, dateRange.end);
        if (error) return [];

        const dates: string[] = [];
        const start = dateRange.start?.startOf('day');
        const end = dateRange.end?.endOf('day');

        // Generate array of dates
        if (start && end) {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end, 'day')) {
                dates.push(current.format('YYYY-MM-DD'));
                current = current.add(1, 'day');
            }
        }

        // Create a map of date -> array of carrier data
        const dateMap = new Map<string, { phoneNumber: string; carrier: string; undelivered30007: number }[]>();

        analytics.carrierDeliverability.forEach(item => {
            const phoneNumber = item.phone_number;
            Object.entries(item.carriers).forEach(([carrier, failData]) => {
                failData.forEach(({ date, undelivered30007 }) => {
                    const formattedDate = dayjs(date).format('YYYY-MM-DD');
                    if (!dateMap.has(formattedDate)) {
                        dateMap.set(formattedDate, []);
                    }
                    dateMap.get(formattedDate)?.push({
                        phoneNumber,
                        carrier,
                        undelivered30007
                    });
                });
            });
        });

        return dates.map(date => ({
            date,
            data: dateMap.get(date) ?? []
        }));
    }, [dateRange.start, dateRange.end, analytics.carrierDeliverability]);

    const uniquePhoneNumbers = Array.from(
        new Set(analytics.carrierDeliverability.map(item => item.phone_number))
    ).sort();

    const uniqueCarriers = Array.from(
        new Set(analytics.carrierDeliverability.flatMap(item => Object.keys(item.carriers)))
    ).sort();

    const carrierDeliverabilityDataFlattened = useMemo(() => {
        return carrierDeliverabilityData.map(({ date, data }) => {
            const result: { [key: string]: number } = {};

            // Initialize all combinations with 0
            uniquePhoneNumbers.forEach(phoneNumber => {
                uniqueCarriers.forEach(carrier => {
                    result[`${phoneNumber}-${carrier}`] = 0;
                });
            });

            // Fill in actual values
            data.forEach(({ phoneNumber, carrier, undelivered30007 }) => {
                result[`${phoneNumber}-${carrier}`] = undelivered30007;
            });

            return {
                date,
                ...result
            };
        });
    }, [carrierDeliverabilityData, uniquePhoneNumbers, uniqueCarriers]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="mt-4 flex h-[calc(100vh-7rem)] flex-col space-y-4 overflow-auto bg-white px-4 sm:px-6 lg:px-8">
            {/* Header Section */}
            <div className="flex h-12 items-center justify-between border border-gray-200 bg-white px-4 py-4 shadow-sm">
                <div className="flex items-center divide-x divide-gray-200">
                    {/* Total Contacts All Time */}
                    <div className="text-sm">
                        <span className="font-medium text-gray-700">Total Contacts All Time:</span>
                        <span className="font-medium text-blue-600"> {analytics.totalContacts}</span>
                    </div>

                    {/* Date Range Picker */}
                    <div className="ml-4 flex items-center gap-3 pl-4">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="flex h-8 items-center gap-3">
                                <DatePicker
                                    label="Start Date (UTC)"
                                    value={dateRange.start}
                                    onChange={newValue => {
                                        const newStart = newValue?.utc().startOf('day') ?? null;
                                        setDateRange(prev => ({ ...prev, start: newStart }));
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            sx: {
                                                '& .MuiInputBase-root': {
                                                    height: '32px' // h-8 equivalent
                                                },
                                                '& .MuiInputBase-input': {
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem' // text-sm equivalent
                                                }
                                            }
                                        }
                                    }}
                                />
                                <DatePicker
                                    label="End Date (UTC)"
                                    value={dateRange.end}
                                    onChange={newValue => {
                                        const newEnd = newValue?.utc().endOf('day') ?? null;
                                        setDateRange(prev => ({ ...prev, end: newEnd }));
                                    }}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            sx: {
                                                '& .MuiInputBase-root': {
                                                    height: '32px' // h-8 equivalent
                                                },
                                                '& .MuiInputBase-input': {
                                                    padding: '4px 8px',
                                                    fontSize: '0.875rem' // text-sm equivalent
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </LocalizationProvider>

                        <div className="text-sm">
                            <span className="font-medium text-gray-700">New Contacts (between dates):</span>
                            <span className="font-medium text-blue-600"> {analytics.newContactsCount}</span>
                        </div>
                    </div>
                </div>
                {dateRangeError && <div className="text-sm font-medium text-red-600">{dateRangeError}</div>}
            </div>

            {/* Metrics Stats Row */}
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {/* Call Metrics Card */}
                <div className="border border-gray-200 bg-white p-4 shadow-sm">
                    <h3 className="mb-2 text-sm font-medium text-gray-700">Call Metrics</h3>
                    <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                        <span className="text-sm text-gray-600">Total Calls:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.callMetrics.total}
                        </span>
                        <span className="text-sm text-gray-600">Answered:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.callMetrics.answered}
                        </span>
                    </div>
                </div>

                {/* SMS Metrics Card */}
                <div className="border border-gray-200 bg-white p-4 shadow-sm">
                    <h3 className="mb-2 text-sm font-medium text-gray-700">SMS Metrics</h3>
                    <div className="grid grid-cols-2 gap-x-3 gap-y-1">
                        <span className="text-sm text-gray-600">Total SMS:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.smsMetrics.total}
                        </span>
                        <span className="text-sm text-gray-600">User SMS:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.smsMetrics.userSms}
                        </span>
                        <span className="text-sm text-gray-600">Assistant SMS:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.smsMetrics.assistantSms}
                        </span>
                        <span className="text-sm text-gray-600">Failed/Undelivered:</span>
                        <span className="text-right text-sm font-medium text-blue-600">
                            {analytics.smsMetrics.undeliveredAndFailedSms}
                        </span>
                    </div>
                </div>
            </div>

            {/* Charts Grid */}
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                {analytics.statusBreakdown.length > 0 ? (
                    <>
                        {/* Bar Chart */}
                        <div className="border border-gray-200 bg-white p-4 shadow-sm">
                            <h3 className="mb-4 text-center text-sm font-medium text-gray-700">
                                Status Breakdown (updated between dates)
                            </h3>
                            <BarChart
                                dataset={sortedStatusBreakdown}
                                yAxis={[
                                    {
                                        scaleType: 'band',
                                        dataKey: 'status',
                                        tickLabelStyle: {
                                            textAnchor: 'end',
                                            fill: '#1F2937'
                                        }
                                    }
                                ]}
                                xAxis={[{ label: 'Count' }]}
                                series={[{ dataKey: 'count', label: 'Status Count' }]}
                                layout="horizontal"
                                height={250}
                                margin={{ left: 200 }}
                                barLabel="value"
                                grid={{ vertical: true }}
                            />
                        </div>

                        {/* Pie Chart */}
                        <div className="border border-gray-200 bg-white p-4 shadow-sm">
                            <h3 className="mb-4 text-center text-sm font-medium text-gray-700">
                                Status Breakdown (updated between dates)
                            </h3>
                            <PieChart
                                series={[
                                    {
                                        data: sortedStatusBreakdown.map(item => ({
                                            id: item.status,
                                            value: (item.count / (analytics.newContactsCount + 0.000001)) * 100,
                                            label: `${item.status} (${item.count})`
                                        })),
                                        arcLabel: item => `${item.id}`,
                                        arcLabelMinAngle: 35,
                                        highlightScope: { faded: 'global', highlighted: 'item' },
                                        valueFormatter: item => `${item.value.toFixed(1)}%`
                                    }
                                ]}
                                margin={{ right: 300 }}
                                height={250}
                                width={800}
                                slotProps={{
                                    legend: {
                                        labelStyle: {
                                            fontSize: 10,
                                            fill: '#1F2937'
                                        }
                                    }
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <p className="text-sm text-gray-600">No status data available</p>
                )}
            </div>

            {/* Daily Engagement Chart */}
            <div className="border border-gray-200 bg-white p-4 shadow-sm">
                <h3 className="mb-4 text-center text-sm font-medium text-gray-700">Daily Engagement</h3>
                <LineChart
                    dataset={dailyEngagementData}
                    xAxis={[
                        {
                            dataKey: 'date',
                            scaleType: 'point',
                            valueFormatter: value => dayjs(value).format('MM-DD'),
                            tickLabelStyle: {
                                angle: 45,
                                textAnchor: 'start',
                                fontSize: 12,
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    yAxis={[
                        {
                            valueFormatter: value => `${(value * 100).toFixed(1)}%`,
                            tickLabelStyle: {
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    series={[
                        {
                            dataKey: 'ratio',
                            curve: 'linear',
                            showMark: true,
                            label: `Overall (Avg: ${(analytics.engagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.engagementMetrics.std * 100).toFixed(1)}%)`,
                            valueFormatter: (value, index) => {
                                const total = dailyEngagementData[index.dataIndex]?.total ?? 0;
                                const engaged = dailyEngagementData[index.dataIndex]?.engaged ?? 0;
                                return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                            }
                        },
                        {
                            dataKey: 'callRatio',
                            curve: 'linear',
                            showMark: true,
                            label: `Calls (Avg: ${(analytics.callEngagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.callEngagementMetrics.std * 100).toFixed(1)}%)`,
                            valueFormatter: (value, index) => {
                                const total = dailyEngagementData[index.dataIndex]?.callTotal ?? 0;
                                const engaged = dailyEngagementData[index.dataIndex]?.callEngaged ?? 0;
                                return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                            }
                        },
                        {
                            dataKey: 'smsRatio',
                            curve: 'linear',
                            showMark: true,
                            label: `SMS (Avg: ${(analytics.smsEngagementMetrics.avg * 100).toFixed(1)}%, Std: ${(analytics.smsEngagementMetrics.std * 100).toFixed(1)}%)`,
                            valueFormatter: (value, index) => {
                                const total = dailyEngagementData[index.dataIndex]?.smsTotal ?? 0;
                                const engaged = dailyEngagementData[index.dataIndex]?.smsEngaged ?? 0;
                                return `Total: ${total}, Engaged: ${engaged}, Ratio: ${((value ?? 0) * 100).toFixed(1)}%`;
                            }
                        }
                    ]}
                    height={300}
                    margin={{ right: 300 }}
                    slotProps={{
                        legend: {
                            position: { vertical: 'top', horizontal: 'right' },
                            direction: 'column'
                        }
                    }}
                />
            </div>

            {/* Carrier Deliverability */}
            <div className="border border-gray-200 bg-white p-4 shadow-sm">
                <h3 className="mb-4 text-center text-sm font-medium text-gray-700">Carrier Deliverability</h3>
                {/* Selectors */}
                <div className="mb-4 flex justify-center gap-4">
                    <FormControl size="small" className="w-1/4">
                        <InputLabel size="small" className="text-sm">
                            Phone Numbers
                        </InputLabel>
                        <Select
                            multiple
                            size="small"
                            value={selectedPhoneNumbers}
                            onChange={e =>
                                setSelectedPhoneNumbers(
                                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                )
                            }
                            input={<OutlinedInput label="Phone Numbers" />}
                            renderValue={selected => selected.join(', ')}
                        >
                            {uniquePhoneNumbers.map(phone => (
                                <MenuItem key={phone} value={phone}>
                                    {phone}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size="small" className="w-1/4">
                        <InputLabel size="small" className="text-sm">
                            Carriers
                        </InputLabel>
                        <Select
                            multiple
                            size="small"
                            value={selectedCarriers}
                            onChange={e =>
                                setSelectedCarriers(
                                    typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
                                )
                            }
                            input={<OutlinedInput label="Carriers" />}
                            renderValue={selected => selected.join(', ')}
                        >
                            {uniqueCarriers.map(carrier => (
                                <MenuItem key={carrier} value={carrier}>
                                    {carrier}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <LineChart
                    dataset={carrierDeliverabilityDataFlattened}
                    xAxis={[
                        {
                            dataKey: 'date',
                            scaleType: 'point',
                            valueFormatter: value => dayjs(value).format('MM-DD'),
                            tickLabelStyle: {
                                angle: 45,
                                textAnchor: 'start',
                                fontSize: 12,
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    yAxis={[
                        {
                            valueFormatter: value => value.toString(),
                            tickLabelStyle: {
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    series={uniquePhoneNumbers
                        .filter(phone => selectedPhoneNumbers.length === 0 || selectedPhoneNumbers.includes(phone))
                        .flatMap(phoneNumber =>
                            uniqueCarriers
                                .filter(carrier => selectedCarriers.length === 0 || selectedCarriers.includes(carrier))
                                .map(carrier => ({
                                    dataKey: `${phoneNumber}-${carrier}`,
                                    curve: 'linear',
                                    showMark: false,
                                    label: `${phoneNumber} - ${carrier}`,
                                    valueFormatter: value => `Fail Count: ${value}`
                                }))
                        )}
                    height={300}
                    margin={{ right: 300 }}
                    slotProps={{
                        legend: {
                            position: { vertical: 'top', horizontal: 'right' },
                            direction: 'column',
                            hidden: (() => {
                                const totalLines =
                                    (selectedPhoneNumbers.length || uniquePhoneNumbers.length) *
                                    (selectedCarriers.length || uniqueCarriers.length);
                                return totalLines > 6;
                            })()
                        }
                    }}
                />
            </div>

            {/* Failed Calls Chart */}
            <div className="border border-gray-200 bg-white p-4 shadow-sm">
                <h3 className="mb-4 text-center text-sm font-medium text-gray-700">Failed Calls by Date</h3>
                <LineChart
                    dataset={analytics.failedCallsByDate.map(item => ({
                        date: dayjs(item.date).format('YYYY-MM-DD'),
                        count: item.count
                    }))}
                    xAxis={[
                        {
                            dataKey: 'date',
                            scaleType: 'point',
                            valueFormatter: value => dayjs(value).format('MM-DD'),
                            tickLabelStyle: {
                                angle: 45,
                                textAnchor: 'start',
                                fontSize: 12,
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    yAxis={[
                        {
                            valueFormatter: value => value.toString(),
                            tickLabelStyle: {
                                fill: '#1F2937'
                            }
                        }
                    ]}
                    series={[
                        {
                            dataKey: 'count',
                            curve: 'linear',
                            showMark: true,
                            label: 'Failed Calls',
                            valueFormatter: value => `Failed Calls: ${value}`
                        }
                    ]}
                    height={300}
                    margin={{ right: 100 }}
                />
            </div>
        </div>
    );
}

export default Analytics;
