import httpClient from '../http-client';

export interface NewDocumentItem {
    documentId: string;
    query: string[];
    content: string;
}

export interface Response {
    message: string;
}

const createDocumentItem = async (payload: NewDocumentItem): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/create-document-item', payload);
    return data;
};

export default createDocumentItem;
