import React, { useState } from 'react';
import { CollectionInfo, ISlotWithId, TypeContactDetail } from 'api/get-contact-detail';
import { millionSecondsToDhmsString } from 'lib/seconds-to-days-hours-mins';
import {
    ArrowTopRightOnSquareIcon,
    PencilSquareIcon,
    DocumentDuplicateIcon,
    CheckIcon
} from '@heroicons/react/24/outline';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import updateContactCollection from 'api/updateContactCollection';
import IntakeStatusSelect from 'pages/Contacts/IntakeStatusSelect';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import NextStepSelect from 'pages/Contacts/nextStepSelect';
import NextStepScheduledAtSelect from 'pages/Contacts/nextStepScheduledAtSelect';
import NextStepStatusSelect from 'pages/Contacts/nextStepStatusSelect';

function ContactDetailBasic(props: { contactInfo: TypeContactDetail }) {
    let timeFromNow = '';
    if (props.contactInfo.generalInfo.nextStepScheduledAt) {
        timeFromNow = millionSecondsToDhmsString(props.contactInfo.generalInfo.nextStepScheduledAt - Date.now());
        if (timeFromNow === '') {
            timeFromNow = 'now';
        }
    }

    const [fieldValues, setFieldValues] = useState<CollectionInfo[]>(props.contactInfo.collectionInfo);
    const [editingField, setEditingField] = useState<CollectionInfo | null>(null);
    const [initialField, setInitialField] = useState<CollectionInfo | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showCopiedCheck, setShowCopiedCheck] = useState(false);

    const handleInputChange = (value: string | boolean | number | Date | null, key: keyof ISlotWithId) => {
        if (editingField) {
            setEditingField(prev => {
                if (prev) {
                    return { ...prev, [key]: value };
                }
                return prev;
            });
        }
    };

    const getValueLabel = () => {
        if (editingField) {
            if (editingField.dataType === 'number') {
                return 'Value (Number)';
            }
        }
        return 'Value';
    };

    const handleSave = async () => {
        if (editingField) {
            setFieldValues(prevValues =>
                prevValues.map(field => (field.slot_id === editingField.slot_id ? editingField : field))
            );

            await updateContactCollection({
                contactId: props.contactInfo.generalInfo.id,
                updateCollection: {
                    value: editingField.value,
                    state: editingField.state,
                    reason: editingField.reason,
                    msg_for_future: editingField.msg_for_future,
                    slot_id: editingField.slot_id
                }
            });
            setEditingField(null);
            setInitialField(null);
            setIsDialogOpen(false);
        }
    };

    const handleClose = () => {
        if (hasChanges()) {
            return;
        }
        setEditingField(null);
        setInitialField(null);
        setIsDialogOpen(false);
    };

    const handleCancel = () => {
        setEditingField(null);
        setInitialField(null);
        setIsDialogOpen(false);
    };

    const hasChanges = () => {
        return JSON.stringify(editingField) !== JSON.stringify(initialField);
    };

    const handleCopyToClipboard = () => {
        const formattedData = fieldValues
            .map(field => {
                return `Field: ${field.slot_id}
Value: ${field.value === null ? 'null' : field.value}
Reason: ${field.reason || ''}
-------------------`;
            })
            .join('\n');

        navigator.clipboard.writeText(formattedData);
        setShowCopiedCheck(true);
        setTimeout(() => {
            setShowCopiedCheck(false);
        }, 3000);
    };

    const renderValueInput = (field: CollectionInfo) => {
        if (field.integration === 'hubspot') {
            const hubspotLink = `${props.contactInfo.generalInfo.hubspotUrl}/properties?search=${field.slot_id}`;
            return (
                <a
                    href={hubspotLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 rounded border px-4 py-2 transition duration-150 hover:bg-blue-50 hover:text-blue-600"
                >
                    <span>{field.value === null ? '' : String(field.value)}</span>
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    <span className="text-xs">(open hubspot to edit)</span>
                </a>
            );
        }

        if (field.dataType === 'boolean') {
            return (
                <select
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else if (e.target.value === 'true') {
                            handleInputChange(true, 'value');
                        } else if (e.target.value === 'false') {
                            handleInputChange(false, 'value');
                        }
                    }}
                    className="w-full items-center overflow-auto rounded-md border px-3 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                >
                    <option value="true">true</option>
                    <option value="false">false</option>
                    <option value="">null</option>
                </select>
            );
        } else if (field.dataType === 'number') {
            return (
                <input
                    type="text"
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        const inputValue = e.target.value.trim();
                        const newValue =
                            inputValue === '' ? null : isNaN(Number(inputValue)) ? null : Number(inputValue);
                        handleInputChange(newValue, 'value');
                    }}
                    className="w-full rounded border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
            );
        } else if (field.dataType === 'datetime') {
            return (
                <div className="z-50">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="mb-4">
                            <DateTimePicker
                                value={
                                    typeof field.value === 'string' ? dayjs(new Date(field.value).toISOString()) : null
                                }
                                onChange={newValue => {
                                    if (newValue !== null && newValue.isValid()) {
                                        handleInputChange(newValue.toISOString(), 'value');
                                    } else {
                                        handleInputChange(null, 'value');
                                    }
                                }}
                            />
                        </div>
                    </LocalizationProvider>
                </div>
            );
        } else if (typeof field.dataType === 'string' && field.dataType.includes('enum')) {
            const enumMatch = field.dataType.match(/\[(.*?)\]/);
            const enumValues = enumMatch ? enumMatch[1].split(',').map(value => value.trim().replace(/'/g, '')) : [];

            return (
                <select
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else {
                            handleInputChange(e.target.value, 'value');
                        }
                    }}
                    className="w-full items-center overflow-auto rounded-md border px-3 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                >
                    <option value=""></option>
                    {enumValues.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            );
        } else {
            return (
                <textarea
                    value={field.value === null ? '' : String(field.value)}
                    onChange={e => {
                        if (e.target.value === '') {
                            handleInputChange(null, 'value');
                        } else {
                            handleInputChange(e.target.value, 'value');
                        }
                    }}
                    className="w-full rounded border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
            );
        }
    };

    return (
        <div className="h-[calc(100vh-9rem)] overflow-y-auto">
            <div className="flex flex-col space-y-3">
                <div className="border border-gray-200 bg-white p-3 shadow-sm">
                    <div>
                        <span className="font-semibold">Intake Status: </span>
                        <IntakeStatusSelect
                            data={{
                                id: props.contactInfo.generalInfo.id,
                                intakeStatus: props.contactInfo.generalInfo.intakeStatus
                            }}
                            intakeStatusArray={props.contactInfo.generalInfo.intakeStatusArray}
                        />
                    </div>
                </div>
                <div className="border border-gray-200 bg-white p-3 shadow-sm">
                    <div>
                        {props.contactInfo.generalInfo.intakeStatusReason !== null && (
                            <>
                                <p className="font-semibold">Reason: </p>
                                <p className="max-h-32 overflow-auto whitespace-pre-wrap">
                                    {props.contactInfo.generalInfo.intakeStatusReason}
                                </p>
                            </>
                        )}
                    </div>
                </div>

                <div className="border border-gray-200 bg-white p-3 shadow-sm">
                    <div>
                        {`Contact Friendly Hours (${props.contactInfo.generalInfo.contactFriendlyHours.timezone || 'UTC'}) ${props.contactInfo.generalInfo.contactFriendlyHours.start}:00-${props.contactInfo.generalInfo.contactFriendlyHours.end}:00`}
                    </div>
                    <div className="mt-2 flex items-center gap-2">
                        <span className="font-semibold">next step: </span>
                        <NextStepSelect
                            data={{
                                id: props.contactInfo.generalInfo.id,
                                nextStep: props.contactInfo.generalInfo.nextStep
                            }}
                        />
                    </div>
                    <div className="mt-2 flex items-center">
                        <span className="mr-2 font-semibold">at (utc): </span>
                        <NextStepScheduledAtSelect
                            data={{
                                id: props.contactInfo.generalInfo.id,
                                nextStepScheduledAt: props.contactInfo.generalInfo.nextStepScheduledAt
                            }}
                        />
                        <span className="ml-2">
                            {timeFromNow === 'now' ? 'Now' : timeFromNow ? `In ${timeFromNow}` : ''}
                        </span>
                    </div>
                    <div className="mt-2 flex items-center gap-2">
                        <span className="font-semibold">status: </span>
                        <NextStepStatusSelect
                            data={{
                                id: props.contactInfo.generalInfo.id,
                                nextStepStatus: props.contactInfo.generalInfo.nextStepStatus
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <span className="font-semibold">reason : </span>
                        <span>{props.contactInfo.generalInfo.nextStepReason || ''}</span>
                    </div>
                </div>

                <div className="border border-gray-200 bg-white shadow-sm">
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[800px]">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="w-[200px] p-3 text-left">
                                        <div className="relative flex items-center">
                                            <span>Field Name</span>
                                            <button
                                                onClick={handleCopyToClipboard}
                                                className="ml-2 hidden items-center gap-1 rounded-md border border-gray-300 bg-white px-2 py-1 text-xs hover:bg-gray-50 group-hover:flex"
                                            >
                                                {showCopiedCheck ? (
                                                    <>
                                                        <CheckIcon className="h-4 w-4 text-green-500" />
                                                        <span>Copied</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <DocumentDuplicateIcon className="h-4 w-4" />
                                                        <span>Copy</span>
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </th>
                                    <th className="w-[200px] p-3 text-left">Value</th>
                                    <th className="w-[200px] p-3 text-left">State</th>
                                    <th className="w-[200px] p-3 text-left">Reason</th>
                                    <th className="w-[200px] p-3 text-left">Message For Future</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fieldValues.map(field => (
                                    <React.Fragment key={field.slot_id}>
                                        <tr className="group transition duration-150 hover:bg-gray-50">
                                            <td className="relative border-b p-2">
                                                {field.slot_id.split('_').join(' ')}
                                                <PencilSquareIcon
                                                    className="absolute right-2 top-2 hidden h-5 w-5 cursor-pointer text-orange-500 group-hover:block"
                                                    onClick={() => {
                                                        setIsDialogOpen(true);
                                                        setEditingField(field);
                                                        setInitialField(field);
                                                    }}
                                                />
                                            </td>
                                            <td className="border-b p-2">{`${field.value === null ? '' : field.dataType === 'datetime' && typeof field.value === 'string' ? new Date(field.value).toLocaleString() : field.value}`}</td>
                                            <td className="border-b p-2">{field.state}</td>
                                            <td className="border-b p-2">{field.reason}</td>
                                            <td className="border-b p-2">{field.msg_for_future}</td>
                                        </tr>
                                        <Dialog
                                            open={isDialogOpen && editingField?.slot_id === field.slot_id}
                                            onClose={handleClose}
                                            maxWidth="md"
                                            fullWidth
                                        >
                                            <DialogTitle className="bg-gray-100 p-4 text-center font-bold">
                                                {field.slot_id.split('_').join(' ')}
                                            </DialogTitle>
                                            <DialogContent className="p-6">
                                                <div className="mb-4">
                                                    <label className="mb-2 block font-semibold">
                                                        {getValueLabel()}
                                                    </label>
                                                    {editingField && renderValueInput(editingField)}
                                                </div>
                                                <div className="mb-4">
                                                    <label className="mb-2 block font-semibold">State</label>
                                                    <select
                                                        value={editingField?.state}
                                                        onChange={e => handleInputChange(e.target.value, 'state')}
                                                        className="w-full rounded-md border px-3 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                                                    >
                                                        <option value="complete">{`COMPLETE (AI won't ask this question)`}</option>
                                                        <option value="need-more-data">
                                                            NEED-MORE-DATA (AI will ask)
                                                        </option>
                                                        <option value="n/a">N/A (AI will skip)</option>
                                                        <option value="initial">{`INITIAL (AI won't ask as user has given the initial info)`}</option>
                                                    </select>
                                                </div>
                                                <div className="mb-4">
                                                    <label className="mb-2 block font-semibold">Reason</label>
                                                    <textarea
                                                        className="w-full rounded border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                                                        value={editingField?.reason || ''}
                                                        onChange={e => {
                                                            if (e.target.value === '') {
                                                                handleInputChange(null, 'reason');
                                                            } else {
                                                                handleInputChange(e.target.value, 'reason');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="mb-2 block font-semibold">
                                                        Message For Future
                                                    </label>
                                                    <textarea
                                                        className="w-full rounded border border-gray-300 p-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                                                        value={editingField?.msg_for_future || ''}
                                                        onChange={e => {
                                                            if (e.target.value === '') {
                                                                handleInputChange(null, 'msg_for_future');
                                                            } else {
                                                                handleInputChange(e.target.value, 'msg_for_future');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="mb-4 rounded bg-red-50 p-2 text-sm text-red-600">
                                                    {`Make sure you have the right 'state', 'reason', and 'message for future' for AI (even if you update the value in hubspot, you must manually update these values)`}
                                                </div>

                                                <div className="flex justify-end">
                                                    <button
                                                        className="mr-2 rounded bg-gray-300 px-4 py-2 transition duration-150 hover:bg-gray-400"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className={`rounded px-4 py-2 text-white transition duration-150 ${hasChanges() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300'}`}
                                                        onClick={handleSave}
                                                        disabled={!hasChanges()}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="border border-gray-200 bg-white p-3 shadow-sm">
                    <h3 className="mb-2 font-semibold">AI Jobs (what AI is doing/has done):</h3>
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="w-1/4 p-2 text-left">Job ID</th>
                                <th className="w-1/4 p-2 text-left">Name</th>
                                <th className="w-1/4 p-2 text-left">Status</th>
                                <th className="w-1/4 p-2 text-left">Scheduled</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.contactInfo.jobInfo.map(job => (
                                <tr key={job.id} className="transition duration-150 hover:bg-gray-50">
                                    <td className="border-b p-2">{job.id}</td>
                                    <td className="border-b p-2">{job.name}</td>
                                    <td className="border-b p-2">{job.status}</td>
                                    <td className="border-b p-2">{job.scheduled}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ContactDetailBasic;
