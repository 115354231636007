import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import SuperpanelIcon from '../components/icons/SuperpanelIcon';
import login from 'api/login';
import useUserStore from 'stores/user';

function Login() {
    const userStore = useUserStore();
    const [inputs, setInputs] = useState<{ email: string; password: string }>({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const userObj = await login(inputs);
            userStore.setState({ user: userObj.user });
            setLoading(false);
            navigate(`/`);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen">
            {/* Left side - Background Image */}
            <div className="hidden lg:block lg:w-1/2">
                <img src="/images/background.jpeg" alt="Mountain landscape" className="h-full w-full object-cover" />
            </div>

            {/* Right side - Login Form */}
            <div className="flex w-full flex-col justify-center bg-white lg:w-1/2">
                <div className="px-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <SuperpanelIcon className="mx-auto h-10 w-auto" />
                    <h2 className="mt-8 text-center text-2xl font-light tracking-tight text-gray-900">Welcome back</h2>
                    <p className="mt-2 text-center text-sm text-gray-500">Please enter your details to sign in</p>
                </div>

                <div className="mt-10 w-full sm:mx-auto sm:max-w-[400px]">
                    <div className="px-8">
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-2">
                                <label htmlFor="email" className="text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    className="block w-full border-0 border-b border-gray-300 px-3 py-2 text-sm text-gray-900 placeholder:text-gray-400 focus:ring-0"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={inputs.email}
                                    onChange={e => {
                                        setInputs(prevInput => ({
                                            ...prevInput,
                                            email: e.target.value
                                        }));
                                    }}
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <label htmlFor="password" className="text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <input
                                    className="block w-full border-0 border-b border-gray-300 px-3 py-2 text-sm text-gray-900 placeholder:text-gray-400 focus:ring-0"
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    value={inputs.password}
                                    onChange={e => {
                                        setInputs(prevInput => ({
                                            ...prevInput,
                                            password: e.target.value
                                        }));
                                    }}
                                />
                            </div>

                            <div className="text-center text-sm text-gray-500">
                                <div>By continuing, you&apos;re agreeing to the Superpanel&nbsp;</div>
                                <div>
                                    <a
                                        className="text-blue-500 hover:underline"
                                        href="https://superpanel.io/terms-conditions"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms of Conditions
                                    </a>
                                    &nbsp;and&nbsp;
                                    <a
                                        className="text-blue-500 hover:underline"
                                        href="https://superpanel.io/privacy-policy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>

                            <div className="mt-2">
                                <button
                                    type="button"
                                    className="w-full bg-gray-900 px-4 py-3 text-sm font-medium text-white transition-all duration-200 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    {loading ? (
                                        <span className="flex items-center justify-center gap-2">
                                            <svg className="h-5 w-5 animate-spin" viewBox="0 0 24 24">
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                    fill="none"
                                                />
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                />
                                            </svg>
                                            Signing in...
                                        </span>
                                    ) : (
                                        'Sign in'
                                    )}
                                </button>
                            </div>

                            {/* <p className="text-center text-sm text-gray-500">
                                Forgot your password?{' '}
                                <a href="#" className="font-medium text-gray-900 hover:text-gray-800">
                                    Reset it here
                                </a>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
