import React, { ReactNode } from 'react';
import SequencePicker from 'components/SequencePicker';
import useContactTableConfigStore from 'stores/contact-table-config';
import { SequenceArray } from 'api/getSequences';

interface SequencePageLayoutProps {
    pageId: string;
    children: ReactNode;
}

function SequencePageLayout({ pageId, children }: SequencePageLayoutProps) {
    const { updateFilter } = useContactTableConfigStore();

    const handleSequenceChange = (sequence: SequenceArray) => {
        updateFilter({
            colId: 'sequenceId',
            label: 'Sequence',
            filterType: 'uuid',
            id: sequence.id
        });
    };

    return (
        <div className="h-full w-full">
            <div className="h-[4rem] border-b px-8 py-4">
                <div className="mb-4 flex">
                    <SequencePicker pageId={pageId} onSequenceChange={handleSequenceChange} />
                </div>
            </div>
            <div className="">{children}</div>
        </div>
    );
}

export default SequencePageLayout;
