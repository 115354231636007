import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import GeneralTab from './SequenceDetail/GeneralTab';
import AgendaTab from './SequenceDetail/AgendaTab';
import FollowUpRulesTab from './SequenceDetail/FollowUpRulesTab';
import getSequence, { Sequence } from 'api/getSequence';
import Loader from 'components/Loader';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`sequence-tabpanel-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const SequenceDetail: React.FC = () => {
    const { sequenceId } = useParams<{ sequenceId: string }>();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const [sequence, setSequence] = useState<Sequence | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSequence = async () => {
            if (!sequenceId) return;
            try {
                const sequenceData = await getSequence(sequenceId);
                setSequence(sequenceData);
            } catch (error) {
                console.error('Error fetching sequence:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSequence();
    }, [sequenceId]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="flex h-full w-full flex-col">
            {/* Header */}
            <div className="mb-6">
                <div className="sticky top-0 z-20 border-b border-gray-200 bg-white">
                    <div className="h-[4rem]">
                        <div className="flex h-full items-center px-4 sm:px-6 lg:px-8">
                            <div className="flex w-full items-center">
                                <div className="w-1/3">
                                    <button
                                        onClick={() => navigate('/sequences')}
                                        className="inline-flex items-center gap-1 text-sm text-gray-600 transition-colors hover:text-blue-600"
                                    >
                                        <ArrowLeftIcon className="h-4 w-4" />
                                        <span>Back to Sequences</span>
                                    </button>
                                </div>
                                <div className="w-1/3 text-center">
                                    <h1 className="text-lg font-bold text-gray-900">
                                        {sequence?.name || 'Sequence Details'}
                                    </h1>
                                </div>
                                <div className="w-1/3" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tabs section */}
                <div className="sticky top-16 z-10 bg-white">
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentTab} onChange={handleTabChange} aria-label="sequence tabs">
                            <Tab label="General" />
                            <Tab label="Agenda" />
                            <Tab label="Follow-up Rules" />
                        </Tabs>
                    </Box>
                </div>

                {/* Tab panels */}
                <TabPanel value={currentTab} index={0}>
                    <GeneralTab
                        sequenceId={sequenceId}
                        sequence={sequence}
                        onSequenceUpdate={updatedSequence =>
                            setSequence(prev => (prev ? { ...prev, ...updatedSequence } : null))
                        }
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <AgendaTab sequenceId={sequenceId} />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                    <FollowUpRulesTab sequenceId={sequenceId} />
                </TabPanel>
            </div>
        </div>
    );
};

export default SequenceDetail;
