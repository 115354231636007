import httpClient from '../http-client';

export interface Response {
    result: string;
}

const deleteDocument = async (payload: { id: string }): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/delete-document', payload);
    return data;
};

export default deleteDocument;
