import React from 'react';
import ContactDetailBasic from './ContactInfoBasic';
import ContactDetailCall from './ContactInfoCall';
import ContactDetailSms from './ContactInfoSms';
import { TypeContactDetail } from 'api/get-contact-detail';
import CreateAICallButton from './CallAndSMS/CreateAiCallButton';

function ContactDetail(props: { contactId: string; contactInfo: TypeContactDetail }) {
    return (
        <div className="grid h-full grid-cols-3 gap-3">
            <div className="">
                <ContactDetailBasic contactInfo={props.contactInfo} />
            </div>
            <div className="flex flex-col">
                <div className="mb-3 flex h-10 items-center justify-between border border-gray-200 bg-white px-3 shadow-sm">
                    <span className="font-medium">Calls</span>
                    <CreateAICallButton contactId={props.contactId} onCall={() => {}} />
                </div>
                <ContactDetailCall contactInfo={props.contactInfo} />
            </div>
            <div className="flex h-full flex-col">
                <div className="mb-3 flex h-10 items-center border border-gray-200 bg-white px-3 shadow-sm">
                    <span className="font-medium">Sms</span>
                </div>
                <div className="h-full overflow-auto">
                    <ContactDetailSms contactInfo={props.contactInfo} />
                </div>
            </div>
        </div>
    );
}

export default ContactDetail;
