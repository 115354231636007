import { Position } from 'reactflow';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { List, ListItem, Typography, Modal, Box, IconButton } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Paper } from '@mui/material';
import { AgendaStep } from 'data/agenda';
import React, { useState } from 'react';
import { Handle } from 'reactflow';

// Helper function to format text
const formatText = (text: string) => text.replace(/[-_]/g, ' ');

const AgendaStepNode: React.FC<{ data: AgendaStep }> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Paper
                className="w-[280px] transition-all duration-200 hover:bg-gray-50"
                elevation={3}
                onClick={handleOpenModal}
                sx={{
                    background: '#ffffff',
                    border: '1px solid rgba(0,0,0,0.12)',
                    borderRadius: '12px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                    height: '280px', // Increased from 220px to 280px
                    overflow: 'hidden', // Ensure content doesn't overflow
                    cursor: 'pointer', // Added to show it's clickable
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        transform: 'translateY(-2px)'
                    }
                }}
            >
                <Handle type="target" position={Position.Top} />
                <div className="flex h-full flex-col gap-3 p-4">
                    {/* Title Section */}
                    <div className="flex items-center gap-2 border-b border-gray-300 pb-2">
                        <AccountTreeIcon
                            sx={{
                                color: '#2f3d89',
                                fontSize: '1.2rem'
                            }}
                        />
                        <Typography
                            variant="subtitle1"
                            className="font-semibold tracking-wide"
                            sx={{
                                color: '#2f3d89',
                                fontSize: '1rem',
                                letterSpacing: '0.3px'
                            }}
                        >
                            {formatText(data.id)}
                        </Typography>
                    </div>

                    {/* Description Section */}
                    {data.description && (
                        <div className="py-1">
                            <Typography
                                variant="body2"
                                className="leading-snug"
                                sx={{
                                    color: '#424242',
                                    fontSize: '0.875rem',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {data.description}
                            </Typography>
                        </div>
                    )}

                    {/* Points Section - with flex-grow to take remaining space */}
                    {data.points && data.points.length > 0 && (
                        <div className="flex-grow overflow-auto rounded-lg bg-gray-50 p-2">
                            <List dense disablePadding>
                                {data.points.slice(0, 2).map((point, index) => (
                                    <ListItem key={index} disablePadding className="mb-1 flex items-start">
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: '#4B5563',
                                                fontSize: '0.85rem',
                                                lineHeight: 1.3,
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {formatText(point)}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}

                    {/* Type Badge - at bottom */}
                    <Typography
                        variant="caption"
                        className="inline-block rounded-full px-2 py-0.5 text-center"
                        sx={{
                            backgroundColor: '#e3f2fd',
                            color: '#1565c0',
                            fontWeight: 500,
                            fontSize: '0.75rem',
                            width: 'fit-content'
                        }}
                    >
                        {data.type}
                    </Typography>
                </div>
                <Handle type="source" position={Position.Bottom} />
            </Paper>

            <Modal open={isModalOpen} onClose={handleCloseModal} aria-labelledby="node-modal-title">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        overflow: 'hidden',
                        borderRadius: 1
                    }}
                >
                    <div className="relative h-full">
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                zIndex: 1
                            }}
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </IconButton>

                        <div className="max-h-[90vh] overflow-y-auto p-8">
                            <Typography variant="h5" className="mb-6 text-xl font-bold text-gray-900">
                                {formatText(data.id)}
                            </Typography>

                            <div className="space-y-6">
                                {data.description && (
                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Description
                                        </Typography>
                                        <Typography className="text-gray-800">{data.description}</Typography>
                                    </div>
                                )}

                                {data.points && data.points.length > 0 && (
                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Points
                                        </Typography>
                                        <List dense>
                                            {data.points.map((point, index) => (
                                                <ListItem key={index} sx={{ paddingLeft: 0 }}>
                                                    <Typography className="text-left text-gray-800">
                                                        {`${index + 1}. ${formatText(point)}`}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                )}

                                <div className="rounded-lg bg-gray-50 p-4">
                                    <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                        Type
                                    </Typography>
                                    <Typography className="text-gray-800">{data.type}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default AgendaStepNode;
