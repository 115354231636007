import httpClient from 'api/http-client';

export interface Sequence {
    id: string;
    created: number;
    createdReadable: string;
    updated: number;
    updatedReadable: string;
    name: string;
    label: string;
    voice: string;
    documentId: string | null;
    slackWebhook: string | null;
    aiEnabled: boolean;
    aiEnabledAutoTakeContact: boolean;
    autoTakeContactRatio: number;
}

const getSequence = async (id: string): Promise<Sequence> => {
    const { data } = await httpClient.post<Sequence>('/api/get-sequence', { id });
    return data;
};

export default getSequence;
