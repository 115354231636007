import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import { NewDocument } from 'api/document/create-document';
import { useNavigate, useLocation } from 'react-router-dom';
import { DocumentNormal } from 'api/document/get-documents';

interface DocumentDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (document: NewDocument) => Promise<DocumentNormal>;
    initialDocument?: DocumentNormal;
    mode: 'create' | 'edit';
    onUpdate?: (document: DocumentNormal) => Promise<void>;
}

function DocumentDialog({ isOpen, onClose, onSave, initialDocument, mode, onUpdate }: DocumentDialogProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const [document, setDocument] = React.useState<NewDocument>({
        name: '',
        description: null
    });

    React.useEffect(() => {
        if (initialDocument) {
            setDocument({
                name: initialDocument.name,
                description: initialDocument.description
            });
        } else {
            // Reset form when opening for new document
            setDocument({ name: '', description: null });
        }
    }, [initialDocument, isOpen]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const savedDocument = await onSave(document);
            onClose();
            // Reset form
            setDocument({ name: '', description: null });

            if (mode === 'create' && !location.pathname.includes('/documents/')) {
                navigate(`/documents/${savedDocument.id}`);
            } else if (mode === 'edit' && onUpdate) {
                // Call the update handler if in edit mode
                await onUpdate(savedDocument);
            }
        } catch (error) {
            console.error('Error saving document:', error);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{mode === 'create' ? 'Add New Document' : 'Edit Document'}</DialogTitle>
                <DialogContent>
                    <div className="mt-4 space-y-4">
                        <TextField
                            autoFocus
                            required
                            label="Name"
                            fullWidth
                            value={document.name}
                            onChange={e => setDocument(prev => ({ ...prev, name: e.target.value }))}
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            value={document.description || ''}
                            onChange={e => setDocument(prev => ({ ...prev, description: e.target.value || null }))}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default DocumentDialog;
