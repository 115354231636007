import React, { useCallback, useMemo, useRef } from 'react';
import ReactFlow, { Node, Edge, Background, Controls, NodeTypes, NodeChange } from 'reactflow';
import 'reactflow/dist/style.css';
import { agendaSteps, AgendaStep } from 'data/agenda';
import { Box } from '@mui/material';
import CustomEdge from './agenda/Edge';
import AgendaStepNode from './agenda/Node';

// Helper function to format text

// Custom node component

const nodeTypes: NodeTypes = {
    agendaStep: AgendaStepNode
};

// Update the wrapText helper function for shorter lines
const wrapText = (text: string, maxLength: number = 15) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach(word => {
        if (currentLine.length + word.length > maxLength) {
            lines.push(currentLine.trim());
            currentLine = word;
        } else {
            currentLine += (currentLine ? ' ' : '') + word;
        }
    });
    lines.push(currentLine.trim());
    return lines.join('\n');
};

// Add the edge types configuration
const edgeTypes = {
    'custom-edge': CustomEdge
};

interface AgendaTabProps {
    sequenceId?: string;
}

const AgendaTab: React.FC<AgendaTabProps> = () => {
    // Add state for nodes
    const [currentNodes, setCurrentNodes] = React.useState<Node[]>([]);
    console.log('r');
    // Update the useMemo to set initial nodes
    const { edges } = useMemo(() => {
        const nodes: Node[] = [];
        const edges: Edge[] = [];
        const nodePositions = new Map<string, { x: number; y: number }>();

        // Find root nodes (nodes with no incoming edges)
        const findParents = (nodeId: string) => {
            return agendaSteps.filter(
                step =>
                    step.nextStep?.conditionalTransitions?.some(t => t.nextStep === nodeId) ||
                    step.nextStep?.defaultTransition === nodeId
            );
        };

        // Calculate levels for each node
        const nodeLevels = new Map<string, number>();
        const calculateLevel = (stepId: string, level: number = 0) => {
            if (nodeLevels.has(stepId)) {
                return;
            }

            const parents = findParents(stepId);
            if (parents.length === 0) {
                return; // Skip orphan nodes
            }

            const parentLevels = parents.map(p => nodeLevels.get(p.id) || 0);
            nodeLevels.set(stepId, Math.max(...parentLevels) + 1);

            const step = agendaSteps.find(s => s.id === stepId);
            if (step?.nextStep) {
                if (step.nextStep.defaultTransition) {
                    calculateLevel(step.nextStep.defaultTransition, level + 1);
                }
                step.nextStep.conditionalTransitions?.forEach(t => {
                    calculateLevel(t.nextStep, level + 1);
                });
            }
        };

        // Calculate levels for all nodes
        agendaSteps.forEach(step => calculateLevel(step.id));

        // Helper function to get node position
        const getNodePosition = (id: string) => {
            if (!nodePositions.has(id)) {
                const level = nodeLevels.get(id) || 0;
                const nodesAtLevel = Array.from(nodeLevels.entries()).filter(([_, l]) => l === level);
                const indexAtLevel = nodesAtLevel.findIndex(([nid]) => nid === id);

                nodePositions.set(id, {
                    x: indexAtLevel * 500, // Increased from 400 to 500 for more horizontal spacing
                    y: level * 600 // Increased from 500 to 600 for more vertical spacing
                });
            }
            return nodePositions.get(id)!;
        };

        // Create nodes and edges
        const processNode = (step: AgendaStep) => {
            // Remove the parent check to keep all nodes
            const position = getNodePosition(step.id);

            nodes.push({
                id: step.id,
                type: 'agendaStep',
                position,
                data: step,
                draggable: true
            });

            if (step.nextStep?.conditionalTransitions) {
                step.nextStep.conditionalTransitions.forEach((transition, idx, arr) => {
                    const totalTransitions = arr.length;
                    const offset = idx - (totalTransitions - 1) / 2;

                    edges.push({
                        id: `${step.id}-${transition.nextStep}-${idx}`,
                        source: step.id,
                        target: transition.nextStep,
                        label: transition.condition,
                        type: 'custom',
                        style: { stroke: '#2563eb', strokeWidth: 2 },
                        data: { offset: offset * 40 }
                    });
                });
            }

            if (step.nextStep?.defaultTransition) {
                edges.push({
                    id: `${step.id}-${step.nextStep.defaultTransition}-default`,
                    source: step.id,
                    target: step.nextStep.defaultTransition,
                    label: 'default',
                    type: 'custom',
                    style: { stroke: '#9333ea', strokeWidth: 2 },
                    data: { offset: 0 }
                });
            }
        };

        // Process all steps
        agendaSteps.forEach(step => processNode(step));

        // Update the return to include setCurrentNodes
        setCurrentNodes(nodes);
        return {
            nodes,
            edges: edges.map(edge => ({
                ...edge,
                type: 'custom-edge',
                style: {
                    ...edge.style,
                    strokeWidth: 2
                }
            }))
        };
    }, []);

    const debounceTimeout = useRef<NodeJS.Timeout>();

    const onNodesChange = useCallback((changes: NodeChange[]) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            setCurrentNodes(nds => {
                const relevantChanges = changes.filter(
                    change => change.type === 'position' && change.dragging && 'position' in change
                );

                if (relevantChanges.length === 0) return nds;

                return relevantChanges.reduce(
                    (acc, change) => {
                        if ('id' in change && 'position' in change) {
                            const nodeIndex = acc.findIndex(n => n.id === change.id);
                            if (nodeIndex !== -1) {
                                acc[nodeIndex] = { ...acc[nodeIndex], position: change.position! };
                            }
                        }
                        return acc;
                    },
                    [...nds]
                );
            });
        }, 10); // 10ms delay
    }, []);

    return (
        <Box className="h-[780px] w-full">
            <ReactFlow
                nodes={currentNodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={() => {}}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
                minZoom={0.1}
                maxZoom={1.5}
                defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
                attributionPosition="bottom-right"
                // Add these options for better edge routing
                defaultEdgeOptions={{
                    type: 'smoothstep'
                }}
                // Increase spacing between elements
                nodesDraggable={true}
                elementsSelectable={true}
                snapToGrid={true}
                snapGrid={[15, 15]}
            >
                <Background color="#aaa" gap={16} />
                <Controls />
            </ReactFlow>
        </Box>
    );
};

export default AgendaTab;
