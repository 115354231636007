import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getDocuments, { DocumentNormal } from 'api/document/get-documents';
import SpinLoader from 'components/ui/spinLoader';
import { PlusIcon, TrashIcon, DocumentIcon } from '@heroicons/react/24/outline';
import createDocument, { NewDocument } from 'api/document/create-document';
import deleteDocument from 'api/document/delete-document';
import DocumentDialog from './Document/DocumentDialogComp';

function Documents() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState<DocumentNormal[]>([]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            setIsLoading(true);
            try {
                const response = await getDocuments();
                setDocuments(response.documents);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching documents:', error);
                setIsLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    const handleAddDocument = async (newDocument: NewDocument) => {
        try {
            const response = await createDocument({ ...newDocument });
            setDocuments(prevDocs => [response, ...prevDocs]);
            return response;
        } catch (error) {
            console.error('Error creating document:', error);
            throw error;
        }
    };

    const handleDeleteDocument = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await deleteDocument({ id });
                setDocuments(prevDocs => prevDocs.filter(doc => doc.id !== id));
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    };

    const handleDocumentClick = (id: string, event: React.MouseEvent) => {
        // Prevent navigation if clicking the delete button
        if ((event.target as HTMLElement).closest('button')) {
            return;
        }
        navigate(`/documents/${id}`);
    };

    if (isLoading) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;

    return (
        <div className="flex h-full w-full flex-col">
            <div className="mb-6">
                <div className="h-[4rem] border-b border-gray-200">
                    <div className="flex h-full items-center px-4 sm:px-6 lg:px-8">
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                                <DocumentIcon className="mr-2 h-5 w-5 text-blue-600" />
                                <div>
                                    <h1 className="text-lg font-medium text-gray-900">Documents</h1>
                                </div>
                            </div>
                            <button
                                onClick={() => setIsAddDialogOpen(true)}
                                className="inline-flex items-center gap-1.5 rounded-md bg-blue-600 px-2.5 py-1 text-xs font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                            >
                                <PlusIcon className="h-3.5 w-3.5" />
                                Add Document
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                        {documents.map(doc => (
                            <div
                                key={doc.id}
                                onClick={e => handleDocumentClick(doc.id, e)}
                                className="group relative border border-gray-200 bg-white p-5 transition-all hover:cursor-pointer hover:bg-gray-50"
                            >
                                <button
                                    className="absolute right-2 top-2 p-2 text-gray-400 opacity-0 transition-opacity hover:bg-gray-100 hover:text-gray-500 group-hover:opacity-100"
                                    onClick={() => handleDeleteDocument(doc.id)}
                                >
                                    <TrashIcon className="h-5 w-5" />
                                </button>
                                <h3 className="mb-2 text-lg font-medium text-gray-900">{doc.name}</h3>
                                {doc.description && <p className="text-sm text-gray-600">{doc.description}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <DocumentDialog
                isOpen={isAddDialogOpen}
                onClose={() => setIsAddDialogOpen(false)}
                onSave={handleAddDocument}
                mode="create"
            />
        </div>
    );
}

export default Documents;
