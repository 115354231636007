import React from 'react';
import { TypeContactDetail, ISlotV1 } from 'api/get-contact-detail';
import { Label } from 'components/ui/label';
import { IMessage } from 'types/ai';
import * as Dialog from '@radix-ui/react-dialog';
import { Link } from 'react-router-dom';

function AssistantMessageView(props: { message: IMessage }) {
    return (
        <div className="w-fit min-w-28 rounded bg-green-200 p-1 text-sm">
            <Label className="capitalize">{props.message.role}:</Label>
            <p>{props.message.content}</p>
        </div>
    );
}

function UserMessageView(props: { message: IMessage }) {
    return (
        <div className="flex justify-end">
            <div className="min-w-28 rounded bg-blue-200 p-1 text-sm">
                <Label className="capitalize">{props.message.role}:</Label>
                <p>{props.message.content}</p>
            </div>
        </div>
    );
}

function ContactDetailCall(props: { contactInfo: TypeContactDetail }) {
    const getDatetime = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    return (
        <div className="flex h-[calc(100vh-9rem)] w-full flex-col gap-4 overflow-auto">
            {props.contactInfo.callInfo.map((call, index) => {
                return (
                    <Dialog.Root key={call.id}>
                        <div
                            className={`flex justify-between border ${
                                index === 0 ? 'border-green-500' : 'border-gray-200'
                            } bg-white p-3 shadow-sm`}
                        >
                            <div className="w-full">
                                <div className="flex items-center gap-1">
                                    <Label className="font-semibold capitalize">Call time:</Label>
                                    <p className="">{getDatetime(call.timestamp)}</p>
                                    <button
                                        className="ml-2 border border-gray-200 bg-white px-2 py-1 text-xs hover:bg-gray-50"
                                        onClick={() => {
                                            if (call.providerUrl) {
                                                navigator.clipboard.writeText(call.providerUrl);
                                                window.open(call.providerUrl, '_blank');
                                            }
                                        }}
                                        title="Provider URL"
                                    >
                                        Provider
                                    </button>
                                    <Dialog.Trigger>
                                        <button className="ml-2 border border-gray-200 bg-white px-2 py-1 text-xs hover:bg-gray-50">
                                            Transcript
                                        </button>
                                    </Dialog.Trigger>
                                    <Link
                                        className="ml-2 border border-gray-200 bg-white px-2 py-1 text-xs hover:bg-gray-50"
                                        to={call.externalUrl || ''}
                                        target="_blank"
                                    >
                                        {call.externalUrl ? 'Hubspot' : 'N/A'}
                                    </Link>
                                </div>
                                <div className="flex items-center gap-1">
                                    <Label className="font-semibold capitalize">Status:</Label>
                                    <p className="">{call.status}</p>
                                </div>
                                {index === 0 && (
                                    <div className="mt-2">
                                        <Label className="font-semibold capitalize">Collection:</Label>
                                        <div className="mt-1 max-h-80 overflow-auto border border-gray-200 bg-white shadow-sm">
                                            <table className="w-full table-fixed border-collapse">
                                                <thead>
                                                    <tr className="bg-gray-100">
                                                        <th className="w-1/2 p-2 text-left">Key</th>
                                                        <th className="w-1/2 p-2 text-left">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.entries(call.collection).map(
                                                        ([key, value]: [string, ISlotV1]) => (
                                                            <tr key={key} className="border-t hover:bg-gray-50">
                                                                <td className="break-words p-2 capitalize">{key}</td>
                                                                <td className="break-words p-2">
                                                                    {value.value !== null ? String(value.value) : 'N/A'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {call.summary && (
                                    <div className="items-center gap-1">
                                        <Label className="font-semibold capitalize">Summary:</Label>
                                        <p className="">{call.summary}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <Dialog.Portal>
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
                            <Dialog.Content className="fixed left-1/2 top-1/2 max-h-[80vh] max-w-md -translate-x-1/2 -translate-y-1/2 overflow-auto border border-gray-200 bg-white p-4 shadow-sm">
                                <Dialog.Title className="p-2">Conversation</Dialog.Title>
                                <div>
                                    <div className="flex h-full flex-col justify-between gap-5">
                                        <div className="h-[calc(100%-3rem)]">
                                            <div className="flex h-[calc(100%-2rem)] flex-col gap-2 overflow-y-auto rounded border p-1">
                                                {call.conversations.map((c, i) => {
                                                    if (c.role === 'user') {
                                                        return <UserMessageView key={i} message={c} />;
                                                    } else if (c.role === 'assistant') {
                                                        return <AssistantMessageView key={i} message={c} />;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Content>
                        </Dialog.Portal>
                    </Dialog.Root>
                );
            })}
        </div>
    );
}

export default ContactDetailCall;
