import React from 'react';
import { SortModelItem } from 'stores/contact-table-config';
import { cn } from 'lib/utils';

type props = {
    sortModels: SortModelItem[];
    onSortChange: (colId: string) => void;
};

function Sort(props: props) {
    return (
        <div className="flex grow items-center gap-2">
            <span className="text-sm font-medium text-control-text">Sort by </span>
            {props.sortModels.map(sort => (
                <button
                    key={sort.colId}
                    className={cn(
                        'flex items-center gap-1 rounded px-2 py-1 text-sm transition-colors',
                        sort.sort === null
                            ? 'border border-control-border text-control-text hover:bg-control-hover'
                            : 'border-2 border-primary-500 text-primary-500'
                    )}
                    onClick={() => props.onSortChange(sort.colId)}
                >
                    {sort.label}
                    {sort.sort === 'asc' && <span className="text-status-success">↑</span>}
                    {sort.sort === 'desc' && <span className="text-status-error">↓</span>}
                </button>
            ))}
        </div>
    );
}

export default Sort;
