import React from 'react';
import SpinLoader from './spinLoader';

export default function TableLoader() {
    return (
        <div className="flex h-full w-full items-center justify-center">
            <div className="flex flex-col items-center gap-4">
                <SpinLoader className="h-8 w-8 animate-spin text-primary-500" />
                <span className="text-sm text-gray-500">Loading data...</span>
            </div>
        </div>
    );
}
