import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import useContactTableConfigStore from 'stores/contact-table-config';
import Loader from 'components/Loader';
import getSequences, { SequenceArray } from 'api/getSequences';

interface SequencePickerProps {
    pageId: string;
    onSequenceChange?: (sequence: SequenceArray) => void;
    label?: string;
}

function SequencePicker({ pageId, onSequenceChange, label = 'Sequence' }: SequencePickerProps) {
    const [sequences, setSequences] = useState<SequenceArray[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { updateSequenceSelection, getSequenceSelection } = useContactTableConfigStore();

    useEffect(() => {
        const fetchSequences = async () => {
            setIsLoading(true);
            try {
                const fetchedSequences = await getSequences();
                setSequences(fetchedSequences);

                const currentSelection = getSequenceSelection(pageId);
                if (!currentSelection && fetchedSequences.length > 0) {
                    const firstSequence = fetchedSequences[0];
                    updateSequenceSelection(pageId, firstSequence.id);
                    onSequenceChange?.(firstSequence);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching sequences:', error);
                setIsLoading(false);
            }
        };
        fetchSequences();
    }, [pageId, label]);

    const handleSequenceChange = (event: SelectChangeEvent) => {
        const sequenceId = event.target.value;
        const selectedSequence = sequences.find(seq => seq.id === sequenceId);

        updateSequenceSelection(pageId, sequenceId);

        if (selectedSequence && onSequenceChange) {
            onSequenceChange(selectedSequence);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    const currentSelection = getSequenceSelection(pageId);
    const currentSequenceId = currentSelection ?? '';

    return (
        <FormControl sx={{ minWidth: 120, height: '2rem' }}>
            <InputLabel id="sequence-select-label" sx={{ lineHeight: '2rem', top: '-6px' }}>
                {label}
            </InputLabel>
            <Select
                labelId="sequence-select-label"
                id="sequence-select"
                value={currentSequenceId}
                label={label}
                onChange={handleSequenceChange}
                sx={{ height: '2rem' }}
            >
                {sequences.map(sequence => (
                    <MenuItem key={sequence.id} value={sequence.id}>
                        {sequence.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SequencePicker;
