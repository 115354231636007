import httpClient from '../http-client';
import { DocumentNormal } from './get-documents';

export interface UpdateDocumentPayload {
    id: string;
    name: string;
    description: string | null;
}

const updateDocument = async (payload: UpdateDocumentPayload): Promise<DocumentNormal> => {
    const { data } = await httpClient.post<DocumentNormal>(`/api/update-document`, {
        id: payload.id,
        name: payload.name,
        description: payload.description
    });
    return data;
};

export default updateDocument;
