import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import { ColDef, SizeColumnsToContentStrategy, GridOptions, SizeColumnsToFitGridStrategy } from 'ag-grid-community';
import Loader from 'components/Loader';
import type { DbContact } from '../../types/dbContact';
import { Switch } from '@mui/material';
import SpinLoader from 'components/ui/spinLoader';
import getContacts, { FilterModelItemInPayload, NORMAL_AI_STATUS } from 'api/getContacts';
import Filter, { FilterModelItem } from './SortAndFilter/Filter';
import Sort from './SortAndFilter/Sort';
import debouncer from 'lib/debouncer';
import useContactTableConfigStore, { SortModelItem } from 'stores/contact-table-config';
import { columnDefinitions } from './contactsTable/colDef';
import updateSequence from 'api/updateSequence';
import Tooltip from 'components/Tooltip';
import { cn } from 'lib/utils';
import TableLoader from 'components/ui/TableLoader';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

interface ContactsProps {
    currentSequenceId: string | null;
}

function Contacts({ currentSequenceId }: ContactsProps) {
    const [rowData, setRowData] = useState<DbContact[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const contactTableConfigStore = useContactTableConfigStore();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalContacts, setTotalContacts] = useState(0);
    const [intakeStatusArray, setIntakeStatusArray] = useState<string[]>([]);
    const [aiEnabledCompany, setAiEnabledCompany] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search, setSearch] = useState<null | string>(null);
    const [aiEnabledAutoTakeContact, setAiEnabledAutoTakeContact] = useState<boolean>(false);
    const [autoTakeContactRatio, setAutoTakeContactRatio] = useState<number>(0);
    const [aiEnabledUpdatedReason, setAiEnabledUpdatedReason] = useState<string | null>(null);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>(columnDefinitions);
    const PAGESIZE = 20; // Define the page size

    const fetchContacts = async (
        sequenceId: string | null,
        page: number,
        pageSize: number,
        sortModel: SortModelItem[],
        filterModel: FilterModelItem[]
    ) => {
        setIsLoading(true);
        const sortFields = sortModel.filter(item => item.sort !== null).map(item => item.colId);
        const sortOrders: ('asc' | 'desc')[] = [];
        for (const item of sortModel) {
            if (item.sort !== null) {
                sortOrders.push(item.sort);
            }
        }

        // Combine custom filter with grid filter model
        const combinedFilterModel: FilterModelItemInPayload = filterModel.map(item => {
            if (item.filterType === 'text') {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    type: item.type,
                    filter: item.filter
                };
            } else if (item.filterType === 'date') {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    type: item.type,
                    dateFrom: item.dateFrom,
                    dateTo: item.dateTo
                };
            } else {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    id: item.id
                };
            }
        });

        const res = await getContacts({
            sequenceId: sequenceId,
            search: search,
            page: page,
            pageSize: pageSize,
            sortFields,
            sortOrders,
            filters: combinedFilterModel
        });
        const contacts = res.result;

        const totalData = res.total;
        setTotalContacts(totalData);
        setTotalPages(Math.ceil(totalData / pageSize));
        setRowData(contacts);
        setAiEnabledCompany(res.aiEnabled);
        setIntakeStatusArray(res.intakeStatusArray);
        setColumnDefs(prev =>
            prev.map(item => {
                if (item.field === 'intakeStatus') {
                    return {
                        ...item,
                        cellRendererParams: { intakeStatusArray: res.intakeStatusArray }
                    };
                } else {
                    return item;
                }
            })
        );
        setAiEnabledAutoTakeContact(res.aiEnabledAutoTakeContact);
        setAutoTakeContactRatio(res.autoTakeContactRatio);
        setAiEnabledUpdatedReason(res.aiEnabledUpdatedReason);
        setIsLoading(false);
    };

    useEffect(() => {
        if (contactTableConfigStore.isSequenceSet()) {
            fetchContacts(
                currentSequenceId,
                currentPage,
                PAGESIZE,
                contactTableConfigStore.config.sort,
                contactTableConfigStore.config.filter
            );
        }
    }, [currentPage, contactTableConfigStore.config.filter, contactTableConfigStore.config.sort, currentSequenceId]);

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const autoSizeStrategy: SizeColumnsToContentStrategy | SizeColumnsToFitGridStrategy = {
        // type: 'fitCellContents'
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [
            {
                colId: 'firstInteractionAt',
                minWidth: 160
            },
            {
                colId: 'userLastInteractionAt',
                minWidth: 160
            },
            {
                colId: 'aiEnabled',
                minWidth: 120
            },
            {
                colId: 'aiEnabledReplySmsCall',
                minWidth: 120
            }
        ]
    };

    const gridOptions: GridOptions = {
        autoSizeStrategy: autoSizeStrategy,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        rowHeight: 48,
        headerHeight: 48,
        animateRows: true,
        getRowStyle: function (params) {
            // Check if intakeStatus is not null and apply light blue background
            if (params.data.intakeStatus !== null) {
                return { backgroundColor: '#E3F2FD' }; // primary-50 (light blue)
            }
            // Keep existing condition for AI enabled
            if (params.data.aiEnabled === false) {
                return { backgroundColor: '#E3F2FD' };
            }
            return undefined;
        },
        rowClass: 'hover:bg-gray-50 transition-colors',
        pagination: true,
        paginationPageSize: PAGESIZE,
        suppressPaginationPanel: true
    };

    if (!currentSequenceId) {
        return null;
    }

    const handleSearch = async () => {
        setIsSubmitting(true);
        setCurrentPage(1); // Reset page to 1 when searching
        await fetchContacts(
            currentSequenceId,
            currentPage,
            PAGESIZE,
            contactTableConfigStore.config.sort,
            contactTableConfigStore.config.filter
        );
        setIsSubmitting(false);
    };

    const handleSortChange = (colId: string) => {
        const items = contactTableConfigStore.config.sort.filter(item => item.colId === colId);
        if (items.length > 0) {
            const item = items[0];
            if (item.sort === 'asc') {
                contactTableConfigStore.updateSort({ ...item, sort: 'desc' });
            } else if (item.sort === 'desc') {
                contactTableConfigStore.updateSort({ ...item, sort: null });
            } else {
                contactTableConfigStore.updateSort({ ...item, sort: 'asc' });
            }
        }
    };

    const handleFilterChange = (
        colId: string,
        datetimeFrom: number | null,
        datetimeTo: number | null,
        textFilter?: string | null
    ) => {
        const items = contactTableConfigStore.config.filter.filter(item => item.colId === colId);
        if (items.length > 0) {
            const item = items[0];
            if (item.filterType === 'date') {
                contactTableConfigStore.updateFilter({
                    ...item,
                    dateFrom: datetimeFrom,
                    dateTo: datetimeTo
                });
            } else if (item.filterType === 'text') {
                contactTableConfigStore.updateFilter({
                    ...item,
                    filter: textFilter || null
                });
            } else if (item.filterType === 'uuid') {
                contactTableConfigStore.updateFilter({
                    ...item,
                    id: textFilter || null
                });
            }
        }
    };

    return (
        <div className="mt-4 flex h-[calc(100vh-7rem)] flex-col space-y-4 overflow-hidden bg-white px-4 sm:px-6 lg:px-8">
            {/* Header Section with Controls */}
            <div className="flex h-12 items-center justify-between border border-gray-200 bg-white px-3 shadow-sm">
                {/* Left side controls */}
                <div className="flex items-center divide-x divide-gray-200">
                    <div className="text-sm">
                        <span className="font-medium text-gray-700">Total Contacts:</span>{' '}
                        <span className="font-medium text-blue-600">{totalContacts}</span>
                    </div>

                    <div className="ml-4 flex items-center gap-3 pl-4">
                        <div className="flex items-center gap-2">
                            <Tooltip title={aiEnabledUpdatedReason || 'No reason provided'} placement="top">
                                <span className="text-sm font-medium text-gray-700">AI enabled:</span>
                            </Tooltip>
                            <Switch checked={aiEnabledCompany} disabled={true} size="small" className="ai-switch" />
                        </div>

                        {aiEnabledCompany && (
                            <>
                                <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-gray-700">Auto-take:</span>
                                    <Switch checked={aiEnabledAutoTakeContact} disabled={true} size="small" />
                                </div>
                                <div className="flex items-center gap-2">
                                    <span className="text-sm font-medium text-gray-700">Ratio:</span>
                                    <input
                                        type="number"
                                        value={autoTakeContactRatio}
                                        disabled={true}
                                        className={cn(
                                            'h-7 w-14 rounded border px-1 text-center text-sm',
                                            aiEnabledAutoTakeContact && autoTakeContactRatio > 0
                                                ? 'border-accent-success bg-accent-success/10'
                                                : 'border-control-border bg-control-hover'
                                        )}
                                    />
                                    <span className="text-sm text-control-text">%</span>
                                </div>
                            </>
                        )}
                        <Link
                            to={`/sequences/${currentSequenceId}`}
                            className="ml-2 flex items-center gap-1 text-sm font-medium text-blue-600 hover:text-blue-700 hover:underline"
                        >
                            <ArrowTopRightOnSquareIcon className="h-3.5 w-3.5" />
                            <span>Agent Setup</span>
                        </Link>
                    </div>
                </div>

                {/* Search Box */}
                <div className="flex items-center gap-2">
                    <input
                        type="text"
                        placeholder="Search contacts..."
                        className="h-8 w-48 rounded-md border border-gray-300 bg-gray-50 px-3 text-sm text-gray-900 placeholder-gray-500 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-1 focus:ring-blue-500"
                        onChange={e => setSearch(e.target.value)}
                        onKeyDown={async e => {
                            if (e.key === 'Enter') await handleSearch();
                        }}
                    />
                    <button
                        onClick={handleSearch}
                        disabled={isSubmitting}
                        className="flex h-8 items-center gap-1 rounded-md bg-blue-600 px-4 text-sm font-medium text-white hover:bg-blue-700 disabled:bg-gray-300"
                    >
                        {isSubmitting && <SpinLoader className="h-3 w-3 animate-spin" />}
                        Search
                    </button>
                </div>
            </div>

            {/* Filter and Sort Controls */}
            <div className="flex flex-col border border-gray-200 bg-white shadow-sm">
                {/* Sort Controls Row */}
                <div className="flex h-10 items-center px-3 py-2">
                    <Sort sortModels={contactTableConfigStore.config.sort} onSortChange={handleSortChange} />
                </div>

                {/* Horizontal divider */}
                <div className="h-px w-full bg-gray-100" />

                {/* Filter Controls Row */}
                <div className="flex h-10 items-center px-3 py-2">
                    <Filter
                        filterModel={contactTableConfigStore.config.filter.filter(item => item.colId !== 'sequenceId')}
                        intakeStatusArray={[NORMAL_AI_STATUS, ...intakeStatusArray]}
                        onFilterChange={handleFilterChange}
                    />
                </div>
            </div>

            {/* Data Grid */}
            <div className="flex-1 overflow-hidden bg-white shadow-sm">
                <div className="ag-theme-gcp h-full w-full overflow-auto">
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        gridOptions={{
                            ...gridOptions,
                            defaultColDef: {
                                headerClass: 'ag-theme-custom-header',
                                cellClass: 'ag-theme-custom-cell'
                            },
                            loadingOverlayComponent: TableLoader,
                            loadingOverlayComponentParams: {
                                loadingMessage: 'One moment, please...'
                            },
                            noRowsOverlayComponent: () => <div className="text-sm text-gray-500">No data</div>,
                            noRowsOverlayComponentParams: {
                                loadingMessage: 'No data available'
                            }
                        }}
                        onGridReady={params => {
                            if (rowData.length === 0) {
                                params.api.showNoRowsOverlay();
                            } else {
                                params.api.showLoadingOverlay();
                                params.api.hideOverlay();
                            }
                        }}
                    />
                </div>
            </div>

            {/* Pagination Controls */}
            <div className="flex h-12 items-center justify-between border border-gray-200 bg-white px-4 shadow-sm">
                <div className="text-sm text-gray-600">
                    Showing page <span className="font-medium text-gray-900">{currentPage}</span> of{' '}
                    <span className="font-medium text-gray-900">{totalPages}</span>
                </div>
                <div className="flex items-center gap-2">
                    <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        className="h-8 rounded-md bg-blue-600 px-4 text-sm font-medium text-white hover:bg-blue-700 disabled:bg-gray-300"
                    >
                        Previous
                    </button>
                    <div className="flex h-8 w-16 items-center justify-center rounded-md border border-gray-300 bg-gray-50 px-2 text-sm font-medium text-gray-900">
                        {currentPage}
                    </div>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="h-8 rounded-md bg-blue-600 px-4 text-sm font-medium text-white hover:bg-blue-700 disabled:bg-gray-300"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Contacts;
