import httpClient from '../http-client';

export interface FAQ {
    id: string;
    query: string[];
    content: string;
}

export interface DocumentWithFAQs {
    document: {
        id: string;
        name: string;
        description: string | null;
        content: FAQ[];
    };
}

const getDocument = async (id: string): Promise<DocumentWithFAQs> => {
    const { data } = await httpClient.post<DocumentWithFAQs>(`/api/get-document`, { id });
    return data;
};

export default getDocument;
