import httpClient from '../http-client';

export interface UpdateDocumentItem {
    id: string;
    query: string[];
    content: string;
}

export interface Response {
    message: string;
}

const updateDocumentItem = async (payload: UpdateDocumentItem): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-document-item', payload);
    return data;
};

export default updateDocumentItem;
