import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { TypeContactDetail } from 'api/get-contact-detail';
import { Label } from 'components/ui/label';
import SendSmsForm from './CallAndSMS/SendSmsForm';
import useUserStore from 'stores/user';
import config from 'utils/config';
import AiIcon from 'components/icons/AiIcon';

function AssistantMessageView(props: { content: string; datetime: string; status: string | null }) {
    return (
        <div className="mb-2 max-w-[85%] px-4">
            <div className="flex items-baseline gap-2">
                <Label className="flex items-center gap-1.5 text-[11px] font-medium text-gray-500">
                    <AiIcon className="h-5 w-5 text-gray-500" />({props.datetime})
                    {props.status && (
                        <span className="ml-1.5 rounded-full bg-blue-100 px-2 py-0.5 text-[11px] font-semibold text-blue-700">
                            {props.status}
                        </span>
                    )}
                </Label>
            </div>
            <div className="mt-1 w-fit">
                <p className="font-inter whitespace-pre-wrap break-words py-2 text-[13px] font-medium leading-[1.5] text-[#1A2B33]">
                    {props.content}
                </p>
            </div>
        </div>
    );
}

function SystemMessageView(props: { content: string; datetime: string; isSuperpanelUser: boolean | undefined }) {
    const component = (
        <div className="mb-2 max-w-[85%] px-4">
            <div className="flex items-baseline gap-2">
                <Label className="text-[11px] font-medium text-gray-500">System ({props.datetime})</Label>
            </div>
            <div className="mt-1 w-fit">
                <p className="font-inter whitespace-pre-wrap break-words rounded-r rounded-bl bg-[#F5E6D3] px-3 py-2 text-[13px] font-medium leading-[1.5] text-[#2A1810] shadow-sm">
                    {props.content}
                </p>
            </div>
        </div>
    );

    if (props.isSuperpanelUser || config.ENV === 'local') {
        return component;
    } else if (props.content.includes('---context start---')) {
        return component;
    }
    return null;
}

function UserMessageView(props: {
    content: string;
    datetime: string;
    smsFileUrls: (string | null)[];
    onImageClick: (url: string) => void;
}) {
    return (
        <div className="mb-2 ml-auto max-w-[85%] px-4">
            <div className="flex justify-end">
                <Label className="text-[11px] font-medium text-gray-500">User ({props.datetime})</Label>
            </div>
            {props.content && (
                <div className="mt-1 flex justify-end">
                    <p className="font-inter max-w-[85%] whitespace-pre-wrap break-words rounded-lg bg-gray-100 px-3 py-2 text-[13px] font-medium leading-[1.5] text-gray-800 shadow-sm">
                        {props.content}
                    </p>
                </div>
            )}
            {props.smsFileUrls && props.smsFileUrls.length > 0 && (
                <div className="mt-2 flex flex-col items-end gap-2">
                    {props.smsFileUrls.map(
                        (url, index) =>
                            url && (
                                <img
                                    key={index}
                                    src={url}
                                    alt={`SMS Media File ${index + 1}`}
                                    className="max-h-48 cursor-pointer rounded shadow-sm transition-transform hover:scale-[0.98]"
                                    onClick={() => props.onImageClick(url)}
                                />
                            )
                    )}
                </div>
            )}
        </div>
    );
}

function ContactDetailSms(props: { contactInfo: TypeContactDetail }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
    const userStore = useUserStore();

    const getDatetime = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const handleImageClick = (url: string) => {
        setSelectedImageUrl(url);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedImageUrl(null);
    };

    return (
        <div className="flex h-[calc(100vh-10rem)] w-full flex-col justify-between gap-4">
            <div className="flex h-full flex-col gap-3 overflow-auto border pt-3">
                {props.contactInfo.smsInfo.map(sms => {
                    return (
                        <div key={sms.id}>
                            {sms.role === 'user' ? (
                                <UserMessageView
                                    content={sms.content}
                                    datetime={getDatetime(sms.timestamp)}
                                    smsFileUrls={sms.smsFileUrls}
                                    onImageClick={handleImageClick}
                                />
                            ) : sms.role === 'assistant' ? (
                                <AssistantMessageView
                                    content={sms.content}
                                    datetime={getDatetime(sms.timestamp)}
                                    status={sms.status}
                                />
                            ) : (
                                <SystemMessageView
                                    content={sms.content}
                                    datetime={getDatetime(sms.timestamp)}
                                    isSuperpanelUser={userStore.isSuperpanelUser()}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <div>{<SendSmsForm contactId={String(props.contactInfo.generalInfo.id)} />}</div>

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: 'white',
                        margin: 'auto',
                        marginTop: '10%',
                        width: '80%',
                        minHeight: '600px',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'auto'
                    }}
                >
                    <div className="m-4 h-[200px]">
                        {selectedImageUrl && (
                            <img
                                src={selectedImageUrl}
                                alt="SMS Media File"
                                style={{
                                    width: 'auto',
                                    height: '600px'
                                }}
                            />
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ContactDetailSms;
