const manufacturersGroup1Detail = [
    'Ford, Lincoln',
    'General Motors, Chevrolet, GMC, Buick, Cadillac',
    'Stellantis, Chrysler, Dodge, Jeep, Ram, Fiat, Alfa Romeo',
    'Honda, Acura',
    'Hyundai, Kia, Genesis',
    'kia',
    'mazda',
    'mitsubishi',
    'Nissan, Infiniti',
    'rivian',
    'subaru',
    'tata',
    'tesla'
];

const manufacturersGroup2Detail = [
    'BMW, Mini',
    'Mercedes-Benz',
    'Volkswagen, Audi, Porsche, Bentley, Bugatti',
    'Toyota, Lexus'
];

type SharedCollection = {
    id: string;
    description?: string;
    prompt: string;
    dataType: 'boolean' | 'string' | 'number' | 'datetime';
    object: string | null;
    integration: string | null;
    isSystem: boolean;
    slotFillingStructured?: {
        prompt: string;
        schemaName: string;
        schema: {
            type: 'object';
            properties: {
                slot_id: { type: 'string'; const: string };
                reason: {
                    type: ['string', 'null'];
                    description: string;
                };
                value: {
                    type: string | number | boolean | null | string[];
                    description: string;
                    enum?: (string | null)[] | string | number | boolean | null;
                };
                state: {
                    type: 'string';
                    enum: string[];
                    description: string;
                };
                msg_for_future: {
                    type: ['string', 'null'];
                    description: string;
                };
            };
            required: string[];
            additionalProperties: boolean;
        };
    };
    dependOn?: string[];
};

export const sharedCollections: SharedCollection[] = [
    {
        id: 'firstname',
        prompt: 'first name of the customer',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'lastname',
        description: 'last name of the customer',
        prompt: 'last name of the customer.',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'email',
        description: 'email of the customer',
        prompt: 'customer email',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'phone',
        prompt: 'customer phone number',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'check_if_call_customer_is_the_right_contact',
        description: 'am I speaking with firstname?',
        prompt: 'Check if the customer is the contact or not. Unless the user says they are not the right user, set the value to true.',
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: true
    },
    {
        id: 'is_customer_available_for_the_call',
        description: `We understand you have a car_year, carmakemodel you told us about in the form you completed. In order to make sure your vehicle qualifies for a lemon law case, I need to ask you around <Number_Of_Question> simple questions regarding your vehicle. Do you have a few minutes to chat?`,
        prompt: `Checking if the customer has time now to chat about their vehicle information. Unless they say they don't have time, set the value to true. If the user was available at the beginning of the call, and during the call, they say they don't have time, you should update the value to false.`,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: true
    },
    {
        id: 'sp_user_wants_to_transfer_call',
        prompt: `true if user has explicitly requested to transfer the call to talk to a human agent. false otherwise. Note that AI should be able to handle the conversation and the desire to talk to a human agent has to be explicitly mentioned by the user. Simply saying "I need help" won't be enough as AI can offer to help.`,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: true
    },
    {
        id: 'is_still_interested_in_moving_forward',
        prompt: `check if the customer is still interested. If they explicitly indicate don't contact me anymore, stop contacting me, they are not interested, went with another firm, wrong phone number, and anything else that indicates they will definitely not move forward, set the value to false, otherwise, set the value to true. If the user's expression is not clear/vague, e.g., I am not sure if I will be able to/be qualified by lemon law, we should consider user is still interested and continue the chat. When you set the value to true, you also set the state to complete.`,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `the field is about whether the customer is still interested in moving forward or not. Here are the True or False options:
- True (still interested): Unless the customer explicitly indicates they will definitely not move forward, set the value to true, this includes: 
-- Vague or unclear responses (e.g., "I am not sure if I will be able to/be qualified by lemon law").
-- Responses indicating they are busy but will follow up later (e.g., "I will follow up later. So stop right now").
-- Any indication of potential future interest or engagement.
-- if customer is asked to stay on the line for transfer, and somehow they say no, this doesn't mean they are not interested, so you should still set the value to true.
-- for voice-mail response from customer, should set the value to true. it doesn't mean the customer is not interested. Voice-mail can be like, "goodbye", "hang up", "press 1 for more options", "leave a message", etc.
-- customer's case is disqualified, and there is no need to move forward, but this doesn't mean the customer is not interested originally.

- False (not interested)
-- If the customer explicitly indicates don't contact me anymore, stop contacting me, they are not interested, went with another firm, wrong phone number, and anything else that indicates they will definitely not move forward, set the value to false (e.g., "I am not interested", "Stop contacting me", "I will not move forward", "stop").

When you set the value to false, you also set the state to complete.
`,
            schemaName: 'is_still_interested_in_moving_forward',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'is_still_interested_in_moving_forward' },
                    reason: {
                        type: ['string', 'null'],
                        description:
                            'the reason why you think the value of is_still_interested_in_moving_forward is true or false'
                    },
                    value: {
                        type: 'boolean',
                        enum: ['true', 'false'],
                        description: `whether or not the customer is still interested in moving forward`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete'],
                        description: `complete: always set the value to complete, if user didn't explicitly indicate they are not interested, or to stop contacting`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'createdate',
        prompt: 'The date that a contact entered the system, we use this as the date when the customer filled the form',
        dataType: 'datetime',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'repairs',
        description:
            'how many times the vehicle has been repaired (selection from 0, 1, 2, 3, 3+), if the number is above 3, set the value to 3+',
        prompt: 'How many times the vehicle has been repaired (selection from 0, 1, 2, 3, 3+), if the number is above 3, set the value to 3+, if not clear, set the value to null',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false,
        slotFillingStructured: {
            prompt: `How many times the vehicle has been repaired (selection from 0, 1, 2, 3, 3+), if the number is above 3, set the value to 3+, if not clear, set the value to null`,
            schemaName: 'repairs',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'repairs' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is'
                    },
                    value: {
                        type: ['string', 'null'],
                        enum: ['0', '1', '2', '3', '3+', null],
                        description:
                            'how many times the vehicle has been repaired, if the number is above 3, set the value to 3+, if not clear, set the value to null'
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'initial'],
                        description: `complete: if the customer has explicitly indicated the number of repairs, or the number is above 3, set the value to complete.
need-more-data: if the number does not exist, and the customer didn't explicitly indicate the number of repairs.
initial: initial value, and no need to update`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'repairs_times',
        prompt: "how many repairs are done? Including repairs that are being done at the moment. If unclear from user's conversation regarding the repair times, set the value to null. There is another CRM field called 'repairs', if present, ignore it, and solely infer the number based on the conversation. Repair should be done by a shop or dealership, not by the customer themselves. If user's answer is an estimation, you should try to get the upper bound of the estimation. Like 3 or 4 times, you should consider 4 times and the state of this slot as complete.",
        dataType: 'number',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `how many repair visits has the customer had for the vehicle?
Concept:
- repair visit: bringing the vehicle to a shop or dealership for repairs
- repair items: the items that are repaired during a repair visit

rules:
- add the repair visit that is being done at the moment, if any.
- if unclear from user's conversation regarding the repair times, set the value to null.
- multiple repair items can be repaired during a single repair visit. e.g., I fixed A and B, but this is counted as 1 repair visit. so if customer just mentions items that are fixed, it's not clear to tell how many repair visits they had. But at least 1 repair visit is guaranteed.
- There is another CRM field called 'repairs', if present, ignore it, and solely infer the number based on the conversation.
- Repair visit should be at a shop or dealership, not by the customer themselves.
- If user's answer is an estimation, you should try to get the upper bound of the estimation. Like 3 or 4 times, you should consider 4 times and the state of this slot as complete.
`,
            schemaName: 'repairs_times',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'repairs_times' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is'
                    },
                    value: {
                        type: ['number', 'null'],
                        description: 'how many times the vehicle has been repaired'
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'initial'],
                        description: `complete: we have enough information to infer the number of repairs.
need-more-data: we don't have enough information to infer the number of repairs.
initial: initial value, and there is no need to update`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    // {
    //     id: 'warranty_repair_',
    //     description:
    //         "If the vehicle has been repaired before, then ask, are these repairs under manufacturer's warranty (if yes, there is higher chance to win the case. manufacturer's extended warranty also counts). If not all the repairs are under the manufacturer's warranty, how many are. Make sure to confirm it's not the third-party warranty.",
    //     prompt: "Are repairs under manufacturer's warranty, just use value, Yes (all repairs are), No (non of the repairs are), or Other (mixed)",
    //     dataType: 'string',
    //     object: 'contact',
    //     integration: 'hubspot',
    //     isSystem: false,
    // },
    // {
    //     id: 'carissue',
    //     description: `what's the general car issue? Is that safty concern, engine, transmission, or other?`,
    //     prompt: "describe if repairs under the same issue, what's the detailed situation, what's been repaired? is it safty concern, engine, transmission related, or other?",
    //     dataType: 'string',
    //     object: 'contact',
    //     integration: 'hubspot',
    //     isSystem: false,
    // },
    {
        id: 'days_out_of_service_more_than_25',
        description: `If the vehicle has been repaired before, and if add up all the days the vehicle was in the shop, was it in the shop for more than 25 (include 25) days total?`,
        prompt: ``,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `The field you need to fill is called days_out_of_service_more_than_25, and you need to decide whether or not the total length of time (in days) the vehicle spent in the repair shop more than 25 days (include 25). If the car is still in shop, or the customer doesn't know how long it was/will be in the shop, set the value to null and state to 'complete'. If a car is never repaired, then set the value to false and state to 'n/a'. If user's answer is an estimation (or not very clear), you should try to get the upper bound of the estimation. Like 25 to 30 days, you should consider 30 days, hence it's more than or equal 25. This question is applicable only if the car manufacturer is one of 
${manufacturersGroup1Detail.join('\n')}, otherwise, set the value to null and state to 'n/a'.`,
            schemaName: 'days_out_of_service_more_than_25',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'days_out_of_service_more_than_25' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is true or false or null'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `think about what is the car make first, if this question is applicable, whether or not the total length of time (in days) the vehicle spent in the repair shop more than 25 days (include 25). If not applicable, set to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'n/a'],
                        description: `complete: customer has shared the duration info. Or customer does not know how long, e.g., the car is still in shop, or the customer doesn't know how long it was/will be in the shop.
need-more-data: use this state if there is no exisitng value, and no new info to update the value. Or we need more data from the customer about the duration
n/a: when the question is not applicable, e.g., the car manufacturer is not in the list`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['car_manufacturer']
    },
    {
        id: 'days_out_of_service_more_than_20',
        description: `If the vehicle has been repaired before, and if add up all the days the vehicle was in the shop, was it in the shop for more than 20 (include 20) days total?`,
        prompt: ``,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `The field you need to fill is called days_out_of_service_more_than_20, and you need to decide whether or not the total length of time (in days) the vehicle spent in the repair shop more than 20 days (include 20). If the car is still in shop, or the customer doesn't know how long it was/will be in the shop, set the value to null and state to 'complete'. If a car is never repaired, then set the value to false and state to 'n/a'. If user's answer is an estimation (or not very clear), you should try to get the upper bound of the estimation. Like 20 to 30 days, you should consider 30 days, hence it's more than or equal 20. This question is applicable only if the car manufacturer is one of 
${manufacturersGroup2Detail.join('\n')}, otherwise, set the value to null and state to 'n/a'.`,
            schemaName: 'days_out_of_service_more_than_20',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'days_out_of_service_more_than_20' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is true or false or null'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `think about what is the car make first, if this question is applicable, whether or not the total length of time (in days) the vehicle spent in the repair shop more than 20 days (include 20). If not applicable, set to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'n/a'],
                        description: `complete: customer has shared the duration info. Or customer does not know how long, e.g., the car is still in shop, or the customer doesn't know how long it was/will be in the shop.
need-more-data: use this state if there is no exisitng value, and no new info to update the value. Or we need more data from the customer about the duration
n/a: when the question is not applicable, e.g., the car manufacturer is not in the list`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['car_manufacturer']
    },
    //     {
    //         id: 'owner_name',
    //         description: "owner's name if it's not the customer himself",
    //         prompt: 'the name of the owner',
    //         dataType: 'string',
    //         object: null,
    //         integration: null,
    //         isSystem: false,
    //         slotFillingStructured: {
    //             prompt: `You are a office worker in a law firm. You need to review customer conversation history of a potential lemon law case and fill in the name of the car owner. If customer is not the owner, ask the full name of the owner.`,
    //             schemaName: 'owner_name',
    //             schema: {
    //                 type: 'object',
    //                 properties: {
    //                     slot_id: { type: 'string', const: 'owner_name' },
    //                     reason: {
    //                         type: ['string', 'null'],
    //                         description: 'the reason why you think the value of owner_name is',
    //                     },
    //                     value: {
    //                         type: ['string', 'null'],
    //                         description: `name of the owner. If the customer is the owner, set the value to customer. If the customer is not the owner, e.g., the vehicle is in another person's name, ask the full name of the owner.`,
    //                     },
    //                     state: {
    //                         type: 'string',
    //                         enum: ['complete', 'need-more-data'],
    //                         description: `complete: customer is the owner, or customer is not the owner and provides the full name of the owner
    // need-more-data: use this state if user has not responded yet, or the response is not clear or complete. If customer is not the owner, e.g., the vehicle is in another person's name, and customer has not provided the full name of the owner, set the state to need-more-data.`,
    //                     },
    //                     msg_for_future: {
    //                         type: ['string', 'null'],
    //                         description:
    //                             'if you think this slot needs to be followed up in future communication, provide a message for the future.',
    //                     },
    //                 },

    //                 required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
    //                 additionalProperties: false,
    //             },
    //         },
    //     },
    //     {
    //         id: 'co_owner_name',
    //         description: "ask the co-owner's name if there is a co-owner",
    //         prompt: 'the name of the co-owner, if any',
    //         dataType: 'string',
    //         object: null,
    //         integration: null,
    //         isSystem: false,
    //         slotFillingStructured: {
    //             prompt: `You are a office worker in a law firm. You need to review customer conversation history of a potential lemon law case. This question is applicable if there is a co_owner. Most often, the customer is the owner, but not always. Their wife, girl friend, etc, can be the owner and they are just calling on bahalf of them. If the customer say the vehicle is in another person's name, that means the car is not co-ownered. And you shoud put 'n/a' in your state.`,
    //             schemaName: 'co_owner_name',
    //             schema: {
    //                 type: 'object',
    //                 properties: {
    //                     slot_id: { type: 'string', const: 'co_owner_name' },
    //                     reason: {
    //                         type: ['string', 'null'],
    //                         description: 'the reason why you think the value of co_owner_name is',
    //                     },
    //                     value: {
    //                         type: ['string', 'null'],
    //                         description: `name of the co_owner`,
    //                     },
    //                     state: {
    //                         type: 'string',
    //                         enum: ['complete', 'need-more-data', 'n/a'],
    //                         description: `complete: user explicitly says there is a co-owner and provides the name
    // need-more-data: use this state if user has not responded yet, or the response is not clear or complete
    // n/a: user explicitly says there is no co-owner, and other not applicable situations, e.g., the vehicle is in another person's name`,
    //                     },
    //                     msg_for_future: {
    //                         type: ['string', 'null'],
    //                         description:
    //                             'if you think this slot needs to be followed up in future communication, provide a message for the future.',
    //                     },
    //                 },

    //                 required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
    //                 additionalProperties: false,
    //             },
    //         },
    //     },
    //     {
    //         id: 'co_owner_relationship',
    //         description: "ask the co-owner's relationship to the owner if there is a co-owner",
    //         prompt: 'the relationship to the owner, if any',
    //         dataType: 'string',
    //         object: null,
    //         integration: null,
    //         isSystem: false,
    //         slotFillingStructured: {
    //             prompt: `You are a office worker in a law firm. You need to review customer conversation history of a potential lemon law case. This field is only applicable if there is a co-owner. Otherwise, give 'n/a' as the state. If the car is solely owned, Then, this question is not applicable. The customer might or might not be the owner. If the customer is the owner and there is a co-owner, you should infer the relationship of the co-owner. If the customer says the vehicle is in another person's name, this indicates there is no co-owner and this question is not applicable.`,
    //             schemaName: 'co_owner_relationship',
    //             schema: {
    //                 type: 'object',
    //                 properties: {
    //                     slot_id: { type: 'string', const: 'co_owner_relationship' },
    //                     reason: {
    //                         type: ['string', 'null'],
    //                         description: 'the reason why you think the value of co_owner_relationship is',
    //                     },
    //                     value: {
    //                         type: ['string', 'null'],
    //                         description: `relationship of the co_owner to the owner. Only applicable if there is a co-owner. If user say it's my wife, my girlfriend, my friend, brother, sister etc, you should infer put the relationship. And if they say it's under someone name, that means the car is not co-ownered.`,
    //                     },
    //                     state: {
    //                         type: 'string',
    //                         enum: ['complete', 'need-more-data', 'n/a'],
    //                         description: `complete: user shared the relationship if ther is a co-owner
    // need-more-data: use this state if user has not responded yet, or the response is not clear or complete. if customer is not the owner, e.g., the vehicle is in another person's name, and customer has not answered if the owner of the vehicle is alone or not, or customer has not provide the relationship of the co-owner to the owner, set the state to need-more-data.
    // n/a: when there is no co-owner`,
    //                     },
    //                     msg_for_future: {
    //                         type: ['string', 'null'],
    //                         description:
    //                             'if you think this slot needs to be followed up in future communication, provide a message for the future.',
    //                     },
    //                 },

    //                 required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
    //                 additionalProperties: false,
    //             },
    //         },
    //     },
    {
        id: 'still_own_the_car',
        description: `does the customer (or its relative, or someone he is calling for) still own the car`,
        prompt: `does the customer (or its relative or or someone he is calling for) still own the car? If user doesn't explicitly say they don't have the car anymore, you can assume user still owns the car. If the customer is calling on behalf of its spouse, we consider they still own the car. If user just mentioned that the car is totaled, this generally means the vehicle is no longer usable, that's all. If user says they are not interested in moving forward, it does not imply they don't have the car. Set the value to true by default, unless the user says they don't have the car anymore.`,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `The field is about if the customer (or its relative, or someone he is calling for) still own the car? 
rules:
- If user doesn't explicitly say they don't have the car anymore, you can assume user still owns the car and always set the state to complete
- If the customer is calling on behalf of its spouse/friend, we consider they still own the car
- If user just mentioned that the car is totaled, this generally means the vehicle is no longer usable, that's all
- If user says they are not interested in moving forward, it does not imply they don't have the car
- Set the value to true by default, unless the user express they don't have the car anymore
- User getting a new does doesn't mean they get rid of the old car, so be cautious on setting the value to false
- Be conservative on setting the value to false. We need to explicitly check if the user expresses that they don't have the car anymore in order to set the value to false
- in order to set the value to false, user must say things like, trade in, get rid of, no longer have, etc, don't infer it. In one case,  'I have no choice but to get a new car', this doesn't mean they don't have the old car anymore.`,
            schemaName: 'still_own_the_car',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'still_own_the_car' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value of still_own_the_car is'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `true: customer didn't say they don't own the car any more. false: customer says they don't own the car.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: it's clear and complete.
need-more-data: customer did mention about ownership, but not clear or complete.`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    //     {
    //         id: 'sole_owner',
    //         description: 'is the owner the only owner of the vehicle',
    //         prompt: 'Does the client own the vehicle alone?',
    //         dataType: 'string',
    //         object: 'contact',
    //         integration: 'hubspot',
    //         isSystem: false,
    //         slotFillingStructured: {
    //             prompt: `You are a office worker in a law firm. You need to review customer conversation history of a potential lemon law case and fill in if the vehicle is owned alone by the owner. If there is a co-owner, then the value should be 'No'.`,
    //             schemaName: 'sole_owner',
    //             schema: {
    //                 type: 'object',
    //                 properties: {
    //                     slot_id: { type: 'string', const: 'sole_owner' },
    //                     reason: {
    //                         type: ['string', 'null'],
    //                         description: 'the reason why you think the value of sole_owner is',
    //                     },
    //                     value: {
    //                         type: ['string'],
    //                         enum: ['Yes', 'No', ''],
    //                         description: `is the vehicle owned solely? if there is a co-owner of the vehicle, then 'No'. If the customer is sole owner, then 'Yes'. If the customer is not the owner, but someone related to the customer is, then 'Yes', e.g., the vehicle is in another person's name.`,
    //                     },
    //                     state: {
    //                         type: 'string',
    //                         enum: ['complete', 'need-more-data'],
    //                         description: `complete: customer explicitly says there is or there is no co-owner, if customer is not the owner, e.g., the vehicle is in another person's name, and customer answered if the owner of the vehicle is alone or not, set the state to complete.
    // need-more-data: use this state if user has not responded yet, or the response is not clear or complete. if customer is not the owner, e.g., the vehicle is in another person's name, and customer has not answered if the owner of the vehicle is alone or not, set the state to need-more-data.`,
    //                     },
    //                     msg_for_future: {
    //                         type: ['string', 'null'],
    //                         description:
    //                             'if you think this slot needs to be followed up in future communication, provide a message for the future.',
    //                     },
    //                 },

    //                 required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
    //                 additionalProperties: false,
    //             },
    //         },
    //     },
    {
        id: 'car_manufacturer',
        description: 'what is the make your vehicle?',
        prompt: 'the make of the vehicle, e.g., Toyota, Honda, Ford, GM, BMW, Tesla, etc.',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false,
        slotFillingStructured: {
            prompt: `You are a car enthusiast, and you have good knowledge about car manufacturers, make, and models. You work as a office worker in a law firm. You need to review customer conversation history of a potential lemon law case. You should infer the manufacturer of the car from the info customer provided. You will be given a part of the most recent conversation and an old car_manufacturer value (if any), do not change the old value if the recent conversation does not provide new info.`,
            schemaName: 'car_manufacturer',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'car_manufacturer' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value of car_manufacturer is'
                    },
                    value: {
                        type: ['string'],
                        // enum: ['Honda Motor Co. (Honda, Acura)','BMW Group (BMW, Mini)',...,'']
                        enum: [''],
                        description: `return the name of the car_manufacturer, return empty string '' if not known, or the car is not under any one of the list.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the slot is filled with a value. And we think it's clear and complete
need-more-data: use this state if user has not responded yet, or the response is not clear or complete`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'manufacturerNormalized',
        prompt: ``,
        dataType: 'string',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'You are a meticulous admin for a lemon law firm dealing with car cases. You have good knowledge about car models and manufacturers. You will be given a list of data about a customer regarding a lemon law case. You need to review the data and normalize them into a standard format so that we can understand if the customer will be qualified by lemon law or not.',
            schemaName: 'manufacturerNormalized',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'manufacturerNormalized' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value'
                    },
                    value: {
                        type: 'string',
                        enum: ['toyota', 'honda', 'etc', 'none'],
                        description: `check the value of 'car_manufacturer', 'car_model', and the conversation, and you should infer the manufacturers. Return 'none' if not known, or the car is not under any one of the list. Use your knowledge about car manufacturers, make, and models. E.g., Atlas is under volkswagen, ariya is under nissan, General Motors is gm, etc. For example, If a car is not in the value list, e.g., 'Jaguar Land Rover', then set the value to 'none', and state to complete`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the value is completed and clear
need-more-data: no info is available for inferring the manufacturer`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['car_manufacturer']
    },
    {
        id: 'car_model',
        description: 'what is the make and model of your vehicle?',
        prompt: 'the model of the vehicle',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'caryear',
        description: 'what is the year of the model',
        prompt: 'the year of model of the vehicle, try to return yyyy only, e.g., 2019, 2022, etc.',
        dataType: 'string',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'car_year_normalized',
        prompt: ``,
        dataType: 'string',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'You are a meticulous admin for a lemon law firm dealing with car cases. Check the value of "caryear" and the conversation, tell the year of the car. If the car year is a range, e.g., 2020-2022, then return the latest year of the range. If the car year is before 2018, then return "Before 2018". If not known or not sure, return null',
            schemaName: 'car_year_normalized',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'car_year_normalized' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value'
                    },
                    value: {
                        type: ['string', 'null'],
                        enum: [
                            'Before 2018',
                            '2018',
                            '2019',
                            '2020',
                            '2021',
                            '2022',
                            '2023',
                            '2024',
                            '2025',
                            '2026',
                            '2027',
                            '2028',
                            null
                        ],
                        description: `check the value of 'caryear' and the conversation, tell the year of the car. If not known or not sure, return null`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the value is completed and clear
need-more-data: no info is available for inferring the car age`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['caryear']
    },
    {
        id: 'current_odometer',
        description: 'what is the vehicle current mileage?',
        prompt: `what is the vehicle current mileage? (numeral number, 100K -> 100000. And rounded to the whole number, e.g., 12000.2 -> 12000). For example, if the number user says is below 1000, e.g., 115, then user should mean 115 thousand miles, hence 115000. If user provides a rough number but not sure, you need to estimate the number and set the state of this slot as complete since an approximate number is good enough.`,
        dataType: 'number',
        object: 'contact',
        integration: 'hubspot',
        isSystem: false
    },
    {
        id: 'car_new_or_certified_pre_owned',
        description: 'is the vehicle new or certified pre-owned?',
        prompt: '',
        dataType: 'string',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `You are a meticulous admin for a lemon law firm dealing with car cases. You need to review customer conversation history of a potential lemon law case. You should infer if the vehicle is bought by new, or certified pre-owned (Simply look at the car year won't be enough). This slot is complete if the coversation covers the question about the vehicle is new or certified pre-owned, and user replied. This slot is complete if user is unsure about car status when purchased.`,
            schemaName: 'car_new_or_certified_pre_owned',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'car_new_or_certified_pre_owned' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value'
                    },
                    value: {
                        type: ['string', 'null'],
                        enum: ['new', 'certified_pre_owned', 'neither', null],
                        description: `return 'new' if user confirmed the vehicle was new when purchased\nreturn 'certified_pre_owned' if user confirmed the vehicle is certified pre-owned.\nreturn null\n- not asked yet, or user is not sure, or other car types than new or certified pre-owned`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'n/a'],
                        description: `complete: the question has been asked and user responded, even if user is unsure about it\nneed-more-data: the conversation did not cover the question yet`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['current_odometer']
    },
    {
        id: 'repair_done_under_warranty_mileage',
        description: 'are the repairs done under warranty?',
        prompt: '',
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'you are a meticulous admin for a lemon law firm dealing with car cases. You need to review customer conversation history of a potential lemon law case. You should infer if the repairs are done under warranty or not. This slot is complete if the coversation covers the question about the repairs are done under warranty, and user replied. This slot is complete if user is unsure about the repairs are done under warranty.',
            schemaName: 'repair_done_under_warranty_mileage',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'repair_done_under_warranty_mileage' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `return true if user confirmed the repairs are done under warranty mileage. 
return false if user confirmed the repairs are not done under warranty mileage
return null if:
- the conversation did not cover the question yet
- user is not sure about the repair is done under warranty mileage.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the question has been asked and user responded, even if user is unsure about it
need-more-data: the conversation did not cover the question yet`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['current_odometer']
    },
    //     {
    //         id: 'future_case_repair_plan_time',
    //         description: 'do you have a plan to get the repairs? if yes, when?',
    //         prompt: `You are a meticulous admin for a lemon law firm dealing with car cases. You need to review customer conversation history of a potential lemon law case. If the rep never mentioned that the user has a future case, then this field is not applicable: set the value to null and state to n/a. If the user has a future case, and the user has not mentioned any plan to get the repairs, then set the value to null and state to complete. If the user has a future case, and the user has mentioned a plan to get the repairs, set the time to the estimated time when the customer plans to get the repairs.`,
    //         dataType: 'date',
    //         object: null,
    //         integration: null,
    //         isSystem: false,
    //         slotFillingStructured: {
    //             prompt: `You are a meticulous admin for a lemon law firm dealing with car cases. You need to review customer conversation history of a potential lemon law case. If the rep never mentioned that the user has a future case, then this field is not applicable: set the value to null and state to n/a. If the user has a future case, and the user has not mentioned any plan to get the repairs, then set the value to null and state to complete. If the user has a future case, and the user has mentioned a plan to get the repairs, set the time to the estimated time when the customer plans to get the repairs.`,
    //             schemaName: 'future_case_repair_plan_time',
    //             schema: {
    //                 type: 'object',
    //                 properties: {
    //                     slot_id: { type: 'string', const: 'future_case_repair_plan_time' },
    //                     reason: {
    //                         type: ['string', 'null'],
    //                         description: 'the reason why you think of the value and state',
    //                     },
    //                     value: {
    //                         type: ['string', 'null'],
    //                         description: `the estimated date when the customer plans to get the repair, use format YYYY-MM-DD`,
    //                     },
    //                     state: {
    //                         type: 'string',
    //                         enum: ['complete', 'need-more-data', 'n/a'],
    //                         description: `complete: the value is completed and clear
    // need-more-data: the user has a future case, and the user has mentioned a plan to get the repairs, but has not provided the estimated time to infer the value
    // n/a: the customer does not have a future case`,
    //                     },
    //                     msg_for_future: {
    //                         type: ['string', 'null'],
    //                         description: 'if you think this slot needs to be followed up in future',
    //                     },
    //                 },

    //                 required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
    //                 additionalProperties: false,
    //             },
    //         },
    //     },
    // {
    //     id: 'dealer_name',
    //     description: 'what is the dealer name',
    //     prompt: "what is the dealer's name",
    //     dataType: 'string',
    //     object: null,
    //     integration: null,
    //     isSystem: false,
    // },
    // {
    //     id: 'dealer_city',
    //     description: "what is the dealer's city",
    //     prompt: "what is the dealer's city",
    //     dataType: 'string',
    //     object: null,
    //     integration: null,
    //     isSystem: false,
    // },
    // {
    //     id: 'state',
    //     description: "what is the dealer's state/region",
    //     prompt: "what is the dealer's state/region",
    //     dataType: 'string',
    //     object: 'contact',
    //     integration: 'hubspot',
    //     isSystem: false,
    // },
    // {
    //     id: 'document_availability',
    //     description: 'Do you have repair records and purchase agreement that you could send us later?',
    //     prompt: `does the customer have the repair records and purchase agreement that they could send later. If there is no repair of the vehicle, then this field is not applicable: set the value to null and state to 'n/a'. If there is at least one repair, wait for the customer to confirm if they have the repair records and purchase agreement. Unless customer explicitly says cannot find/get documents, alway set this to yes. e.g., they say they will get the documents later, need to check with dealer, ask dealer to get it, get back to us later, set the value to yes.`,
    //     dataType: 'string',
    //     object: null,
    //     integration: null,
    //     isSystem: false,
    // },
    {
        id: 'new_repair_done',
        description: 'does the user have a new repair visit done according to the latest conversation?',
        prompt: '',
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'The field you need to fill is called new_repair_done. Since it is a field that compares the current repair info with the repair info in the past, you need to look at the latest conversation and decide whether or not the vehicle has a new repair visit.',
            schemaName: 'new_repair_done',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'new_repair_done' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value and state'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `If the vehicle has a new repair done, set the value to true. If the vehicle does not have a new repair done, set the value to false. If you cannot determine whether the vehicle has a new repair done or not, set the value to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the value is completed and clear
need-more-data: 
- user has not answered if they have new repair done
- user's answer is not clear to determine if the vehicle has a new repair done`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },
                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'new_repairs_times',
        description: 'how many times has the user had repairs done currently?',
        prompt: '',
        dataType: 'number',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'The field you need to fill is called new_repairs_times. Since it is the latest info about the repair times, you need to look at the latest conversation and decide how many times the user has had repairs done to date. This field should be the current total number of repair times the user has had. Only set the value when the user has explicitly shared the digital number of the latest repair times or confirmed the digital number of latest repair times. Only set the state to complete when the user has provided the digital number of the latest repair times or confirmed the digital number of latest repair times.',
            schemaName: 'new_repairs_times',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'new_repairs_times' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value and state'
                    },
                    value: {
                        type: ['number', 'null'],
                        description: `Return the latest digital number of repair times user confirmed or answered. Avoid inferring from past repair counts user has had. Avoid inferring from simply more repairs done than before. Only set the value when user has explicitly shared the digital number of the latest repair times or confirmed the digital number of latest repair times. Otherwise, set the value to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the user answered the question about the latest repair times and provided a digital number, or user has confirmed the latest repair times digital number
need-more-data: 
-user has not answered if they have new repair done
-user has not been asked about the latest repair times
-user has not answered about the latest repair times
-user has not confirmed the repair times number
-user has not provided a digital number
-user has not confirmed the digital number
-user has confirmed they have more repairs done than before, but has not provided the digital number or confirmed the digital number`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },
                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'new_current_odometer',
        description: 'what is the vehicle current mileage?',
        prompt: '',
        dataType: 'number',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: 'The field you need to fill is called new_current_odometer. Since it is the latest info about the vehicle current mileage, you need to look at the latest conversation and decide the latest number of the vehicle current mileage.',
            schemaName: 'new_current_odometer',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'new_current_odometer' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think of the value and state'
                    },
                    value: {
                        type: ['number', 'null'],
                        description: `Return the latest number of the vehicle current mileage user confirmed. If the user has not mentioned any mileage, set the value to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data'],
                        description: `complete: the user answered the question about the latest mileage
need-more-data: 
-the user has not answered if they have new repair done
-the user has not answered the question about the latest mileage`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description: 'if you think this slot needs to be followed up in future'
                    }
                },
                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        }
    },
    {
        id: 'new_days_out_of_service_more_than_25',
        description: `If the vehicle has been repaired before, and if you add up all the days the vehicle was in the shop, was it in the shop for more than 25 (include 25) days total?`,
        prompt: ``,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `The field you need to fill is called new_days_out_of_service_more_than_25, and you need to use the latest conversation to decide whether or not the total length of time (in days) the vehicle spent in the repair shop was more than 25 days (include 25). If the car is still in shop, or the customer doesn't know how long it was/will be in the shop, set the value to null and state to 'complete'. If a car has never been repaired, then set the value to false and state to 'n/a'. If user's answer is an estimation (or not very clear), you should try to get the upper bound of the estimation. Like 25 to 30 days, you should consider 30 days, hence it's more than or equal to 25. This question is applicable only if the car manufacturer is one of 
${manufacturersGroup1Detail.join('\n')}, otherwise, set the value to null and state to 'n/a'.`,
            schemaName: 'new_days_out_of_service_more_than_25',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'new_days_out_of_service_more_than_25' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is true or false or null'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `think about what is the car make first, if this question is applicable, whether or not the total length of time (in days) the vehicle spent in the repair shop was more than 25 days (include 25) according to the latest conversation. If not applicable, set to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'n/a'],
                        description: `complete: customer has shared the duration info. Or customer does not know how long, e.g., the car is still in shop, or the customer doesn't know how long it was/will be in the shop.
need-more-data: 
- user has not answered if they have new repair done
- user has not been asked about the duration
- user has not answered the question about the duration
n/a: when the question is not applicable, e.g., the car manufacturer is not in the list`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['car_manufacturer']
    },
    {
        id: 'new_days_out_of_service_more_than_20',
        description: `If you add up all the days the vehicle was in the shop, was it in the shop for more than 20 (include 20) days total?`,
        prompt: ``,
        dataType: 'boolean',
        object: null,
        integration: null,
        isSystem: false,
        slotFillingStructured: {
            prompt: `The field you need to fill is called new_days_out_of_service_more_than_20, and you need to use the latest conversation to decide whether or not the total length of time (in days) the vehicle spent in the repair shop was more than 20 days (include 20). If the car is still in shop, or the customer doesn't know how long it was/will be in the shop, set the value to null and state to 'complete'. If user's answer is an estimation (or not very clear), you should try to get the upper bound of the estimation. Like 20 to 30 days, you should consider 30 days, hence it's more than or equal to 20. This question is applicable only if the car manufacturer is one of 
${manufacturersGroup2Detail.join('\n')}, otherwise, set the value to null and state to 'n/a'.`,
            schemaName: 'new_days_out_of_service_more_than_20',
            schema: {
                type: 'object',
                properties: {
                    slot_id: { type: 'string', const: 'new_days_out_of_service_more_than_20' },
                    reason: {
                        type: ['string', 'null'],
                        description: 'the reason why you think the value is true or false or null'
                    },
                    value: {
                        type: ['boolean', 'null'],
                        description: `think about what is the car make first, if this question is applicable, whether or not the total length of time (in days) the vehicle spent in the repair shop was more than 20 days (include 20) according to the latest conversation. If not applicable, set to null.`
                    },
                    state: {
                        type: 'string',
                        enum: ['complete', 'need-more-data', 'n/a'],
                        description: `complete: customer has shared the duration info. Or customer does not know how long, e.g., the car is still in shop, or the customer doesn't know how long it was/will be in the shop.
need-more-data: 
- user has not answered if they have new repair done
- user has not been asked about the duration
- user has not answered the question about the duration
n/a: when the question is not applicable, e.g., the car manufacturer is not in the list`
                    },
                    msg_for_future: {
                        type: ['string', 'null'],
                        description:
                            'if you think this slot needs to be followed up in future communication, provide a message for the future.'
                    }
                },

                required: ['slot_id', 'reason', 'value', 'state', 'msg_for_future'],
                additionalProperties: false
            }
        },
        dependOn: ['car_manufacturer']
    }
];
