import {
    ScaleIcon,
    TruckIcon,
    UserGroupIcon,
    BuildingOfficeIcon,
    HeartIcon,
    ShieldExclamationIcon
} from '@heroicons/react/24/outline';
import { CaseCategory } from '../types/demo';

export const caseCategories: CaseCategory[] = [
    {
        id: 'personal-injury',
        title: 'Personal Injury',
        cases: [
            {
                id: 'motor-vehicle',
                title: 'Motor Vehicle Accident',
                description: 'Get help with your motor vehicle accident case',
                icon: TruckIcon,
                comingSoon: false
            },
            {
                id: 'police-brutality',
                title: 'Police Brutality',
                description: 'Legal assistance for police misconduct cases',
                icon: ShieldExclamationIcon,
                comingSoon: false
            },
            {
                id: 'slip-fall',
                title: 'Slip & Fall',
                description: 'Help with premises liability cases',
                icon: BuildingOfficeIcon,
                comingSoon: false
            },
            {
                id: 'med-malpractice',
                title: 'Medical Malpractice',
                description: 'Support for medical negligence cases',
                icon: HeartIcon,
                comingSoon: false
            }
        ]
    },
    {
        id: 'lemon-law',
        title: 'Lemon Law',
        cases: [
            {
                id: 'lemon-evaluation',
                title: 'Lemon Law Evaluation',
                description: 'Get your vehicle evaluated for lemon law qualification',
                icon: TruckIcon,
                comingSoon: false
            },
            {
                id: 'document-collection',
                title: 'Document Collection',
                description: 'Submit required documentation for your lemon law case',
                icon: ScaleIcon,
                comingSoon: false
            }
        ]
    },

    {
        id: 'mass-tort',
        title: 'Mass Tort',
        cases: [
            {
                id: 'chevy-bolt',
                title: 'Chevy Bolt',
                description: 'Join the Chevrolet Bolt lawsuit',
                icon: UserGroupIcon,
                comingSoon: false
            }
        ]
    }
];
