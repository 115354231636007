import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { Case } from '../../types/demo';

export interface CaseGridProps {
    cases: Case[];
    onCaseSelect: (caseId: string) => void;
}

export default function CaseGrid({ cases, onCaseSelect }: CaseGridProps) {
    return (
        <Grid container spacing={2} className="mt-4">
            {cases.map(caseItem => {
                const Icon = caseItem.icon;
                return (
                    <Grid item xs={12} sm={6} md={4} key={caseItem.id}>
                        <Paper
                            className={`relative cursor-pointer p-6 transition-all hover:shadow-lg ${
                                caseItem.comingSoon ? 'opacity-60' : ''
                            }`}
                            sx={{
                                backgroundColor: 'background.paper',
                                border: '1px solid rgba(255,255,255,0.12)',
                                borderRadius: '8px',
                                '&:hover': {
                                    backgroundColor: caseItem.comingSoon
                                        ? 'background.paper'
                                        : 'rgba(138, 180, 248, 0.08)',
                                    borderColor: caseItem.comingSoon
                                        ? 'rgba(255,255,255,0.12)'
                                        : 'rgba(138, 180, 248, 0.5)',
                                    '& .coming-soon-badge': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                                        transform: 'scale(1.05)',
                                        transition: 'all 0.2s ease-in-out'
                                    },
                                    '& .coming-soon-text': {
                                        color: 'rgba(255, 255, 255, 0.9)'
                                    }
                                }
                            }}
                            onClick={() => !caseItem.comingSoon && onCaseSelect(caseItem.id)}
                        >
                            {/* Coming Soon Badge */}
                            {caseItem.comingSoon && (
                                <Box
                                    className="coming-soon-badge"
                                    sx={{
                                        position: 'absolute',
                                        right: 16,
                                        top: 16,
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderRadius: '16px',
                                        padding: '4px 12px',
                                        zIndex: 1,
                                        transition: 'all 0.2s ease-in-out'
                                    }}
                                >
                                    <Typography
                                        className="coming-soon-text"
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            fontSize: '0.75rem',
                                            fontWeight: 500,
                                            transition: 'all 0.2s ease-in-out'
                                        }}
                                    >
                                        Coming soon
                                    </Typography>
                                </Box>
                            )}

                            {/* Updated icon styling */}
                            {Icon && (
                                <div className="mb-4 flex justify-center">
                                    <Icon className="h-8 w-8 text-[#8AB4F8]" />
                                </div>
                            )}

                            {/* Updated typography */}
                            <Typography
                                variant="h6"
                                className="mb-2 text-center"
                                sx={{
                                    color: 'rgba(255,255,255,0.87)',
                                    fontSize: '1rem',
                                    fontWeight: 500
                                }}
                            >
                                {caseItem.title}
                            </Typography>
                            <Typography
                                className="text-center"
                                sx={{
                                    color: 'rgba(255,255,255,0.6)',
                                    fontSize: '0.875rem'
                                }}
                            >
                                {caseItem.description}
                            </Typography>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
}
