import React, { useEffect, useState } from 'react';
import ContactDetail from './ContactDetail/ContactInfo';
import { Link, useParams } from 'react-router-dom';
import getContactDetail, { TypeContactDetail } from 'api/get-contact-detail';
import SpinLoader from 'components/ui/spinLoader';
import AiEnabledButton from './Contacts/AiEnabledButton';
import Hubspot from 'components/logos/hubspot';
import resetContact from 'api/resetContact';
import config from 'utils/config';
import NextSequenceScheduledAtSelect from './Contacts/nextSequenceScheduledAtSelect';
import { millionSecondsToDhmsString } from 'lib/seconds-to-days-hours-mins';
import { Tooltip } from '@mui/material';

function Contact() {
    const { contactId } = useParams();

    const [contactInfo, setContactInfo] = useState<TypeContactDetail | null>(null);
    const [isloading, setIsLoading] = useState(false);

    let nextSequenceTimeFromNow = '';
    if (contactInfo) {
        if (contactInfo.generalInfo.nextSequenceScheduledAt) {
            nextSequenceTimeFromNow = millionSecondsToDhmsString(
                contactInfo.generalInfo.nextSequenceScheduledAt - Date.now()
            );
            if (nextSequenceTimeFromNow === '') {
                nextSequenceTimeFromNow = 'now';
            }
        }
    }

    useEffect(() => {
        const fetchContactDetail = async () => {
            if (!contactId) {
                return;
            }
            setIsLoading(true);
            const contactInfo = await getContactDetail({
                contactId: parseInt(contactId)
            });
            setContactInfo(contactInfo);
            setIsLoading(false);
        };
        fetchContactDetail();
    }, []);

    const handleReset = async () => {
        if (!contactInfo) return;
        await resetContact({ contactId: contactInfo.generalInfo.id });
        window.location.reload();
    };

    if (isloading || contactInfo === null) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;

    if (!contactId) {
        return <div>No contact ID Found</div>;
    }

    return (
        <div className="flex h-[calc(100vh-0rem)] flex-col overflow-hidden">
            <div className="my-4 flex flex-col space-y-2 px-2 sm:px-4 lg:px-6">
                <div className="flex flex-wrap items-center gap-3 border border-gray-200 bg-white px-3 py-2 shadow-sm">
                    <div className="flex items-center gap-2">
                        <Link
                            to={`${contactInfo.generalInfo.hubspotUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-2 text-[14px] font-medium text-[#1a73e8] hover:text-[#174ea6]"
                        >
                            <Hubspot className="h-4 w-4" />
                            <span>{contactInfo.generalInfo.hubspotContactId}</span>
                        </Link>
                        <span className="text-[14px] font-normal text-gray-600">({contactInfo.generalInfo.id})</span>
                    </div>

                    <div className="flex items-center divide-x divide-control-border">
                        <div className="flex items-center gap-4">
                            <div className="flex items-center gap-2">
                                <span className="text-[14px] font-medium text-gray-700">AI follow ups:</span>
                                <AiEnabledButton
                                    data={{
                                        id: contactInfo.generalInfo.id,
                                        aiEnabled: contactInfo.generalInfo.aiEnabled,
                                        aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall
                                    }}
                                    fieldName="aiEnabled"
                                />
                                {!contactInfo.generalInfo.aiEnabled && (
                                    <Tooltip title={contactInfo.generalInfo.aiDisabledReason} placement="top">
                                        <span className="max-w-[200px] truncate text-[14px] font-normal text-[#1a73e8]">
                                            ({contactInfo.generalInfo.aiDisabledReason})
                                        </span>
                                    </Tooltip>
                                )}
                            </div>

                            <div className="flex items-center gap-2">
                                <span className="text-[14px] font-medium text-gray-700">AI responses:</span>
                                <AiEnabledButton
                                    data={{
                                        id: contactInfo.generalInfo.id,
                                        aiEnabled: contactInfo.generalInfo.aiEnabled,
                                        aiEnabledReplySmsCall: contactInfo.generalInfo.aiEnabledReplySmsCall
                                    }}
                                    fieldName="aiEnabledReplySmsCall"
                                />
                                {!contactInfo.generalInfo.aiEnabledReplySmsCall && (
                                    <Tooltip
                                        title={contactInfo.generalInfo.aiDisabledReplySmsCallReason}
                                        placement="top"
                                    >
                                        <span className="max-w-[200px] truncate text-[14px] font-normal text-[#1a73e8]">
                                            ({contactInfo.generalInfo.aiDisabledReplySmsCallReason})
                                        </span>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        <div className="flex items-center gap-2 border border-gray-200 bg-white px-3 py-1.5 shadow-sm">
                            <span className="text-[14px] font-medium text-gray-600">Sequence:</span>
                            <span className="text-[14px] font-medium text-[#1a73e8]">
                                {contactInfo.generalInfo.sequenceName || 'None'}
                            </span>
                        </div>

                        <div className="flex flex-col border border-gray-200 bg-white px-3 py-1.5 shadow-sm">
                            <div className="flex items-center gap-2">
                                <span className="text-[14px] font-medium text-gray-600">Next sequence:</span>
                                <span className="text-[14px] font-medium text-[#1a73e8]">
                                    {contactInfo.generalInfo.nextSequenceName || 'None'}
                                </span>
                                <span className="text-[14px] font-medium text-gray-600">at (UTC):</span>
                                <NextSequenceScheduledAtSelect
                                    data={{
                                        id: contactInfo.generalInfo.id,
                                        nextSequenceScheduledAt: contactInfo.generalInfo.nextSequenceScheduledAt
                                    }}
                                />
                            </div>
                            {contactInfo.generalInfo.nextSequenceScheduledAt && (
                                <span className="text-[14px] font-medium text-[#1a73e8]">
                                    {nextSequenceTimeFromNow === 'now' ? 'Now' : `in ${nextSequenceTimeFromNow}`}
                                </span>
                            )}
                        </div>
                    </div>

                    {config.ENV !== 'prod' && (
                        <button
                            onClick={handleReset}
                            className="ml-auto h-7 rounded-md border border-red-600 bg-red-500 px-2.5 text-[13px] font-medium text-white hover:bg-red-600"
                        >
                            Reset Contact
                        </button>
                    )}
                </div>
            </div>

            <div className="flex-1 overflow-auto px-2 sm:px-4 lg:px-6">
                <div className="h-full w-full">
                    <ContactDetail contactId={contactId} contactInfo={contactInfo} />
                </div>
            </div>
        </div>
    );
}

export default Contact;
