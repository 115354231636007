import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getSequences, { SequenceArray } from 'api/getSequences';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { QueueListIcon } from '@heroicons/react/24/outline';

const Sequences: React.FC = () => {
    const [sequences, setSequences] = useState<SequenceArray[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSequences = async () => {
            try {
                const data = await getSequences();
                setSequences(data);
            } catch (error) {
                console.error('Failed to fetch sequences:', error);
            }
        };

        fetchSequences();
    }, []);

    const handleSequenceClick = (sequenceId: string) => {
        navigate(`/sequences/${sequenceId}`);
    };

    return (
        <div className="flex h-full w-full flex-col">
            <div className="mb-6">
                <div className="h-[4rem] border-b border-gray-200">
                    <div className="flex h-full items-center px-4 sm:px-6 lg:px-8">
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                                <QueueListIcon className="mr-2 h-5 w-5 text-blue-600" />
                                <div>
                                    <h1 className="text-lg font-medium text-gray-900">Sequences</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <Grid container spacing={3}>
                        {sequences.map(sequence => (
                            <Grid item xs={12} sm={6} md={4} key={sequence.id}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        borderRadius: 0,
                                        boxShadow: 'none',
                                        transition: 'all 0.2s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: '#f8f9fa',
                                            cursor: 'pointer'
                                        }
                                    }}
                                    onClick={() => handleSequenceClick(sequence.id)}
                                    className="border border-gray-200"
                                >
                                    <CardContent className="p-5">
                                        <Typography variant="h6" className="mb-2 font-medium text-gray-900">
                                            {sequence.name}
                                        </Typography>
                                        <Typography variant="body2" className="text-gray-600">
                                            {sequence.label}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default Sequences;
