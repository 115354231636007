import httpClient from '../http-client';
import { DocumentNormal } from './get-documents';

export interface NewDocument {
    name: string;
    description: string | null;
}

const createDocument = async (payload: NewDocument): Promise<DocumentNormal> => {
    const { data } = await httpClient.post<DocumentNormal>('/api/create-document', payload);
    return data;
};

export default createDocument;
