import React, { useState } from 'react';
import {
    ChartBarIcon,
    Cog6ToothIcon,
    QueueListIcon,
    CommandLineIcon,
    DocumentTextIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    AdjustmentsHorizontalIcon,
    ArrowRightEndOnRectangleIcon
} from '@heroicons/react/24/outline';
import SuperpanelIcon from './icons/SuperpanelIcon';
import { cn } from 'lib/utils';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import useUserStore from 'stores/user';
import logout from 'api/logout';

const navigationItems = [
    {
        id: 'ai-workspace',
        name: 'Agent Workspace',
        icon: CommandLineIcon,
        subitems: [
            {
                name: 'Sequences',
                href: '/contacts',
                icon: QueueListIcon
            },
            {
                name: 'Analytics',
                href: '/analytics',
                icon: ChartBarIcon
            }
        ]
    },
    {
        id: 'ai-setup',
        name: 'Agent Setup',
        icon: AdjustmentsHorizontalIcon,
        subitems: [
            {
                name: 'Sequences',
                href: '/sequences',
                icon: QueueListIcon
            },
            {
                name: 'Documents',
                href: '/documents',
                icon: DocumentTextIcon
            },
            {
                name: 'Variables',
                href: '/variables',
                icon: DocumentTextIcon
            }
        ]
    }
];

const settingsNavigation = {
    name: 'Settings',
    href: '/settings',
    icon: Cog6ToothIcon
};

const isPathActive = (pathname: string, href: string) => {
    // Special case for contacts route
    if (href === '/contacts' && pathname === '/') {
        return true;
    }

    // Remove trailing slash for consistent comparison
    const cleanPath = pathname.replace(/\/$/, '');
    const cleanHref = href.replace(/\/$/, '');

    // Check if the path starts with href
    return cleanPath.startsWith(cleanHref);
};

function Sidebar(props: { children: React.ReactNode }) {
    const location = useLocation();
    const [expandedItems, setExpandedItems] = useState<string[]>(['ai-workspace']);
    const navigate = useNavigate();
    const userStore = useUserStore();

    const toggleExpand = (itemId: string) => {
        setExpandedItems(prev => (prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]));
    };

    const handleLogout = async () => {
        try {
            await logout();
            userStore.setState(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const NavigationItem = ({ item }: { item: (typeof navigationItems)[0] }) => (
        <li>
            <button
                onClick={() => toggleExpand(item.id)}
                className={cn(
                    'flex w-full items-center justify-between px-6 py-3 text-sm transition-colors',
                    'text-gray-700 hover:bg-gray-200',
                    expandedItems.includes(item.id) && 'bg-gray-50 font-medium text-gray-900'
                )}
            >
                <div className="flex items-center gap-3">
                    <item.icon className="h-5 w-5" />
                    <span className="font-medium">{item.name}</span>
                </div>
                {expandedItems.includes(item.id) ? (
                    <ChevronUpIcon className="h-4 w-4" />
                ) : (
                    <ChevronDownIcon className="h-4 w-4" />
                )}
            </button>
            {expandedItems.includes(item.id) && (
                <ul>
                    {item.subitems.map(subitem => (
                        <li key={subitem.name}>
                            <Link
                                to={subitem.href}
                                className={cn(
                                    'flex w-full items-center py-3 text-sm transition-colors',
                                    'pl-9 pr-6',
                                    isPathActive(location.pathname, subitem.href)
                                        ? 'bg-blue-50 font-medium text-blue-700'
                                        : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900'
                                )}
                            >
                                <subitem.icon className="h-4 w-4" />
                                <span className="ml-3">{subitem.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );

    return (
        <div className="h-full">
            <div className="fixed inset-y-0 left-0 z-50 flex w-64 flex-col overflow-y-auto border-r border-gray-200 bg-white pb-4">
                <div className="flex h-16 shrink-0 items-center border-b border-gray-200 px-6">
                    <div className="flex items-center gap-3">
                        <SuperpanelIcon className="h-6 w-auto text-blue-600" />
                        <span className="text-lg font-medium text-gray-900">Superpanel</span>
                    </div>
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul>
                        {navigationItems.map(item => (
                            <NavigationItem key={item.id} item={item} />
                        ))}
                    </ul>

                    <div className="mt-auto space-y-1">
                        <Link
                            to={settingsNavigation.href}
                            className={cn(
                                'flex items-center gap-3 px-6 py-3 text-sm transition-colors',
                                isPathActive(location.pathname, settingsNavigation.href)
                                    ? 'bg-blue-50 font-medium text-blue-700'
                                    : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900'
                            )}
                        >
                            <settingsNavigation.icon className="h-5 w-5" />
                            <span className="">{settingsNavigation.name}</span>
                        </Link>

                        <button
                            onClick={handleLogout}
                            className="flex w-full items-center gap-3 px-6 py-3 text-sm text-gray-700 transition-colors hover:bg-gray-200 hover:text-gray-900"
                        >
                            <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                            <span>Logout</span>
                        </button>
                    </div>
                </nav>
            </div>
            <main className="h-full pl-64">
                <div className="h-full bg-white">{props.children}</div>
            </main>
        </div>
    );
}

export default Sidebar;
