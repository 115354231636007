import React, { useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, Modal, IconButton } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { DataObject } from '@mui/icons-material';
import { sharedCollections } from 'data/variables';

const Variables: React.FC = () => {
    const [selectedVariable, setSelectedVariable] = useState<(typeof sharedCollections)[0] | null>(null);

    const handleVariableClick = (variable: (typeof sharedCollections)[0]) => {
        setSelectedVariable(variable);
    };

    const handleCloseModal = () => {
        setSelectedVariable(null);
    };

    return (
        <div className="flex h-full w-full flex-col">
            <div className="mb-6">
                <div className="sticky top-0 z-10 h-[4rem] border-b border-gray-200 bg-white">
                    <div className="flex h-full items-center px-4 sm:px-6 lg:px-8">
                        <div className="flex w-full items-center justify-between">
                            <div className="flex items-center">
                                <DataObject className="mr-2 h-5 w-5 text-blue-600" />
                                <div>
                                    <h1 className="text-lg font-medium text-gray-900">Variables</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Variable Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Description
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Type
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {sharedCollections.map(variable => (
                                    <tr
                                        key={variable.id}
                                        onClick={() => handleVariableClick(variable)}
                                        className="cursor-pointer hover:bg-gray-50"
                                    >
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="font-medium text-gray-900">
                                                {variable.id.replace(/_/g, ' ')}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-600">
                                                {variable.description || variable.prompt}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-6 py-4">
                                            <div className="text-sm text-gray-500">{variable.dataType}</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal open={!!selectedVariable} onClose={handleCloseModal} aria-labelledby="variable-modal-title">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        overflow: 'hidden',
                        borderRadius: 1
                    }}
                >
                    <div className="relative h-full">
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                zIndex: 1
                            }}
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </IconButton>

                        {selectedVariable && (
                            <div className="max-h-[90vh] overflow-y-auto p-8">
                                <Typography variant="h5" className="mb-6 text-xl font-bold text-gray-900">
                                    {selectedVariable.id.replace(/_/g, ' ')}
                                </Typography>

                                <div className="space-y-6">
                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Description
                                        </Typography>
                                        <Typography className="text-gray-800">
                                            {selectedVariable.description || selectedVariable.prompt}
                                        </Typography>
                                    </div>

                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                        <div className="rounded-lg bg-gray-50 p-4">
                                            <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                                Data Type
                                            </Typography>
                                            <Typography className="text-gray-800">
                                                {selectedVariable.dataType}
                                            </Typography>
                                        </div>

                                        <div className="rounded-lg bg-gray-50 p-4">
                                            <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                                System Variable
                                            </Typography>
                                            <Typography className="text-gray-800">
                                                {selectedVariable.isSystem ? 'Yes' : 'No'}
                                            </Typography>
                                        </div>
                                    </div>

                                    {selectedVariable.object && (
                                        <div className="rounded-lg bg-gray-50 p-4">
                                            <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                                Object
                                            </Typography>
                                            <Typography className="text-gray-800">{selectedVariable.object}</Typography>
                                        </div>
                                    )}

                                    {selectedVariable.integration && (
                                        <div className="rounded-lg bg-gray-50 p-4">
                                            <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                                Integration
                                            </Typography>
                                            <Typography className="text-gray-800">
                                                {selectedVariable.integration}
                                            </Typography>
                                        </div>
                                    )}

                                    {selectedVariable.dependOn && (
                                        <div className="rounded-lg bg-gray-50 p-4">
                                            <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                                Dependencies
                                            </Typography>
                                            <Typography className="text-gray-800">
                                                {selectedVariable.dependOn.join(', ')}
                                            </Typography>
                                        </div>
                                    )}

                                    {selectedVariable.slotFillingStructured && (
                                        <>
                                            <div className="rounded-lg bg-gray-50 p-4">
                                                <Typography
                                                    variant="subtitle1"
                                                    className="mb-2 font-medium text-gray-700"
                                                >
                                                    Slot Filling Structure
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    className="whitespace-pre-wrap text-gray-800"
                                                >
                                                    {selectedVariable.slotFillingStructured.prompt}
                                                </Typography>
                                            </div>

                                            {selectedVariable.slotFillingStructured.schema && (
                                                <div className="rounded-lg bg-gray-50 p-4">
                                                    <Typography
                                                        variant="subtitle1"
                                                        className="mb-2 font-medium text-gray-700"
                                                    >
                                                        Schema
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        className="whitespace-pre-wrap rounded bg-white p-3 font-mono text-sm text-gray-800"
                                                    >
                                                        {JSON.stringify(
                                                            selectedVariable.slotFillingStructured.schema,
                                                            null,
                                                            2
                                                        )}
                                                    </Typography>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default Variables;
