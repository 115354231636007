import React from 'react';

interface Props {
    className?: string;
}
function AiIcon({ className }: Props) {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.9 3.9L11.3392 7.95798C11.0854 8.61788 10.9585 8.94784 10.7612 9.22538C10.5863 9.47136 10.3714 9.68627 10.1254 9.86118C9.84784 10.0585 9.51788 10.1854 8.85797 10.4392L4.8 12L8.85798 13.5608C9.51788 13.8146 9.84784 13.9415 10.1254 14.1388C10.3714 14.3137 10.5863 14.5286 10.7612 14.7746C10.9585 15.0522 11.0854 15.3821 11.3392 16.042L12.9 20.1L14.4608 16.042C14.7146 15.3821 14.8415 15.0522 15.0388 14.7746C15.2137 14.5286 15.4286 14.3137 15.6746 14.1388C15.9522 13.9415 16.2821 13.8146 16.942 13.5608L21 12L16.942 10.4392C16.2821 10.1854 15.9522 10.0585 15.6746 9.86118C15.4286 9.68627 15.2137 9.47136 15.0388 9.22538C14.8415 8.94784 14.7146 8.61788 14.4608 7.95797L12.9 3.9Z"
                stroke="url(#gradient)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient id="gradient" x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00A3FF" />
                    <stop offset="1" stopColor="#0066FF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

AiIcon.defaultProps = {
    className: undefined
};
export default AiIcon;
