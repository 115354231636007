import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SpinLoader from 'components/ui/spinLoader';
import getDocument, { DocumentWithFAQs } from 'api/document/get-document';
import FaqItem from './FaqItem';
import FaqFormDialog from './FaqFormDialog';
import { Button, IconButton } from '@mui/material';
import { ArrowLeftIcon, PlusIcon, PencilIcon } from '@heroicons/react/24/outline';
import createDocumentItem from 'api/document/create-document-item';
import updateDocumentItem from 'api/document/update-document-item';
import deleteDocumentItem from 'api/document/delete-document-item';
import { NewDocument } from 'api/document/create-document';
import DocumentDialog from './DocumentDialogComp';
import updateDocument from 'api/document/update-document';
import usePopupMessageStore from 'stores/popup-message';

function DocumentDetailPage() {
    const { documentId } = useParams();
    const navigate = useNavigate();
    const [document, setDocument] = useState<DocumentWithFAQs | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddFaqOpen, setIsAddFaqOpen] = useState(false);
    const [editingFaq, setEditingFaq] = useState<{ id: string; query: string[]; content: string } | null>(null);
    const [isEditDocumentOpen, setIsEditDocumentOpen] = useState(false);
    const { success, error } = usePopupMessageStore();

    useEffect(() => {
        const fetchDocument = async () => {
            if (!documentId) return;
            try {
                const response = await getDocument(documentId);
                setDocument(response);
            } catch (error) {
                console.error('Error fetching document:', error);
                navigate('/documents');
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocument();
    }, [documentId, navigate]);

    if (isLoading) return <SpinLoader className="mr-2 h-4 w-4 animate-spin" />;
    if (!document) return null;

    const refreshDocument = async () => {
        if (!documentId) return;
        try {
            const response = await getDocument(documentId);
            setDocument(response);
        } catch (error) {
            console.error('Error refreshing document:', error);
        }
    };

    const handleAddFaq = async (faq: { query: string[]; content: string }) => {
        if (!documentId) return;
        try {
            await createDocumentItem({
                documentId,
                ...faq
            });
            await refreshDocument();
            success('FAQ added successfully');
        } catch (err) {
            error('Failed to add FAQ');
            console.error('Error adding FAQ:', err);
        }
    };

    const handleEditFaq = async (faq: { query: string[]; content: string }) => {
        if (!editingFaq?.id) return;
        try {
            await updateDocumentItem({
                id: editingFaq.id,
                ...faq
            });
            await refreshDocument();
            setEditingFaq(null);
            success('FAQ updated successfully');
        } catch (err) {
            error('Failed to update FAQ');
            console.error('Error updating FAQ:', err);
        }
    };

    const handleDeleteFaq = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this FAQ?')) {
            try {
                await deleteDocumentItem({ id });
                await refreshDocument();
                success('FAQ deleted successfully');
            } catch (err) {
                error('Failed to delete FAQ');
                console.error('Error deleting FAQ:', err);
            }
        }
    };

    const handleUpdateDocument = async (updatedDoc: NewDocument) => {
        if (!documentId) {
            throw new Error('Document ID is required');
        }
        try {
            const response = await updateDocument({
                id: documentId,
                ...updatedDoc
            });
            success('Document updated successfully');
            return response;
        } catch (err) {
            error('Failed to update document');
            console.error('Error updating document:', err);
            throw err;
        }
    };

    return (
        <div className="flex h-full w-full flex-col bg-white">
            <div className="mb-4">
                <div className="h-16 border-b border-gray-200 bg-white">
                    <div className="flex h-full items-center px-4 sm:px-6">
                        <button
                            onClick={() => navigate('/documents')}
                            className="inline-flex items-center gap-1.5 text-sm font-medium text-gray-600 transition-colors hover:text-blue-600"
                        >
                            <ArrowLeftIcon className="h-4 w-4" />
                            <span>Back to Documents</span>
                        </button>
                    </div>
                </div>

                <div className="mx-4 mt-4 border border-gray-200 bg-white sm:mx-6">
                    <div className="border-b border-gray-200 p-4 sm:p-5">
                        <div className="flex items-center justify-between">
                            <div className="flex-1">
                                <div className="flex items-center gap-2">
                                    <h1 className="font-inter text-xl font-semibold text-gray-900">
                                        {document.document.name}
                                    </h1>
                                    <IconButton
                                        onClick={() => setIsEditDocumentOpen(true)}
                                        size="small"
                                        className="text-gray-400 hover:text-gray-600"
                                    >
                                        <PencilIcon className="h-4 w-4" />
                                    </IconButton>
                                </div>
                                {document.document.description && (
                                    <p className="mt-1 text-sm leading-relaxed text-gray-500">
                                        {document.document.description}
                                    </p>
                                )}
                            </div>
                            <button
                                onClick={() => setIsAddFaqOpen(true)}
                                className="inline-flex items-center gap-1.5 rounded-md bg-blue-600 px-3 py-1.5 text-xs font-medium text-white shadow-sm transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                            >
                                <PlusIcon className="h-3.5 w-3.5" />
                                Add FAQ
                            </button>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-200">
                        {document.document.content.length === 0 ? (
                            <p className="py-8 text-center text-sm text-gray-500">
                                No FAQs available for this document.
                            </p>
                        ) : (
                            document.document.content.map(faq => (
                                <FaqItem
                                    key={faq.id}
                                    query={faq.query}
                                    content={faq.content}
                                    onEdit={() => setEditingFaq(faq)}
                                    onDelete={() => handleDeleteFaq(faq.id)}
                                />
                            ))
                        )}
                    </div>
                </div>
            </div>

            <FaqFormDialog
                isOpen={isAddFaqOpen}
                onClose={() => setIsAddFaqOpen(false)}
                onSave={handleAddFaq}
                title="Add FAQ"
                onSuccess={() => success('saved!')}
            />

            <FaqFormDialog
                isOpen={!!editingFaq}
                onClose={() => setEditingFaq(null)}
                onSave={handleEditFaq}
                initialFaq={editingFaq || undefined}
                title="Edit FAQ"
                onSuccess={() => success('saved!')}
            />

            <DocumentDialog
                isOpen={isEditDocumentOpen}
                onClose={() => setIsEditDocumentOpen(false)}
                onSave={handleUpdateDocument}
                initialDocument={document.document}
                mode="edit"
                onUpdate={refreshDocument}
            />
        </div>
    );
}

export default DocumentDetailPage;
