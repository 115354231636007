import httpClient from './http-client';
import { UserSubmission } from '../types/demo';

interface DemoSubmissionResponse {
    success: boolean;
    message: string;
}

export const submitDemoRequest = async (data: UserSubmission): Promise<DemoSubmissionResponse> => {
    const res = await httpClient.post<DemoSubmissionResponse>('/api/create-demo-call', data);
    const { data: response } = res;

    return response;
};
