import React, { useState } from 'react';
import createCallTask from 'api/create-call-task';
import SpinLoader from 'components/ui/spinLoader';
import { pause } from 'lib/pause';

interface CreateAICallButtonProps {
    contactId: string;
    onCall: () => void;
}

const CreateAICallButton: React.FC<CreateAICallButtonProps> = ({ contactId, onCall }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCreateCall = async () => {
        setIsSubmitting(true);
        await pause(2000);
        await createCallTask({ contactId: parseInt(contactId) });
        setIsSubmitting(false);
        onCall();
    };

    return (
        <button
            className="inline-flex h-6 items-center justify-center rounded border border-blue-600 bg-blue-500 px-2 text-xs font-medium text-white hover:bg-blue-600 disabled:border-gray-300 disabled:bg-gray-300"
            type="button"
            onClick={handleCreateCall}
            disabled={isSubmitting}
        >
            <div className="flex items-center gap-1.5">
                {isSubmitting && <SpinLoader className="h-3 w-3 animate-spin" />}
                <span>Make AI call</span>
            </div>
        </button>
    );
};

export default CreateAICallButton;
