import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Condition } from 'data/conditions';

interface ConditionDetailDialogProps {
    isOpen: boolean;
    onClose: () => void;
    condition: Condition | null;
}

const ConditionDetailDialog: React.FC<ConditionDetailDialogProps> = ({ isOpen, onClose, condition }) => {
    if (!condition) return null;

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <div className="flex items-center justify-between border-b border-gray-200 p-4">
                <DialogTitle className="p-0 text-xl font-semibold">Condition Details</DialogTitle>
                <IconButton onClick={onClose} size="small" className="text-gray-400 hover:text-gray-600">
                    <XMarkIcon className="h-5 w-5" />
                </IconButton>
            </div>
            <DialogContent className="p-6">
                <div className="space-y-6">
                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Name</h3>
                        <p className="mt-1 text-base text-gray-900">{condition.name}</p>
                    </div>

                    {condition.description && (
                        <div>
                            <h3 className="text-sm font-medium text-gray-500">Description</h3>
                            <p className="mt-1 text-base text-gray-900">{condition.description}</p>
                        </div>
                    )}

                    <div>
                        <h3 className="text-sm font-medium text-gray-500">Condition Logic</h3>
                        <pre className="mt-2 rounded-lg bg-gray-50 p-4 text-sm">
                            {JSON.stringify(condition.condition, null, 2)}
                        </pre>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ConditionDetailDialog;
