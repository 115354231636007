import React, { useState } from 'react';
import { Tabs, Tab, Box, Container, ThemeProvider, createTheme, Typography } from '@mui/material';
import { caseCategories } from '../data/cases';
import { UserSubmission } from '../types/demo';
import CaseGrid from './Demo/CaseGrid';
import ContactModal from './Demo/ContactModal';

// Updated theme to match Google AI Studio
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#8AB4F8' // Google's blue
        },
        secondary: {
            main: '#C58AF9' // Google's purple
        },
        background: {
            default: '#202124', // Dark background
            paper: '#303134' // Slightly lighter background
        }
    },
    typography: {
        fontFamily: "'Google Sans', 'Roboto', sans-serif",
        h1: {
            fontSize: '2.125rem',
            fontWeight: 400,
            letterSpacing: '0.01em'
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 500,
            letterSpacing: '0.01em'
        },
        body1: {
            fontSize: '0.875rem',
            letterSpacing: '0.01em'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 500,
                    borderRadius: '8px'
                }
            }
        }
    }
});

export default function Demo() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedCase, setSelectedCase] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = (data: UserSubmission) => {
        console.log('User selected:', data);
        setIsModalOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box className="min-h-screen" sx={{ backgroundColor: 'background.default' }}>
                <Container maxWidth="lg" className="py-8">
                    {/* Updated Hero Section */}
                    <Box className="mb-8 text-center">
                        <Typography variant="h1" className="mb-2" sx={{ color: 'rgba(255,255,255,0.87)' }}>
                            Demo Intake Calls
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.6)', mt: 3, fontWeight: 500 }}>
                            You can customize what you want said, how you want it said, and the voice you want used.
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'rgba(255,255,255,0.6)', fontWeight: 500 }}>
                            These voice calls are just one part of the multi-channel intake that you can be set up for
                            you.
                        </Typography>
                    </Box>

                    {/* Updated Tabs styling */}
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'rgba(255,255,255,0.12)',
                            backgroundColor: 'background.paper',
                            borderRadius: '8px 8px 0 0'
                        }}
                    >
                        <Tabs
                            value={selectedTab}
                            onChange={(_, newValue) => setSelectedTab(newValue)}
                            variant="fullWidth"
                            sx={{
                                '& .MuiTab-root': {
                                    color: 'rgba(255,255,255,0.6)',
                                    fontSize: '0.875rem',
                                    textTransform: 'none',
                                    '&.Mui-selected': {
                                        color: '#8AB4F8'
                                    }
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#8AB4F8'
                                }
                            }}
                        >
                            {caseCategories.map(category => (
                                <Tab key={category.id} label={category.title} />
                            ))}
                        </Tabs>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <CaseGrid
                            cases={caseCategories[selectedTab].cases}
                            onCaseSelect={caseId => {
                                setSelectedCase(caseId);
                                setIsModalOpen(true);
                            }}
                        />
                    </Box>

                    <ContactModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onSubmit={handleSubmit}
                        selectedCase={selectedCase}
                        caseCategory={caseCategories[selectedTab].id}
                    />
                </Container>
            </Box>
        </ThemeProvider>
    );
}
