import React, { useEffect, useState } from 'react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Snackbar, Switch } from '@mui/material';
import { Sequence } from 'api/getSequence';
import getDocuments from 'api/document/get-documents';
import updateSequence from 'api/updateSequence';
import { DocumentNormal } from 'api/document/get-documents';
import Loader from 'components/Loader';
import { cn } from 'lib/utils';
import debouncer from 'lib/debouncer';
import usePopupMessageStore from 'stores/popup-message';

interface GeneralTabProps {
    sequenceId?: string;
    sequence: Sequence | null;
    onSequenceUpdate: (sequence: Partial<Sequence>) => void;
}

const GeneralTab: React.FC<GeneralTabProps> = ({ sequenceId, sequence, onSequenceUpdate }) => {
    const { success, error: showError } = usePopupMessageStore();
    const [documents, setDocuments] = useState<DocumentNormal[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState(sequence?.name || '');
    const [documentId, setDocumentId] = useState<string | null>(sequence?.documentId || null);
    const [slackWebhook, setSlackWebhook] = useState<string | null>(sequence?.slackWebhook || null);
    const [aiEnabled, setAiEnabled] = useState(sequence?.aiEnabled || false);
    const [aiEnabledAutoTakeContact, setAiEnabledAutoTakeContact] = useState(
        sequence?.aiEnabledAutoTakeContact || false
    );
    const [autoTakeContactRatio, setAutoTakeContactRatio] = useState(sequence?.autoTakeContactRatio || 0);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const documentsData = await getDocuments();
                setDocuments(documentsData.documents);
            } catch (error) {
                console.error('Error fetching documents:', error);
                showError('Error loading documents');
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    const handleNameUpdate = async () => {
        if (!sequenceId) return;
        try {
            await updateSequence({
                id: sequenceId,
                name
            });
            success('Sequence name updated successfully');
        } catch (error) {
            console.error('Error updating name:', error);
            showError('Failed to update sequence name');
        }
    };

    const handleDocumentChange = async (newDocumentId: string | null) => {
        if (!sequenceId) return;
        try {
            await updateSequence({
                id: sequenceId,
                documentId: newDocumentId
            });
            setDocumentId(newDocumentId);
            success('Context document updated successfully');
        } catch (error) {
            console.error('Error updating document:', error);
            showError('Failed to update context document');
        }
    };

    const handleSlackWebhookUpdate = async () => {
        if (!sequenceId) return;
        try {
            await updateSequence({
                id: sequenceId,
                slackWebhook
            });
            success('Slack webhook updated successfully');
        } catch (error) {
            console.error('Error updating slack webhook:', error);
            showError('Failed to update Slack webhook');
        }
    };

    const handleAiEnabledChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!sequenceId) return;
        setIsLoading(true);
        try {
            await updateSequence({
                id: sequenceId,
                aiEnabled: event.target.checked
            });
            setAiEnabled(event.target.checked);
            onSequenceUpdate({ aiEnabled: event.target.checked });
            success('AI settings updated successfully');
        } catch (error) {
            console.error('Error updating AI settings:', error);
            showError('Failed to update AI settings');
            setAiEnabled(!event.target.checked);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAutoTakeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!sequenceId) return;
        setIsLoading(true);
        try {
            await updateSequence({
                id: sequenceId,
                aiEnabledAutoTakeContact: event.target.checked
            });
            setAiEnabledAutoTakeContact(event.target.checked);
            onSequenceUpdate({ aiEnabledAutoTakeContact: event.target.checked });
            success('Auto-take settings updated successfully');
        } catch (error) {
            console.error('Error updating auto-take settings:', error);
            showError('Failed to update auto-take settings');
            setAiEnabledAutoTakeContact(!event.target.checked);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRatioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!sequenceId) return;
        let ratio = parseInt(event.target.value, 10);
        if (isNaN(ratio)) {
            ratio = 0;
        }
        if (ratio < 0) ratio = 0;
        if (ratio > 100) ratio = 100;

        if (ratio !== autoTakeContactRatio) {
            debouncer.debounce(
                `update-auto-take-new-contact-ratio`,
                async () => {
                    await updateSequence({ id: sequenceId, autoTakeContactRatio: ratio });
                    onSequenceUpdate({ autoTakeContactRatio: ratio });
                    success('Ratio updated successfully');
                },
                1000
            );
        }
        setAutoTakeContactRatio(ratio);
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!sequence) {
        return <Typography color="error">Sequence not found</Typography>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: '600px',
                '& .MuiTextField-root, & .MuiFormControl-root': {
                    marginBottom: 0
                },
                '& .MuiInputLabel-root': {
                    fontSize: '0.875rem'
                },
                '& .MuiInputBase-input': {
                    fontSize: '0.875rem'
                }
            }}
        >
            {/* Basic & Integration Settings Combined */}
            <Box className="rounded-lg border border-gray-200 p-3">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <TextField
                        label="Sequence Label"
                        value={sequence.label}
                        size="small"
                        fullWidth
                        disabled
                        sx={{ backgroundColor: 'action.hover' }}
                    />

                    <TextField
                        label="Sequence Name"
                        value={name}
                        size="small"
                        onChange={e => setName(e.target.value)}
                        onBlur={handleNameUpdate}
                        fullWidth
                    />

                    <FormControl fullWidth size="small">
                        <InputLabel>Context Document (FAQ)</InputLabel>
                        <Select
                            value={documentId || ''}
                            onChange={e => handleDocumentChange(e.target.value === '' ? null : e.target.value)}
                            label="Context Document (FAQ)"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {documents.map(doc => (
                                <MenuItem key={doc.id} value={doc.id}>
                                    {doc.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Slack Webhook URL"
                        value={slackWebhook || ''}
                        size="small"
                        onChange={e => setSlackWebhook(e.target.value)}
                        onBlur={handleSlackWebhookUpdate}
                        fullWidth
                        placeholder="Enter Slack webhook URL"
                    />
                </Box>
            </Box>

            {/* AI Configuration Section */}
            <Box className="rounded-lg border border-gray-200 p-3">
                <Box className="space-y-2">
                    <Box className="flex items-center justify-between py-0.5">
                        <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="text-gray-700">
                            Enable AI Agent
                        </Typography>
                        <Switch
                            checked={aiEnabled}
                            onChange={handleAiEnabledChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={isLoading}
                            size="small"
                        />
                    </Box>

                    {aiEnabled && (
                        <>
                            <Box className="flex items-center justify-between py-0.5">
                                <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="text-gray-700">
                                    Auto-take Contacts
                                </Typography>
                                <Switch
                                    checked={aiEnabledAutoTakeContact}
                                    onChange={handleAutoTakeChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    disabled={isLoading}
                                    size="small"
                                />
                            </Box>

                            <Box className="flex items-center justify-between py-0.5">
                                <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="text-gray-700">
                                    Auto-take Ratio
                                </Typography>
                                <Box className="flex items-center gap-1.5">
                                    <input
                                        type="number"
                                        value={autoTakeContactRatio}
                                        onChange={handleRatioChange}
                                        className={cn(
                                            'h-6 w-12 rounded border px-1 text-center text-sm',
                                            aiEnabledAutoTakeContact && autoTakeContactRatio > 0
                                                ? 'border-accent-success bg-accent-success/10'
                                                : 'border-control-border bg-control-hover'
                                        )}
                                        min="0"
                                        max="100"
                                    />
                                    <Typography variant="body2" sx={{ fontSize: '0.875rem' }} className="text-gray-600">
                                        %
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default GeneralTab;
