import React from 'react';
import Contacts from './Contacts/Contacts';
import useContactTableConfigStore from 'stores/contact-table-config';

function MainPage() {
    const contactTableConfigStore = useContactTableConfigStore();
    const currentSequenceId = contactTableConfigStore.getSequenceSelection('contacts') ?? null;

    return <Contacts currentSequenceId={currentSequenceId} />;
}

export default MainPage;
