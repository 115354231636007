import React, { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { XMarkIcon, EnvelopeIcon, PhoneIcon, CalendarIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { nextStepRules, StepRule } from '../../data/rules';

interface FollowUpRulesTabProps {
    sequenceId?: string;
}

const FollowUpRulesTab: React.FC<FollowUpRulesTabProps> = ({ sequenceId }) => {
    const [selectedRule, setSelectedRule] = useState<StepRule | null>(null);

    const handleRuleClick = (rule: StepRule) => {
        setSelectedRule(rule);
    };

    const handleCloseModal = () => {
        setSelectedRule(null);
    };

    return (
        <div className="flex h-full w-full flex-col">
            <div className="mb-6">
                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Rule Description
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Action Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                    >
                                        Conditions Count
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {nextStepRules.map((rule, index) => (
                                    <tr
                                        key={index}
                                        onClick={() => handleRuleClick(rule)}
                                        className="cursor-pointer hover:bg-gray-50"
                                    >
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-900">{rule.event.params.description}</div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="flex items-center text-sm text-gray-600">
                                                {rule.event.type === 'sms' && <EnvelopeIcon className="mr-2 h-5 w-5" />}
                                                {rule.event.type === 'call' && <PhoneIcon className="mr-2 h-5 w-5" />}
                                                {rule.event.type === 'schedule call' && (
                                                    <CalendarIcon className="mr-2 h-5 w-5" />
                                                )}
                                                {rule.event.type === 'schedule sms' && (
                                                    <ChatBubbleLeftIcon className="mr-2 h-5 w-5" />
                                                )}
                                                {rule.event.type}
                                            </div>
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className="text-sm text-gray-500">
                                                {rule.conditions.all?.length || rule.conditions.any?.length || 0}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal open={!!selectedRule} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: 800,
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        overflow: 'hidden',
                        borderRadius: 1
                    }}
                >
                    <div className="relative h-full">
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                zIndex: 1
                            }}
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </IconButton>

                        {selectedRule && (
                            <div className="max-h-[90vh] overflow-y-auto p-8">
                                <Typography variant="h5" className="mb-6 font-medium text-gray-900">
                                    {selectedRule.event.params.description}
                                </Typography>

                                <div className="space-y-6">
                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Action Type
                                        </Typography>
                                        <Typography className="flex items-center text-gray-800">
                                            {selectedRule.event.type === 'sms' && (
                                                <EnvelopeIcon className="mr-2 h-5 w-5" />
                                            )}
                                            {selectedRule.event.type === 'call' && (
                                                <PhoneIcon className="mr-2 h-5 w-5" />
                                            )}
                                            {selectedRule.event.type === 'schedule call' && (
                                                <CalendarIcon className="mr-2 h-5 w-5" />
                                            )}
                                            {selectedRule.event.type === 'schedule sms' && (
                                                <ChatBubbleLeftIcon className="mr-2 h-5 w-5" />
                                            )}
                                            {selectedRule.event.type}
                                        </Typography>
                                    </div>

                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Conditions
                                        </Typography>
                                        <div className="space-y-2">
                                            {selectedRule.conditions.all?.map((condition, index) => (
                                                <div key={index} className="rounded bg-white p-3">
                                                    <Typography className="text-sm text-gray-800">
                                                        {condition.fact} {condition.operator} {String(condition.value)}
                                                    </Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="rounded-lg bg-gray-50 p-4">
                                        <Typography variant="subtitle1" className="mb-2 font-medium text-gray-700">
                                            Raw Rule
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="whitespace-pre-wrap rounded bg-white p-3 font-mono text-sm text-gray-800"
                                        >
                                            {JSON.stringify(selectedRule, null, 2)}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default FollowUpRulesTab;
