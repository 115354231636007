export type ConditionRule = {
    condition?: string;
    fact?: string;
    operator?: string;
    value?: string | number | string[] | number[] | boolean | null;
    path?: string;
    not?: { condition: string };
};

export type ConditionLogic = {
    all?: Array<ConditionRule | ConditionLogic>;
    any?: Array<{
        all?: Array<ConditionRule | ConditionLogic>;
        condition?: string;
    }>;
    not?: {
        condition: string;
    };
};

export type Condition = {
    name: string;
    description?: string;
    condition: ConditionLogic;
};

export const sharedConditions: Condition[] = [
    {
        name: 'user-not-interested-in-moving-forward',
        description: 'is_still_interested_in_moving_forward is complete and is false',
        condition: {
            all: [
                { fact: 'is_still_interested_in_moving_forward', operator: 'isValueComplete', value: true },
                { fact: 'is_still_interested_in_moving_forward', operator: 'equal', value: false, path: '$.value' }
            ]
        }
    },
    {
        name: 'user-no-longer-own-the-car',
        description: 'still_own_the_car is complete and is false',
        condition: {
            all: [
                { fact: 'still_own_the_car', operator: 'isValueComplete', value: true },
                { fact: 'still_own_the_car', operator: 'equal', value: false, path: '$.value' }
            ]
        }
    },
    {
        name: 'car-year-earlier-than-2018',
        description: 'caryear is complete and is ealier than 2018',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                {
                    fact: 'car_year_normalized',
                    operator: 'in',
                    value: ['2018', 'Before 2018'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'car-year-later-than-2019',
        description: 'caryear is complete and is later than 2019',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                {
                    fact: 'car_year_normalized',
                    operator: 'in',
                    value: ['2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'car-year-2019',
        description: 'caryear is complete and is 2019',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                { fact: 'car_year_normalized', operator: 'equal', value: '2019', path: '$.value' }
            ]
        }
    },
    {
        name: 'car-year-later-than-2020',
        description: 'caryear is complete and is later than 2020',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                {
                    fact: 'car_year_normalized',
                    operator: 'in',
                    value: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'car-year-later-than-2022',
        description: 'caryear is complete and is later than 2022',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                {
                    fact: 'car_year_normalized',
                    operator: 'in',
                    value: ['2022', '2023', '2024', '2025', '2026', '2027', '2028'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'car-year-earlier-than-2021',
        description: 'caryear is complete and is earlier than 2021',
        condition: {
            all: [
                { condition: 'car-year-normalized-complete' },
                {
                    fact: 'car_year_normalized',
                    operator: 'in',
                    value: ['Before 2018', '2018', '2019', '2020', '2021'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'current-odometer-greater-than-100000',
        description: 'current_odometer is complete and is greater than 100000',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'greaterThan', value: 100000, path: '$.value' }
            ]
        }
    },
    {
        name: 'current-odometer-less-than-100000-inclusive',
        description: 'current_odometer is complete and is less than 100000 inclusive',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'lessThanInclusive', value: 100000, path: '$.value' }
            ]
        }
    },
    {
        name: 'current-odometer-in-between-100000-and-115000',
        description: 'current_odometer is complete and is in-between 100000 and 115000 inclusive',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'greaterThan', value: 100000, path: '$.value' },
                { fact: 'current_odometer', operator: 'lessThanInclusive', value: 115000, path: '$.value' }
            ]
        }
    },
    {
        name: 'current-odometer-greater-than-115000',
        description: 'current_odometer is complete and is greater than 115000',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'greaterThan', value: 115000, path: '$.value' }
            ]
        }
    },
    {
        name: 'car_new_or_certified_pre_owned_complete',
        description: 'car_new_or_certified_pre_owned is complete',
        condition: {
            all: [{ fact: 'car_new_or_certified_pre_owned', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'car_purchased_new',
        description: 'car_new_or_certified_pre_owned is complete and is new',
        condition: {
            all: [
                { condition: 'car_new_or_certified_pre_owned_complete' },
                { fact: 'car_new_or_certified_pre_owned', operator: 'equal', value: 'new', path: '$.value' }
            ]
        }
    },
    {
        name: 'car_purchased_certified_pre_owned',
        description: 'car_new_or_certified_pre_owned is complete and is certified pre-owned',
        condition: {
            all: [
                { condition: 'car_new_or_certified_pre_owned_complete' },
                {
                    fact: 'car_new_or_certified_pre_owned',
                    operator: 'equal',
                    value: 'certified_pre_owned',
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'car_purchased_neither_new_nor_certified_pre_owned',
        description: 'car_new_or_certified_pre_owned is complete and is neither new nor certified pre-owned',
        condition: {
            all: [
                { condition: 'car_new_or_certified_pre_owned_complete' },
                { fact: 'car_new_or_certified_pre_owned', operator: 'equal', value: 'neither', path: '$.value' }
            ]
        }
    },
    {
        name: 'repair-done-under-warranty-mileage-complete',
        description: 'repair_done_under_warranty_mileage is complete',
        condition: {
            all: [{ fact: 'repair_done_under_warranty_mileage', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'repair-done-under-warranty-mileage-true',
        description: 'repair_done_under_warranty_mileage is complete and is true',
        condition: {
            all: [
                { condition: 'repair-done-under-warranty-mileage-complete' },
                { fact: 'repair_done_under_warranty_mileage', operator: 'equal', value: true, path: '$.value' }
            ]
        }
    },
    {
        name: 'repair-done-under-warranty-mileage-false',
        description: 'repair_done_under_warranty_mileage is complete and is false',
        condition: {
            all: [
                { condition: 'repair-done-under-warranty-mileage-complete' },
                { fact: 'repair_done_under_warranty_mileage', operator: 'equal', value: false, path: '$.value' }
            ]
        }
    },
    {
        name: 'manufacturer-normalized-complete',
        description: 'manufacturerNormalized is complete',
        condition: {
            all: [{ fact: 'manufacturerNormalized', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'repairs-times-complete',
        description: 'repairs_times is complete',
        condition: {
            all: [{ fact: 'repairs_times', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'current-odometer-complete',
        description: 'current_odometer is complete',
        condition: {
            all: [{ fact: 'current_odometer', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'car-year-normalized-complete',
        description: 'car_year_normalized is complete',
        condition: {
            all: [{ fact: 'car_year_normalized', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'no-repairs',
        description: 'repairs_times is complete and is 0',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'lessThanInclusive', value: 0, path: '$.value' }
            ]
        }
    },
    {
        name: 'uncertain-repairs-times',
        description: 'repairs_times is complete and is null',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'equal', value: null, path: '$.value' }
            ]
        }
    },
    {
        name: 'group-1-manufacturer',
        description: 'manufacturerNormalized is complete and is in group 1',
        condition: {
            all: [
                { condition: 'manufacturer-normalized-complete' },
                {
                    fact: 'manufacturerNormalized',
                    operator: 'in',
                    value: ['Toyota', 'Honda', 'Ford', 'Chevrolet'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'group-2-manufacturer',
        description: 'manufacturerNormalized is complete and is in group 2',
        condition: {
            all: [
                { condition: 'manufacturer-normalized-complete' },
                {
                    fact: 'manufacturerNormalized',
                    operator: 'in',
                    value: ['bmw', 'mercedes', 'audi', 'volkswagen'],
                    path: '$.value'
                }
            ]
        }
    },
    {
        name: 'manufacturer-not-in-scope',
        description: 'manufacturerNormalized is complete and is not in scope',
        condition: {
            all: [
                { condition: 'manufacturer-normalized-complete' },
                { condition: 'repairs-times-complete' },
                { not: { condition: 'group-1-manufacturer' } },
                { not: { condition: 'group-2-manufacturer' } }
            ]
        }
    },
    {
        name: 'repair-times-between-1-and-2-inclusive',
        description: 'repairs_times is complete and is between 1 and 2 inclusive',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'lessThanInclusive', value: 2, path: '$.value' },
                { fact: 'repairs_times', operator: 'greaterThanInclusive', value: 1, path: '$.value' }
            ]
        }
    },
    {
        name: 'repair-times-equal-to-1',
        description: 'repairs_times is complete and is 1',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'equal', value: 1, path: '$.value' }
            ]
        }
    },
    {
        name: 'repair-times-greater-than-2-inclusive',
        description: 'repairs_times is complete and is greater than 2 inclusive',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'greaterThanInclusive', value: 2, path: '$.value' }
            ]
        }
    },
    {
        name: 'repair-times-greater-than-3-inclusive',
        description: 'repairs_times is complete and is greater than 3 inclusive',
        condition: {
            all: [
                { condition: 'repairs-times-complete' },
                { fact: 'repairs_times', operator: 'greaterThanInclusive', value: 3, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-25-complete',
        condition: {
            all: [{ fact: 'days_out_of_service_more_than_25', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'days-out-of-service-more-than-25-uncertain',
        description: 'days_out_of_service_more_than_25 is complete and is null',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-25-complete' },
                { fact: 'days_out_of_service_more_than_25', operator: 'equal', value: null, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-25-true',
        description: 'days_out_of_service_more_than_25 is complete and is true',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-25-complete' },
                { fact: 'days_out_of_service_more_than_25', operator: 'equal', value: true, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-25-false',
        description: 'days_out_of_service_more_than_25 is complete and is false',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-25-complete' },
                { fact: 'days_out_of_service_more_than_25', operator: 'equal', value: false, path: '$.value' }
            ]
        }
    },
    {
        name: 'current-odometer-greater-than-24000',
        description: 'current_odometer is complete and is greater than 24000',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'greaterThan', value: 24000, path: '$.value' }
            ]
        }
    },
    {
        name: 'current-odometer-less-than-24000-inclusive',
        description: 'current_odometer is complete and is less than 24000 inclusive',
        condition: {
            all: [
                { condition: 'current-odometer-complete' },
                { fact: 'current_odometer', operator: 'lessThanInclusive', value: 24000, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-20-complete',
        condition: {
            all: [{ fact: 'days_out_of_service_more_than_20', operator: 'isValueComplete', value: true }]
        }
    },
    {
        name: 'days-out-of-service-more-than-20-uncertain',
        description: 'days_out_of_service_more_than_20 is complete and is null',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-20-complete' },
                { fact: 'days_out_of_service_more_than_20', operator: 'equal', value: null, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-20-true',
        description: 'days_out_of_service_more_than_20 is complete and is true',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-20-complete' },
                { fact: 'days_out_of_service_more_than_20', operator: 'equal', value: true, path: '$.value' }
            ]
        }
    },
    {
        name: 'days-out-of-service-more-than-20-false',
        description: 'days_out_of_service_more_than_20 is complete and is false',
        condition: {
            all: [
                { condition: 'days-out-of-service-more-than-20-complete' },
                { fact: 'days_out_of_service_more_than_20', operator: 'equal', value: false, path: '$.value' }
            ]
        }
    },
    {
        name: 'user-wants-to-transfer-call',
        description: 'sp_user_wants_to_transfer_call exists and is true',
        condition: {
            all: [
                { fact: 'sp_user_wants_to_transfer_call', operator: 'notEqual', value: undefined },
                { fact: 'sp_user_wants_to_transfer_call', operator: 'equal', value: true, path: '$.value' }
            ]
        }
    },
    {
        name: 'qualified-repair-conditions',
        description: 'Vehicle meets repair count and service criteria for qualification',
        condition: {
            any: [
                {
                    all: [{ condition: 'group-1-manufacturer' }, { condition: 'repair-times-greater-than-2-inclusive' }]
                },
                {
                    all: [
                        { condition: 'group-1-manufacturer' },
                        { condition: 'repair-times-equal-to-1' },
                        { condition: 'car-year-later-than-2022' },
                        { condition: 'days-out-of-service-more-than-25-true' },
                        { condition: 'current-odometer-less-than-24000-inclusive' }
                    ]
                },
                {
                    all: [
                        { condition: 'group-2-manufacturer' },
                        { condition: 'repair-times-greater-than-3-inclusive' },
                        { condition: 'days-out-of-service-more-than-20-true' }
                    ]
                }
            ]
        }
    },
    {
        name: 'insufficient-repair-conditions',
        description: 'Vehicle does not meet minimum repair count and service criteria',
        condition: {
            any: [
                { all: [{ condition: 'no-repairs' }] },
                {
                    all: [
                        { condition: 'group-1-manufacturer' },
                        { condition: 'repair-times-equal-to-1' },
                        {
                            any: [
                                { condition: 'car-year-earlier-than-2021' },
                                { condition: 'days-out-of-service-more-than-25-false' },
                                { condition: 'current-odometer-greater-than-24000' }
                            ]
                        }
                    ]
                },
                {
                    all: [
                        { condition: 'group-2-manufacturer' },
                        { condition: 'repair-times-between-1-and-2-inclusive' }
                    ]
                },
                {
                    all: [
                        { condition: 'group-2-manufacturer' },
                        { condition: 'repair-times-greater-than-3-inclusive' },
                        { condition: 'days-out-of-service-more-than-20-false' }
                    ]
                }
            ]
        }
    },
    {
        name: 'qualified',
        description: 'qualified',
        condition: {
            all: [
                { condition: 'car-year-later-than-2019' },
                {
                    any: [
                        { condition: 'current-odometer-less-than-100000-inclusive' },
                        {
                            all: [
                                { condition: 'current-odometer-in-between-100000-and-115000' },
                                { condition: 'repair-done-under-warranty-mileage-true' }
                            ]
                        }
                    ]
                },
                {
                    any: [
                        {
                            all: [
                                { condition: 'group-1-manufacturer' },
                                { condition: 'repair-times-greater-than-2-inclusive' }
                            ]
                        },
                        {
                            all: [
                                { condition: 'group-1-manufacturer' },
                                { condition: 'repair-times-equal-to-1' },
                                { condition: 'car-year-later-than-2022' },
                                { condition: 'days-out-of-service-more-than-25-true' },
                                { condition: 'current-odometer-less-than-24000-inclusive' }
                            ]
                        },
                        {
                            all: [
                                { condition: 'group-2-manufacturer' },
                                { condition: 'repair-times-greater-than-3-inclusive' },
                                { condition: 'days-out-of-service-more-than-20-true' }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    {
        name: 'potential-future-case',
        description: 'potential-future-case',
        condition: {
            all: [
                { condition: 'current-odometer-less-than-100000-inclusive' },
                { condition: 'car-year-later-than-2020' }
            ]
        }
    },
    {
        name: 'future-case',
        description: 'future-case',
        condition: {
            all: [{ condition: 'potential-future-case' }, { condition: 'insufficient-repair-conditions' }]
        }
    },
    {
        name: 'car-year-2019-and-insufficient-repair-conditions',
        description: 'car-year-2019-and-insufficient-repair-conditions',
        condition: {
            all: [{ condition: 'car-year-2019' }, { condition: 'insufficient-repair-conditions' }]
        }
    },
    {
        name: 'odometer-greater-than-100000-and-insufficient-repair-conditions',
        description: 'odometer-greater-than-100000-and-insufficient-repair-conditions',
        condition: {
            all: [
                { condition: 'current-odometer-greater-than-100000' },
                { condition: 'insufficient-repair-conditions' }
            ]
        }
    },
    {
        name: 'missing-car-purchase-info-odometer-100k-115k-enough-repairs',
        description:
            'Vehicle has qualified repairs, odometer between 100k-115k miles, and missing car purchase type info',
        condition: {
            all: [
                { condition: 'current-odometer-in-between-100000-and-115000' },
                {
                    condition: 'qualified-repair-conditions'
                },
                { not: { condition: 'car_new_or_certified_pre_owned_complete' } }
            ]
        }
    },
    {
        name: 'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-new-enough-repairs',
        description:
            'Vehicle has qualified repairs, odometer between 100k-115k miles, car purchased new, and missing if repair done under warranty info',
        condition: {
            all: [
                { condition: 'current-odometer-in-between-100000-and-115000' },
                {
                    condition: 'qualified-repair-conditions'
                },
                { condition: 'car_purchased_new' },
                { not: { condition: 'repair-done-under-warranty-mileage-complete' } }
            ]
        }
    },
    {
        name: 'missing-repair-done-under-warranty-info-odometer-100k-115k-purchase-certified-pre-owned-enough-repairs',
        description:
            'Vehicle has qualified repairs, odometer between 100k-115k miles, car purchased certified pre-owned, and missing if repair done under warranty info',
        condition: {
            all: [
                { condition: 'current-odometer-in-between-100000-and-115000' },
                {
                    condition: 'qualified-repair-conditions'
                },
                { condition: 'car_purchased_certified_pre_owned' },
                { not: { condition: 'repair-done-under-warranty-mileage-complete' } }
            ]
        }
    },
    {
        name: 'disqualify-odometer-100k-115k-car-repair-not-done-under-warranty',
        description:
            'Vehicle has qualified repairs, odometer between 100k-115k miles, but repair is not done under warranty',
        condition: {
            all: [
                { condition: 'current-odometer-in-between-100000-and-115000' },
                {
                    condition: 'qualified-repair-conditions'
                },
                {
                    any: [
                        { condition: 'car_purchased_neither_new_nor_certified_pre_owned' },
                        { condition: 'repair-done-under-warranty-mileage-false' }
                    ]
                }
            ]
        }
    },
    {
        name: 'qualified-in-office-hour',
        description: 'qualified-in-office-hour',
        condition: {
            all: [{ condition: 'qualified' }, { condition: 'in-office-hour' }]
        }
    },
    {
        name: 'qualified-out-office-hour-morning',
        description: 'qualified-out-office-hour-morning',
        condition: {
            all: [{ condition: 'qualified' }, { condition: 'out-office-hour-morning' }]
        }
    },
    {
        name: 'qualified-out-office-hour-evening',
        description: 'qualified-out-office-hour-evening',
        condition: {
            all: [{ condition: 'qualified' }, { condition: 'out-office-hour-evening' }]
        }
    }
];
