import React, { useState } from 'react';
import { TextField, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import resetPassword from 'api/reset-password';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TrashIcon } from '@heroicons/react/24/outline';
import IconButton from '@mui/material/IconButton';
import getUsers, { User } from 'api/get-users';
import updateUser from 'api/update-user';
import usePopupMessageStore from 'stores/popup-message';

const SettingsPage = () => {
    const { success, error: showError } = usePopupMessageStore();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<number | null>(null);

    React.useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const res = await getUsers();
            setUsers(res.result);
        } catch (error) {
            showError('Failed to fetch users');
        }
    };

    const openDeleteDialog = (userId: number) => {
        setUserToDelete(userId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteUser = async () => {
        if (!userToDelete) return;

        try {
            const res = await updateUser({ id: userToDelete, isDeleted: true });
            if (res.result === 'success') {
                success('User deleted successfully');
                fetchUsers(); // Refresh the list
            }
        } catch (error) {
            showError('Failed to delete user');
        } finally {
            setDeleteDialogOpen(false);
            setUserToDelete(null);
        }
    };

    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'role', headerName: 'Role', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: params => (
                <IconButton onClick={() => openDeleteDialog(params.row.id)} color="error">
                    <TrashIcon className="h-5 w-5" />
                </IconButton>
            )
        }
    ];

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            showError('New password and confirm password do not match.');
            return;
        }

        if (newPassword.length < 6) {
            showError('Password must be at least 6 characters long.');
            return;
        }

        try {
            const res: { result: string; message: string } = await resetPassword({ oldPassword, newPassword });
            if (res.result === 'error') {
                showError(res.message);
            }

            if (res.result === 'success') {
                success(res.message);
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            }
        } catch (error) {
            showError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="mt-4 flex h-[calc(100vh-7rem)] flex-col items-center space-y-4 overflow-hidden bg-white px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-2xl">
                {/* Reset Password Section */}
                <div className="border border-gray-200 bg-white shadow-sm">
                    <div className="px-4 py-3">
                        <Typography className="text-sm font-medium text-gray-700">Reset Your Password</Typography>
                    </div>
                    <div className="h-px w-full bg-gray-100" />
                    <div className="p-4">
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Old Password"
                                type="password"
                                value={oldPassword}
                                onChange={e => setOldPassword(e.target.value)}
                                required
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 0
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="New Password"
                                type="password"
                                value={newPassword}
                                onChange={e => setNewPassword(e.target.value)}
                                required
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 0
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Confirm New Password"
                                type="password"
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                required
                                size="small"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 0
                                    }
                                }}
                            />
                            <div className="mt-4 flex justify-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        borderRadius: 0,
                                        textTransform: 'none',
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    Reset Password
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Manage Users Section */}
                <div className="mt-4 border border-gray-200 bg-white shadow-sm">
                    <div className="px-4 py-3">
                        <Typography className="text-sm font-medium text-gray-700">Manage Users</Typography>
                    </div>
                    <div className="h-px w-full bg-gray-100" />
                    <div className="h-[400px] w-full">
                        <DataGrid
                            rows={users.filter(user => !user.isDeleted)}
                            columns={columns}
                            disableRowSelectionOnClick
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-cell': {
                                    fontSize: '0.875rem',
                                    color: 'rgb(55, 65, 81)'
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'rgb(249, 250, 251)',
                                    fontSize: '0.875rem',
                                    fontWeight: 500
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Existing Dialog and Snackbar components */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: 0
                    }
                }}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this user?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteUser} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SettingsPage;
